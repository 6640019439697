import enLocale from './locales/en-locale';
import trLocale from './locales/tr-locale';

export default  {
    locale: 'tr',
    messages: {
        tr: trLocale,
        en: enLocale
    },
    fallbackLocale: 'tr',

}