import baseServices from './index';

export default {
    getCitiesByCountryCodeAndLanguage: async (countriesCode, language) => {
        if (!countriesCode || !language) {
            return null;
        }
        try {   
            const result = await baseServices().post('geo-api/get-cities', { countriesCode, lang: language });
            return result;

        } catch (error) {
            console.log(error);
            return error;
        }
    }
};