<template>
  <div class="steps-line">
    <div class="steps-line__step" @click="setIndex(index)" v-for="(st,index) in steps" :key="st?.index">
        <div class="steps-line__step__circle" :style="`height: ${height/5.5}rem;width: ${height/5.5}rem;`" :class="{'selected-step':index === currentStepIndex}">

            <i :class="st?.icon"></i>
            <div class="steps-line__step__circle__title" :style="{fontSize: st?.title.length > 10 ? '0.55rem' : '0.6rem'}">{{ st?.title }}</div>
        </div>
        <div class="steps-line__step__line" :style="`height: ${height/(steps.length+1.1)}rem`"  v-if="index !== steps.length-1"></div>
    
    </div>
  </div>
</template>

<script>
export default {
    props:{
        steps:{
            type:Array,
            required:true,
            default: () => []
        },

        height:{
            type:Number,
            required:false,
            default: 0
        },
        selectedStepId:{
            type:String,
            required:false,
            default: 'set-content'
        }
    },
    data(){
        return {
            currentStepIndex: 0
        }
    },
    methods:{
        setIndex(index){
            this.$emit('clickStep',index);
            this.currentStepIndex = index
        }
    },

    created(){

        this.currentStepIndex = this.steps.findIndex(el => el?.id === this.selectedStepId) ?? 'set-content';
    },
    emits:['clickStep']
}
</script>

<style scoped lang="scss">

    .selected-step{
        border:2px dashed #b5b4b4ac !important;
        transform: scale(1.1);
        background: #f0f0f0d8 !important;


    }
    .steps-line{
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap:0;
        width: max-content;
        height: inherit;
        &__step{
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            gap:.1rem;
            width: max-content;
            height: max-content;
            &__circle{
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                gap:.2rem;
                transition: .1s all ease-in-out;
                cursor: pointer;
                // width: 3rem;
                // height: 3rem;
                border-radius: 50%;
                background: #f7f7f7d8;
                border:1px solid #d0d0d05b ;
                margin-top: .1rem;
                // padding: .5rem 1rem;
                &__title{
                    font-size: .6rem;
                    font-weight: 550;
                    color: #313133;
                }
                & i{
                    font-size: 1rem;
                    font-weight: 600;
                    color: #313133;

                }
            }   
            &__line{
                display: flex;
                width: .1rem;
                background-color: #95959549;
            }
        }
    }
</style>