<template>
    <div style="display: flex;align-items: center; width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 999; background-color: rgba(0, 0, 0, 0.75); justify-content: center; overflow: hidden; " class="w-full h-full fixed block top-0 left-0 bg-black bg-opacity-75 z-50">
        
<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="300px" height="300px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<rect x="15" y="30" width="10" height="40" fill="#089ccc">
  <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.6"></animate>
</rect><rect x="35" y="30" width="10" height="40" fill="#f5b70f">
  <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.4"></animate>
</rect><rect x="55" y="30" width="10" height="40" fill="#cc0505">
  <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.2"></animate>
</rect><rect x="75" y="30" width="10" height="40" fill="#e7e0c9">
  <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-1"></animate>
</rect>

</svg> -->

<svg width="180" height="120" style="margin: auto;" viewBox="0 0 24 34" xmlns="http://www.w3.org/2000/svg">



<rect class="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10" />
<rect class="spinner_9y7u spinner_DF2s" x="1" y="1" rx="1" width="10" height="10" />
<rect class="spinner_9y7u spinner_q27e" x="1" y="1" rx="1" width="10" height="10" />

<text x="12" y="32" class="loading-text">Please Wait</text>

</svg>








    </div>

   
</template>

<style>

.loading-text {
  width: max-content;
  height: max-content;
  font-family: Roboto;
  font-size: .4rem;
  font-weight: 550;
  fill: white;
  text-anchor: middle;
  text-shadow: 2px 2px 4px #000000;
  animation: fadeIn 2s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
.spinner_9y7u {
  animation: spinner_fUkk 2.4s linear infinite;
  animation-delay: -2.4s;
  fill: #da1297
}
.spinner_DF2s {
  animation-delay: -1.6s;
  fill: #066aff;
}
.spinner_q27e {
  animation-delay: -0.8s;
  fill: #ffbb00
}
@keyframes spinner_fUkk {
  8.33% { x: 13px; y: 1px; }
  25% { x: 13px; y: 1px; }
  33.3% { x: 13px; y: 13px; }
  50% { x: 13px; y: 13px; }
  58.33% { x: 1px; y: 13px; }
  75% { x: 1px; y: 13px; }
  83.33% { x: 1px; y: 1px; }
}
</style>

