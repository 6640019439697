<template>
    <div class="user-indivudal-view">
      <IndividualUserSection />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import IndividualUserSection from '@/components/indivudal-user-section/IndexIndividualUserSection.vue'
  
  export default {
    name: 'UserIndividualView',
    components: {
      IndividualUserSection
    }
  }
  </script>
  
  
  <style lang="scss">
  .user-indivudal-view{ 
    height:auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  </style>
  