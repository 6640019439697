<template>
  <div class="home">
    <Navbar />
    <AppIntroduceSection />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/navbar/IndexNavbar.vue'
import AppIntroduceSection from '@/components/introduce-app/IndexIntroduceApp.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    AppIntroduceSection
  }
}
</script>


<style lang="scss">
.home {
  height:auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  overflow: hidden;
}
</style>
