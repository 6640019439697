<template>
  <!-- Kısa metin gösterimi -->
  <div class="shorted-text"
       @mouseover="showPopupAt($event)"
       @mouseleave="showPopup = false"
       @touchstart="showPopupAt($event)"
       @mouseout="cancelTimerToShow"
       @touchend="showPopup = false">
    {{ shortText }}
  </div>

  <!-- Tam metin için popup penceresi -->
  <div v-if="showPopup" :style="{ top: popupTop + 'px', left: popupLeft + 'px' }" class="popup">
    <p>{{ getPlainText(fullText) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    customLength: {
      type: Number,
      default: 30
    }
  },
  data() {
    return {
      showPopup: false,
      popupTop: 0,
      popupLeft: 0,
      showTimeout:null
    };
  },
 
  methods: {
    getPlainText(text) {
      const div = document.createElement('div');
      div.innerHTML = text;
      return div.textContent || div.innerText || '';
    },
    cancelTimerToShow(){
      this.showPopup = false;
      clearTimeout(this.showTimeout)

    },

    showPopupAt(event) {
      this.showTimeout =  setTimeout(() => {
        
      this.showPopup = true;
      const offset = 10; // Metnin üstünde biraz boşluk bırakmak için offset
      
      // Ekran boyutuna göre konumu ayarla
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const popupWidth = 150; // Popup'un genişliği (CSS'te tanımladığınız width ile aynı olmalı)
      
      // Mouse veya dokunmatik konumunu al
      const x = event.clientX || event.touches[0].clientX;
      const y = event.clientY || event.touches[0].clientY;

      // Popup'un ekranın dışına taşmaması için konumu ayarla
      this.popupLeft = (x + popupWidth > viewportWidth) ? x - popupWidth - offset : x + offset;
      this.popupTop = (y + offset > viewportHeight) ? y - offset : y + offset;
    }, 500);
    },

  },
  computed: {
    shortText() {
      return this.text.length > this.customLength ? this.text.substr(0, this.customLength) + '...' : this.text;
    },
    fullText() {
      return this.text;
    }
  }
}
</script>

<style scoped lang="scss">
.shorted-text {
  position: relative;
  cursor: pointer;
}

.popup {
  position: fixed;
  background-color: white;
  border: 2px solid #cee9ff;
  padding: 1rem;
  z-index: 10000000 !important;
  border-radius: 8px;
  width: 12rem; /* veya gereksinime göre ayarlayın */
}
</style>
