<template>
    <div class="ad-detaily-show-container"  :class="{ 'only-border-bottom': userRole === 'individual'}">
        <div class="ad-detaily-show-container__ad-info-card">
            <div class="ad-detaily-show-container__ad-info-card__image">
                <img v-if="adDetails?.image" :src="adDetails?.image?.bucketLocation" alt="ad image" :style="{ height: adDetails?.promotionCode ? '86%' : '100%'}">
                <div v-if="adDetails?.promotionCode" class="ad-detaily-show-container__ad-info-card__image__promotion-code">
                    <label for="">{{ $t('promotionCode') }}:</label>
                    <p>{{ adDetails?.promotionCode || 'no-promotion-code' }}</p>
                </div>
            </div>
            <div class="ad-detaily-show-container__ad-info-card__body">
                <div class="ad-detaily-show-container__ad-info-card__body__title">
    

                    {{ adDetails?.title || 'no-title' }}

                    </div>
                <div class="ad-detaily-show-container__ad-info-card__body__description">
                    
                    <span v-if="(adDetails?.description || '').length < 180">
                        {{ adDetails?.description || 'no-description' }}
                    </span>
                    <ShowShorterTextView :customLength="180"  v-else  :text="adDetails?.description  || 'no-description'" />

                    </div>
                <div class="ad-detaily-show-container__ad-info-card__body__locations" v-if="userRole !== 'individual'">
                    <div class="ad-detaily-show-container__ad-info-card__body__locations__countries">
                        <label for="">{{ $t('countries') }}</label>
                        <p>  {{  adDetails?.countries || 'no-countries' }}</p>
                    </div>
                    <div v-if="(adDetails?.cities || []).length" class="ad-detaily-show-container__ad-info-card__body__locations__cities">
                        <label for="">{{ $t('cities') }}</label>
                        <p>{{ adDetails?.cities || 'no-cities' }}</p>
                    </div>

                </div>
                <div class="ad-detaily-show-container__ad-info-card__body__category">
                    <label for="">{{ $t('category') }}:</label>
                    <p>{{ adDetails?.categories || $t('no-category') }}</p>
                </div>
                <div class="ad-detaily-show-container__ad-info-card__body__commercial-name">
                    <label for="">{{ $t('commercialName') }}:</label>
                    <p>{{ adDetails?.commercialName || $t('no-name') }}</p>
                </div>

                <div class="ad-detaily-show-container__ad-info-card__body__publish-date" v-if="userRole !== 'individual'">
                    <label for="">{{ $t('createdDate') }}:</label>
                    <p>{{ getDateConverted(adDetails?.createdDate)|| $t('no-date') }}</p>


                </div>

                <div class="ad-detaily-show-container__ad-info-card__body__publish-date">
                        <label for="">{{ $t('publishDate') }}:</label>
                        <p>{{ getDateConverted(adDetails?.publishedDate)|| $t('no-date') }}</p>
                    </div>


            </div>
        </div>


    </div>
</template>

<script>
import moment  from 'moment'
export default {
props:{
    adDetails: {
        type: Object,
        required: true,
        default: {}
    },
    userRole: {
        type: String,
        required: true,
        default: ''
    }
},

methods: {

    getDateConverted(date){
        if(date){
            return moment(date).format('DD/MM/YYYY HH:mm');
        }
        return ''
    },

},

}
</script>

<style scoped lang="scss">

.only-border-bottom {
    border:0 !important;
    border-radius: 0 !important;
}
    .ad-detaily-show-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        padding: 1rem;
        box-sizing: border-box;

        @media screen and  (max-width: 768px) {
                    border-radius: 4px;
                }


        & p {
            font-size: .65rem;
            font-weight: 500;
            color: #2a2a2ad7;
            margin: 0;
            padding: 0;
        }

        & label {
            font-size: .65rem;
            font-weight: 550;
            color: #2a2a2ad7;
            margin: 0;
            padding: 0;
        }
        &__ad-info-card{
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: space-between;       
            
            @media screen and  (max-width: 500px) {
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    justify-content: flex-start;
            }
            &__image{
                width: 48%;
                height: 100%;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: space-between;


                @media screen and  (max-width: 500px) {
                        width: 100%;
                        height: 72%;
                        max-height: 26rem ;
                        margin-bottom: 1%;
                }
                
                &__promotion-code{
                    width: 100%;
                    height: 10%;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .5rem;
                    font-size: .7rem;
                    font-weight: 550;
                    color: #2a2a2ad7;
                    border-bottom: 2px dashed #452149bf;
                    padding: .2rem 1rem;
                }
                & img{
                    width: 100%;
                    height: 86%;
                    object-fit: fill;

                    border-radius: 8px;
                }
            }
            &__body{
                width: 50%;
                height: 100%;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: space-between;
                gap: 0;
                overflow: auto;

                @media screen and  (max-width: 500px) {
                        width: 100%;
                        height: 26%;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;
                        gap: .5rem;

                        overflow: auto;

                        &__title{
                            font-size: .8rem !important;
                            max-width: 25% !important;


                        }
                        &__description{
                            max-height: 100% !important;
                            max-width: 35% !important;

                            & p {
                                font-size: .6rem !important;
                            }
                        }
                        
                        p , label{
                            font-size: .65rem !important;
                        }
                        label {
                            font-weight: 700 !important;
                        }

                        &__locations, &__category, &__commercial-name, &__publish-date{
                            display: flex;
                            flex-flow: column nowrap !important;
                            gap: .2rem !important;
                            justify-content: flex-start;
                            align-items: flex-start;
                            font-size: .65rem !important; 
                        }

                   

                }

            //    &__category, &__commercial-name, &__publish-date{
            //             margin: .5rem 0;
            //     }

                
                &__title{
                    font-size: .9rem;
                    font-weight: 600;
                    height: max-content;
                }
                &__description{
                    font-size: .7rem;
                    font-weight: 500;
                    max-height: 30%;
                    overflow: auto;
                    
                }

                &__locations{
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: flex-start;
                    justify-content: space-between;

                        & p{
                            margin-top: .3rem !important;
                        }

                    &__countries{
                        width: 46%;
                        height: max-content;
                        font-size: .65rem;
                        font-weight: 550;
                        color: #2a2a2ad7;

                        // border-bottom: 1px dashed #452149bf;

                    }
                    &__cities{
                        width: 46%;
                        height: max-content;
                        font-size: .65rem;
                        font-weight: 550;
                        color: #2a2a2ad7;
                        // border-bottom: 1px dashed #452149bf;
                    }
                }
                &__category{
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .5rem;
                    font-size: .7rem;
                    font-weight: 550;
                    color: #2a2a2ad7;

                }
                &__commercial-name{
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .5rem;
                }
                &__publish-date{
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .5rem;
    
                }
            }
        }




    }
</style>