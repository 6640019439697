<template>
    <nav class="navbar-container-individual">
        <div class="navbar-sub-container-individual">


            <div class="navbar-container-individual__app-logo-container">
                <img src="/img/app_logos/darkest_app_logo.png" alt="app logo"
                    class="navbar-container-individual__app-logo-container__app-logo-img">
            </div>
            <div class="navbar-container-individual__user-login-section" ref="individualUserNavbarFirstSection">
                <div class="navbar-container-individual__user-login-section__first">
                    <div class="navbar-container-individual__user-login-section__first__user-icon">
                        <i class='bx bxs-user'></i>
                    </div>
                    <div class="navbar-container-individual__user-login-section__first__user-info">
                        <p class="navbar-container-individual__user-login-section__first__user-info__name">{{
                            getUserIndividualInfo?.name }}</p>
                        <p class="navbar-container-individual__user-login-section__first__user-info__surname">{{
                            getUserIndividualInfo?.surname }}</p>
                    </div>
                </div>

                <div class="navbar-container-individual__user-login-section__second">

                    <div class="navbar-container-individual__user-login-section__second__user-info">
                        <a :href="felixsellLink" target="_blank">felixsell.com <i class="bx bx-link-external"></i></a>
                        <!-- <p class="navbar-container-individual__user-login-section__second__user-info__member-title">{{ $t('userIndividualMemberNo') }} :</p>
            <p class="navbar-container-individual__user-login-section__second__user-info__member-number">{{ getUserIndividualInfo?.userId }} <i @click="setCopy($event,getUserIndividualInfo?.userId)" class="bx bx-copy"></i></p> -->
                    </div>
                    <div class="navbar-container-individual__user-login-section__second__location">
                        <div class="navbar-container-individual__user-login-section__second__location__icon"> <i
                                class="fa-solid fa-location-dot"></i></div>
                        <p class="navbar-container-individual__user-login-section__second__location__position">{{
                            getUserIndividualInfo?.country.names[getSelectedLanguage] }}</p>
                    </div>
                </div>


                <div class="navbar-container-individual__user-login-section__time-limitation">
                    <!-- <p class="navbar-container-individual__user-login-section__time-limitation__title"> <span>{{ $t('userRemainingAdsDailyCount') }} </span>  </p> -->

                    <div class="navbar-container-individual__user-login-section__time-limitation__remaining-ads-daily">
                        <div
                            class="navbar-container-individual__user-login-section__time-limitation__remaining-ads-daily__container">
                            <p
                                class="navbar-container-individual__user-login-section__time-limitation__remaining-ads-daily__container__count">
                                {{ getUserIndividualInfo?.remainingDailyAdWatchCount }}</p>
                        </div>
                        <div
                            class="navbar-container-individual__user-login-section__time-limitation__remaining-ads-daily__remaining-ad-refresh-time">
                            <div
                                class="navbar-container-individual__user-login-section__time-limitation__remaining-ads-daily__remaining-ad-refresh-time__info">
                                <img src="/app_icons/hourglass_trp.gif" alt="timer-daily-ad">
                                <p>{{ getTimerModifiedByHours }}</p>
                            </div>
                        </div>


                    </div>

                </div>

                <div class="navbar-container-individual__user-login-section__third">
                    <div class="navbar-container-individual__user-login-section__third__balance">
                        <p class="navbar-container-individual__user-login-section__third__balance__title"> <span>{{
                            $t('userBalance') }} </span> <i class="bx bx-money"></i> </p>
                        <p class="navbar-container-individual__user-login-section__third__balance__amount">{{
                            getUserIndividualInfo?.balance }} {{ getUserIndividualInfo?.currency?.currency }}</p>
                    </div>
                    <div class="navbar-container-individual__user-login-section__third__withdraw">
                        <button class="navbar-container-individual__user-login-section__third__withdraw__btn"> <span>{{
                            $t('userIndividualWithdraw') }}</span> <i class="bx bx-right-arrow"></i></button>
                    </div>
                </div>


                <div class="navbar-container-individual__user-login-section__smaller-devices-screen" v-if="isSmallDevice">
                    <button class="navbar-container-individual__user-login-section__smaller-devices-screen__btn-logout logout"
                        @click="logout"> <span>{{ $t('logout') }}</span></button>

                    <button
                        class="navbar-container-individual__user-login-section__smaller-devices-screen__btn-notification notification">
                        <i class="bx bx-bell"></i>
                    </button>
                    <button class="navbar-container-individual__user-login-section__smaller-devices-screen__btn-menu menu"
                        @click="openHamburgerBarDetailSetting">
                        <i class="bx bx-menu"></i>
                    </button>

                </div>
            </div>
            <div class="navbar-container-individual__user-third-section" ref="individualUserNavbarSecondSection">
                <div class="navbar-container-individual__user-third-section__exit-hamburger-bar"
                    v-if="isSmallDevice && isHamburgerBarDetailSettingOpen">
                    <button @click="closeHamburgerBarDetailSetting"
                        class="navbar-container-individual__user-third-section__exit-hamburger-bar__btn"> <i
                            class="bx bx-arrow-back"></i> <span>{{ $t('back') }}</span></button>
                </div>
                <div class="navbar-container-individual__user-third-section__settings">
                    <button @click="isRegesterModalOpen = true"
                        class="navbar-container-individual__user-third-section__settings__btn"><span>{{ $t('userSettings')
                        }}</span> <i class="bx bx-cog"></i></button>
                </div>
                <div class="navbar-container-individual__user-third-section__notifications">
                    <button class="navbar-container-individual__user-third-section__notifications__btn"> <span>{{
                        $t('userNotifications') }}</span><i class="bx bx-bell"></i></button>
                </div>
                <div class="navbar-container-individual__user-third-section__logout" @click="logout">
                    <button class="navbar-container-individual__user-third-section__logout__btn"> <span>{{ $t('logout')
                    }}</span><i class="bx bx-log-out"></i></button>
                </div>
                <div class="navbar-container-individual__user-third-section__select-language">
                    <n-popselect v-model:value="selectedLanguage" :options="languages">
                        <div class="navbar-container-individual__user-third-section__select-language__btn">
                            <img :src="require(`@/assets/flags/${selectedLanguage}.png`)" alt="language">
                            <span>{{ $t(selectedLanguage) }}</span>
                        </div>
                    </n-popselect>
                </div>
            </div>
        </div>
    </nav>
    <ModalContainer v-if="isRegesterModalOpen" @closeModal="isRegesterModalOpen = false">
    </ModalContainer>
</template>
  
<script>
import { mapGetters } from 'vuex';
import ModalContainer from '@/utils/ModalContainer.vue';
import RegisterUserForm from '@/components/register-user/IndexUserRegister.vue'
export default {

    components: {
        ModalContainer,
        RegisterUserForm
    },
    data() {
        return {
            selectedLanguage: 'tr',
            languages: [
                {
                    label: this.$t('tr'),
                    value: 'tr'
                },
                {
                    label: this.$t('en'),
                    value: 'en'
                },


            ],
            felixsellLink: 'https://felixsell.com',
            userEntryInfo: '',
            passwordEntryInfo: '',
            isRegesterModalOpen: false,
            secondsLeft: 0,
            isHamburgerBarDetailSettingOpen: false

        }
    },


    methods: {

        setHamburgerBarLayout() {
            this.$refs['individualUserNavbarSecondSection'].style.display = 'flex'
            this.$refs['individualUserNavbarSecondSection'].style.width = '90%'
            this.$refs['individualUserNavbarSecondSection'].style.height = '100%'
            this.$refs['individualUserNavbarSecondSection'].style['justify-content'] = 'flex-start'
            this.$refs['individualUserNavbarSecondSection'].style['align-items'] = 'center'
            this.$refs['individualUserNavbarSecondSection'].style.gap = '1rem'
        },
        openHamburgerBarDetailSetting() {

            this.setHamburgerBarLayout()

            this.$refs['individualUserNavbarFirstSection'].style.display = 'none'

            this.isHamburgerBarDetailSettingOpen = true;
        },
        closeHamburgerBarDetailSetting() {
            this.$refs['individualUserNavbarSecondSection'].style.display = 'none'
            this.$refs['individualUserNavbarFirstSection'].style.display = 'flex'
            this.isHamburgerBarDetailSettingOpen = false
        },

        startCountdown(seconds) {
            this.secondsLeft = seconds;
            const countdown = () => {
                if (this.secondsLeft > 0) {
                    setTimeout(() => {
                        this.secondsLeft--;
                        countdown();
                    }, 1000);
                } else {
                    // Zamanlayıcı sıfırlandığında yeni süreyi al
                    this.fetchResetTime();
                }
            };
            countdown();
        },

        async fetchResetTime() {
            try {
                const userId = this.getUserIndividualInfo?.userId || '';
                if (!userId) {
                    //   this.setPopupErrorMessage(this.$t('noUserId'))
                    return;
                }
                const secondsUntilReset = await this.$store.dispatch('individualUserModule/getRemaningSecondWatchAdsRightReset', userId);
                if (secondsUntilReset !== 0) {
                    this.startCountdown(secondsUntilReset);
                }
                else {
                    this.secondsLeft = 0

                }


            } catch (error) {
                console.error(error);

            }

        },
        logout() {
            this.$store.dispatch('logout')
        },
        setCopy(event, text) {
            this.$copyText(text).then(() => {

                const div = document.createElement('div');
                div.style.position = 'absolute';
                div.style.top = '-.9rem';
                div.style.right = '-.5rem';
                div.style.fontSize = '.65rem';
                div.innerText = this.$t('copied');
                event.target.appendChild(div);
                setTimeout(() => {
                    event.target.removeChild(div);

                }, 2000);
            })
        }
    },
    computed: {
        ...mapGetters({ getUserIndividualInfo: 'individualUserModule/getUserIndividualInfo' }),
        ...mapGetters(['getSelectedLanguage', 'getScreenType']),
        isSmallDevice() {
            return this.getScreenType === 'small' || this.getScreenType === 'xsmall'
        },
        getTimerModifiedByHours() {
            if (this.secondsLeft <= 0) {
                return '00:00:00'
            }
            const hours = Math.floor(this.secondsLeft / 3600);
            const minutes = Math.floor((this.secondsLeft % 3600) / 60);
            const seconds = this.secondsLeft % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
    },

    created() {
        this.selectedLanguage = this.getSelectedLanguage
        this.fetchResetTime();

    },

    watch: {
        selectedLanguage() {
            this.$store.commit('setSelectedLanguage', this.selectedLanguage)
        }
    },

}
</script>
  
<style scoped lang="scss">
.navbar-container-individual {
    display: flex;
    width: 100%;
    height: 5.5rem;
    //   padding: 0 6%;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    background: #FFCC01;
    box-sizing: border-box;
    overflow: hidden;


    @media screen and (max-width: 768px) {

        height: 6rem;
    }


    & .navbar-sub-container-individual {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 84%;
        height: 100%;

        @media screen and (min-width: 3000px) {
            width: 60%;
        }

        @media screen and ((min-width: 2560px) and (max-width: 3000px)) {
            width: 64%;
        }

        @media screen and ((min-width: 2360px) and (max-width: 2560px)) {
            width: 68%;
        }

        @media screen and ((min-width: 2140px) and (max-width: 2360px)) {
            width: 72%;
        }

        @media screen and ((min-width: 1920px) and (max-width: 2140px)) {
            width: 76%;
        }

        @media screen and ((min-width: 1820px) and (max-width: 1920px)) {
            width: 78%;
        }

        @media screen and ((min-width: 1820px) and (max-width: 1920px)) {
            width: 80%;
        }

        @media screen and ((min-width: 1620px) and (max-width: 1820px)) {
            width: 82%;
        }

        @media screen and ((min-width: 1440px) and (max-width: 1620px)) {
            width: 84%;

        }


        @media screen and (max-width: 768px) {
            width: 80%;

        }
        @media screen and (max-width: 425px) {
       
       width: 90%;
       justify-content: center;     
           
           }
    }


    &__app-logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 6%;

        @media screen and (max-width: 425px) {
       
       display: none;
   
           
           }

        &__app-logo-img {
            width: 4rem;
            height: 4rem;
            object-fit: scale-down;
            transform: scale(1.2);
        }

        @media screen and (max-width: 500px) {
            width:max-content;

              &__app-logo-img {
            width: 3rem;
            height: 3rem;
        
        }
                
            }

           

        //   @media screen and (max-width: 768px) {
        //     &__app-logo-img {
        //       width: 3rem;
        //       height: 3rem;
        //       object-fit: scale-down;
        //       transform: scale(1.2);
        //   }

        //   }
    }

    &__user-login-section {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 1.5rem;

        width: 56%;
        margin: 0;
        padding: .5rem 0;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #FEE98D;
        border-radius: 8px;
        box-sizing: border-box;


        @media screen and (max-width: 768px) {
            width: 90%;
            border-radius: 4px;
            justify-content: space-around;
            gap: 1rem;



        }

        @media screen and (max-width: 375px) {
                    
                    // width: 80%;
        
                         
                           
                        }





        & p {
            margin: 0;
            padding: 0;
        }

        &__first {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            gap: .5rem;

            @media screen and (max-width: 550px) {
                display: none;
        }

            &__user-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #D8E6E8;
                border-radius: 6px;
                padding: .3rem;

                & i {
                    font-size: 1.5rem;
                    color: #8BB3BE;
                }
            }

            &__user-info {
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                justify-content: center;
                color: #313131af;
                font-weight: 550;
                font-size: .65rem;
                text-transform: capitalize;


                &__name {}

                &__surname {}

            }

        }

        &__second {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: .2rem;

            &__location {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: .3rem;
                color: #313131e2;
                font-weight: 600;
                font-size: .65rem;

                & i {
                    position: relative;
                    font-size: .65rem;
                    color: #38405A;
                }
            }

            &__user-info {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: flex-start;
                color: #313131;
                font-weight: 500;
                font-size: .7rem;


               @media screen and (max-width: 600px){
                font-size: .65rem;

               }


                & a {
                    text-decoration: none;
                    // color: #7868E6;

                }

                & i {
                    position: relative;
                    top: .1rem;
                }

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }



            }

        }

        &__third {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1.5rem;
            margin: 0;
            margin-left: .5rem;
            margin-right: auto;

            @media screen and (max-width: 768px) {
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (max-width: 500px) {
                margin-left: 0;
                margin-right: 0;
                
            }



            &__withdraw {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: .5rem;

                & button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: max-content;
                    height: max-content;
                    border: none;
                    outline: none;
                    border-radius: 4px;
                    background-color: #000;
                    color: #FFCC01;
                    font-size: .6rem;
                    font-weight: 650;
                    padding: .4rem .6rem;
                    gap: .2rem;

                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }

                    @media screen and (max-width: 768px) {
                        border-radius: 2px;

            }

            @media screen and (max-width: 360px) {
                font-size: .55rem;
                padding: .4rem ;

            }


                    & .bxs-right-arrow {
                        margin-top: .1rem;
                    }
                }

            }

            &__balance {

                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                gap: .3rem;

                &__title {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .2rem;
                    font-size: .65rem;
                    font-weight: 550;
                    color: #2a2a2ad7;
                    border-bottom: 2px dashed #452149bf;
                    padding: .2rem 1rem;


                    & i {
                        font-size: .7rem;
                        font-weight: 750;
                    }
                }

                &__amount {
                    font-size: .7rem;
                    font-weight: 750;
                    color: #2a2a2ad7;
                }
            }

        }


        &__time-limitation {

            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            gap: .3rem;
            // border-left: 1px solid #0000003b;
            margin-left: .5rem;


            @media screen and (max-width: 360px) {
                            margin-left: .1rem;
                            gap: .1rem;

            }

            &__title {
                width: max-content;
                font-size: .55rem;
                font-weight: 1000;
                color: #313131;
            }

            &__remaining-ads-daily {

                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                &__container {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .3rem;
                    width: auto;


                    &__count {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 1.4rem;
                        width: 1.4rem;
                        font-size: .6rem;
                        font-weight: 1000;
                        color: #313131;
                        border: 3px dashed #313131;
                        border-radius: 50%;
                        background: #ffffff00;

      

                    }
                }

                &__remaining-ad-refresh-time {
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;
                    margin-left: .7rem;

                    @media screen and (max-width: 425px) {
                        margin-left: .5rem;
                    }


                    &__info {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: center;

                        background: transparent;
                        border: 1px solid #0000001a;
                        border-radius: 6px;
                        padding: .4rem .5rem;


                        @media screen and (max-width: 768px) {
                            border-radius: 2px;
                            margin-left: .2rem;


                        }

                        @media screen and (max-width: 425px) {
                            padding: .2rem .3rem;

                            & img {
                                width: .8rem;
                                height: .8rem;
                            }

                            & p {
                                font-size: .5rem;
                                font-weight: 550;
                            }
                    }




                        gap: .2rem;

                        & img {
                            width: 1rem;
                            height: 1rem;
                            object-fit: scale-down;
                        }

                        & p {
                            font-size: .58rem;
                            font-weight: 600;
                            color: #131313d7;
                        }
                    }

                }
            }
        }

        &__smaller-devices-screen {
            display: grid;
            grid-template-columns: repeat(3, auto); /* Creates 3 columns of equal size */
            grid-gap: .5rem;
            grid-row: 1;
            margin: 0;
            margin-left: auto;

            grid-template-areas: 
    "logout notification menu";

            .logout { grid-area: logout; }
.notification { grid-area: notification; }
.menu { grid-area: menu; }

            @media screen and (max-width: 475px) {
                
                gap: .2rem;
            
         }
         @media screen and (max-width: 360px) {
                
            grid-template-columns: repeat(2, auto);
            grid-row: 2;
            grid-template-areas: 
    "notification menu"
    "logout logout"; 

            gap: .2rem;

            
         }


            & button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2rem;
                height: 2rem;
                border: .5px solid #afc0ff;
                outline: none;
                border-radius: 2px;
                background-color: #ffffff;
                color: #526cc4;
                font-size: 1.1rem;
                font-weight: 650;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }


            @media screen and (max-width: 475px) {
             width: 1.5rem ;
             height: 1.5rem;
             font-size: .8rem;
            
         }
         @media screen and (max-width: 360px) { 
             width: 1rem ;
             height: 1.2rem;
             font-size: .7rem;
             border-radius: 2px;
         }


            }


            &__btn-logout {
                width: max-content !important;
                padding: 0 1rem !important;
                font-size: .7rem !important;
                font-weight: 600 !important;

                @media screen and (max-width: 475px) {
                    
             font-size: .6rem !important;
             font-weight: 550 !important;
             padding: 0 .3rem !important;
            
         }


         @media screen and (max-width: 355px) {
                    
                    font-size: .55rem !important;
                    border-radius: 2px !important;

                 
                   
                }
            }

        }


    }

    &__user-third-section {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 34%;
        margin: 0;

        border-radius: 8px;
        padding: .8rem 0;

        box-sizing: border-box;

        & button {
            background: #fff;
        }

        @media screen and (max-width: 768px) {
            display: none;

        }


        @media screen and (max-width: 550px) {
            

            & button {
                border-radius: 2px !important;
                position: relative;

                & i {
                    position: relative;
                    top: .05rem !important;
                }
               

            }

        }

        &__exit-hamburger-bar {
            &__btn {
                width: max-content;
                height: max-content;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .4rem .8rem;
                border: none;
                outline: none;
                color: #384c51;
                font-size: .6rem;
                font-weight: 600;
                border-radius: 6px;
                gap: .3rem;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                & i {
                    font-size: .9rem;
                    color: #384c51;
                }
            }
        }

        &__settings {
            &__btn {
                width: max-content;
                height: max-content;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .4rem .8rem;
                border: none;
                outline: none;
                color: #384c51;
                font-size: .6rem;
                font-weight: 600;
                border-radius: 6px;
                gap: .3rem;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                & i {
                    font-size: .9rem;
                    color: #384c51;
                }
            }
        }

        &__notifications {
            &__btn {
                width: max-content;
                height: max-content;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .4rem .8rem;
                gap: .3rem;
                border: none;
                outline: none;
                color: #384c51;
                font-size: .6rem;
                font-weight: 600;
                border-radius: 6px;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                & i {
                    font-size: .9rem;
                    color: #384c51;
                }
            }
        }


        &__logout {
            &__btn {
                width: max-content;
                height: max-content;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .4rem .8rem;
                gap: .3rem;
                border: none;
                outline: none;
                color: #384c51;
                font-size: .6rem;
                font-weight: 600;
                border-radius: 6px;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }

                & i {
                    font-size: .9rem;
                    color: #384c51;
                }
            }
        }

        &__select-language {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .5rem;
            padding-top: .2rem;


            &__btn {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                width: max-content;
                height: max-content;
                border: none;
                outline: none;
                border-radius: 0;
                color: #000000;

                font-size: .65rem;
                font-weight: 600;
                padding: 0;
                gap: .3rem;
                //   padding:0 .6rem .2rem .4rem;
                // border-bottom: 1px solid #000000;


                & img {
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-right: .2rem;
                }
            }
        }
    }

}</style>