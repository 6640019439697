const titlesMyAdListLangKeyObj = [
    {
        id: 'title',
        lang_ref_key: 'title',
    },
    {
        id: 'description',
        lang_ref_key: 'description',
    },
    {
        id: 'image',
        lang_ref_key: 'banner',
    },
    {
        id: 'categories',
        lang_ref_key: 'categories',
    },
    {
        id: 'countries',
        lang_ref_key: 'countries',
    },
    {
        id: 'createdDate',
        lang_ref_key: 'createdDate',
    },
    {
        id: 'cities',
        lang_ref_key: 'cities',
    },
    {
        id: 'languages',
        lang_ref_key: 'languages',
    },
    {
        id: 'link',
        lang_ref_key: 'link',
    },
    {
        id: 'viewsCount',
        lang_ref_key: 'targetedViewsCount',
    },
    {
        id: 'reachedViewsCount',
        lang_ref_key: 'reachedViewsCount',
    },
    {
        id: 'statusApproval',
        lang_ref_key: 'approval',
    },


]
export default titlesMyAdListLangKeyObj;