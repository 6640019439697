<template>
  <div class="ads-container">
    <h3 class="ads-container__title">{{ $t('publishAds') }}</h3>
    <p class="ads-container__text">{{ $t('publishAdsDetail') }}</p>
    <div class="ads-container__steps-container">
      <div class="ads-container__steps-container__steps-line">
        <StepsLine :steps="steps" :height="26" :selected-step-id="selectedStep" :key="selectedStep"
          @clickStep="setSelectedStep($event)" v-if="currentSize > 600" />

          <StepLinesHorizantel :steps="steps" :selected-step-id="selectedStep" :key="selectedStep+'horizantel'"
          @clickStep="setSelectedStep($event)" v-else />

          <div v-if="currentSize <= 600"  class="hr"></div>
      </div>
      <div class="ads-container__steps-container__steps-section">
        <div class="ads-container__steps-container__steps-section__set-content" v-show="selectedStep === 'set-content'">
          <h4 class="ads-container__steps-container__steps-section__set-content__title">{{ $t('stepContent') }} </h4>
          <div class="ads-container__steps-container__steps-section__set-content__content-form">
            <div class="ads-container__steps-container__steps-section__set-content__content-form__first">
              <div class="ads-container__steps-container__steps-section__set-content__content-form__first__category">
                <div
                  class="ads-container__steps-container__steps-section__set-content__content-form__first__category__categories-title-container">
                  <button
                    class="ads-container__steps-container__steps-section__set-content__content-form__first__category__categories-title-container__set-category-btn"
                    :class="{ 'selected-btn': selectedCategoryType === 'category' }"
                    @click="selectedCategoryType = 'category'" for="category-ad">{{ $t('categories') }}*</button>
                  <button
                    class="ads-container__steps-container__steps-section__set-content__content-form__first__category__categories-title-container__set-sub-category-btn"
                    :class="{ 'selected-btn': selectedCategoryType === 'subcategory' }"
                    @click="selectedCategoryType = 'subcategory'" for="category-sub-ad">{{ $t('subCategories')
                    }}*</button>

                </div>


                <InteracticeInputWithList :selected-items="selectedCategories" :listToShow="getCategoriesList"
                  refComp="category-ads-ref" v-show="selectedCategoryType === 'category'"
                  :listIcon="'fa-solid fa-bolt-lightning'" :onFocusListShow="true"
                  :placeholder="$t('categoriesPlaceHolder')" :lang="getSelectedLanguage"
                  @update:modelValue="setCategory($event)" />

                <InteracticeInputWithList v-show="selectedCategoryType === 'subcategory'"
                  :selected-items="selectedSubCategories" :listToShow="getSubCategoriesList" refComp="categorysub-ads-ref"
                  :listIcon="'fa-solid fa-bolt-lightning'" :onFocusListShow="true"
                  :placeholder="$t('categoriesSubPlaceHolder')" :lang="getSelectedLanguage"
                  @update:modelValue="setSubCategory($event)" />

              </div>
              <div class="ads-container__steps-container__steps-section__set-content__content-form__first__title">
                <label for="">{{ $t('title') }}*</label>
                <input type="text" :placeholder="$t('titlePlaceHolder')" v-model="contentTitle">

              </div>
              <div class="ads-container__steps-container__steps-section__set-content__content-form__first__description">
                <label for="">{{ $t('description') }}*</label>
                <textarea name="" id="" :placeholder="$t('descriptionPlaceHolder')"
                  v-model="contentDescription"></textarea>
              </div>
            </div>

            <div class="ads-container__steps-container__steps-section__set-content__content-form__second">
              <div class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media">
                <div
                  class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__types">
                  <button @click="setUploadType('image')" :class="{ activeUploderOption: selectedUploadType === 'image' }"
                    class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__types__image-btn">
                    {{ $t('image') }}
                  </button>
                  <button @click="setUploadType('video')" :class="{ activeUploderOption: selectedUploadType === 'video' }"
                    class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__types__video-btn">
                    {{ $t('video') }}
                  </button>
                </div>
                <div v-if="selectedUploadType === 'image'"
                  class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__image">

                  <img v-if="uploadedImage" :src="uploadedImage" alt="uploaded image">
                  <div v-else
                    class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__image__input">
                    <label for="upload-content-image"><i class="bx bx-image-add"></i>
                      <span>{{ $t('uploadImage') }} </span> </label>
                    <input style="width: 0;height: 0;" type="file"   accept="image/jpeg, image/png, image/gif, image/webp, image/svg+xml"  id="upload-content-image"
                      @change="uploadMediaImage($event)">



                  </div>

                </div>
                <div v-else
                  class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__video">
                  <div
                    class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__video__banner-video">

                    <img v-if="uploadedVideoBanner" :src="uploadedVideoBanner" alt="uploaded video banner">
                    <div v-else
                      class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__video__banner-video__input">
                      <label for="upload-content-video-banner"><i class="bx bx-image-add"></i>
                        <span>{{ $t('uploadBanner') }} </span> </label>
                      <input style="width: 0;height: 0;" type="file"   accept="image/jpeg, image/png, image/gif, image/webp, image/svg+xml"  id="upload-content-video-banner"
                        @change="uploadMediaVideoBanner($event)">

                    </div>

                  </div>
                  <div
                    class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__video__upload-video">

                    <video v-if="uploadedVideo" :src="uploadedVideo" controls></video>
                    <div v-else
                      class="ads-container__steps-container__steps-section__set-content__content-form__second__upload-media__video__upload-video__input">
                      <label for="upload-content-video"><i class="bx bx-video"></i>
                        <span>{{ $t('uploadVideo') }} </span> </label>
                      <input style="width: 0;height: 0;" type="file" accept="video/mp4, video/webm, video/ogg" id="upload-content-video"
                        @change="uploadMediaVideo($event)">
                    </div>

                  </div>
                </div>

              </div>
              <div class="ads-container__steps-container__steps-section__set-content__content-form__second__set-link">
                <label for="set-content-link">{{ $t('contentLink') }}</label>
                <input type="text" :placeholder="$t('linkPlaceHolder')" id="set-content-link" v-model="contentLink">
              </div>

              <div
                class="ads-container__steps-container__steps-section__set-content__content-form__second__set-promotion-code">
                <label for="set-content-promotion-code">{{ $t('promotionCode') }}</label>
                <input type="text" :placeholder="$t('promotionCodePlaceHolder')" id="set-content-promotion-code"
                  v-model="contentPromotionCode">
              </div>

              <div
                class="ads-container__steps-container__steps-section__set-content__content-form__second__set-age-limitation">
                <label for="set-content-age-limitation">{{ $t('ageLimitation') }}</label>
                <div
                  class="ads-container__steps-container__steps-section__set-content__content-form__second__set-age-limitation__over-18">
                  <input type="radio" id="over18" name="age" :value="true" v-model="isOver18">
                  <label for="over18">{{ $t('yes') }}</label>
                  <input style="margin-left:1rem;" type="radio" id="under18" name="age" :value="false" v-model="isOver18">
                  <label for="under18">{{ $t('no') }}</label>
                </div>

              </div>

              <div class="ads-container__steps-container__steps-section__set-content__content-form__second__next-btn">
                <button @click="selectedStep = 'set-location'">{{ $t('next') }}</button>
              </div>
            </div>


          </div>

        </div>

        <!-- ___________________________________________________________________________________________________ -->

        <div class="ads-container__steps-container__steps-section__set-location" v-show="selectedStep === 'set-location'">
          <h4 class="ads-container__steps-container__steps-section__set-location__title">{{ $t('stepLocation') }}</h4>
          <div class="ads-container__steps-container__steps-section__set-location__set-location-form">
            <div class="ads-container__steps-container__steps-section__set-location__set-location-form__country">
              <label for="set-location">{{ $t('countries') }}*</label>
              <InteracticeInputWithList :onFocusListShow="true" :selectedItems="selectedCountries"
                :listToShow="[...getRegionList,...getCountryList]" refComp="country-ads-ref" :placeholder="$t('countriesPlaceHolder')"
                :lang="getSelectedLanguage" @update:modelValue="setCountries($event)" />
            </div>
            <div :class="{ disabled: !selectedCountries.length }"
              class="ads-container__steps-container__steps-section__set-location__set-location-form__city">
              <label for="set-location">{{ $t('cities') }}</label>
              <InteracticeInputWithList :selectedItems="selectedCities" :onFocusListShow="true"
                :placeholder="$t('cityPlaceHolder')" refComp="city-ads-ref" :listToShow="getCityList"
                :lang="getSelectedLanguage" @update:modelValue="setCities($event)" />
            </div>

          </div>

          <div class="languages-and-keywords-container">
            <div class="ads-container__steps-container__steps-section__set-location__set-languages">
            <label for="set-content-languages">{{ $t('languages') }}</label>
            <InteracticeInputWithList :onFocusListShow="true" listIcon="fa fa-globe" refComp="languages-ads-ref"
              :placeholder="$t('languagesPlaceHolder')" :selectedItems="selectedLanguages" :listToShow="getLanguagesList"
              :lang="getSelectedLanguage" @update:modelValue="setLanguages($event)" />
          </div>

          <div class="ads-container__steps-container__steps-section__set-location__set-keywords">
            <label for="set-content-keywords">{{ $t('keywords') }}</label>
            <InteracticeInputWithList :onFocusListShow="true" listIcon="fa fa-hashtag" refComp="keywords-ads-ref"
              :placeholder="$t('keywordsPlaceHolder')" :keywordInputToListActive="true" :selectedItems="keywords" 
              :lang="getSelectedLanguage" @update:modelValue="setKeywords($event)" />
          </div>
          </div>


          <div class="ads-container__steps-container__steps-section__set-location__btn-container">
            <button class="ads-container__steps-container__steps-section__set-location__btn-container__prev"
              @click="selectedStep = 'set-content'">{{ $t('previous') }}</button>
            <button class="ads-container__steps-container__steps-section__set-location__btn-container__next"
              @click="selectedStep = 'set-budget'">{{ $t('next') }}</button>
          </div>
        </div>
        <div class="ads-container__steps-container__steps-section__set-budget" v-show="selectedStep === 'set-budget'">
          <div class="ads-container__steps-container__steps-section__set-budget__title-container">
            <h4 class="ads-container__steps-container__steps-section__set-budget__title-container__title">{{ $t('stepBudget') }}</h4>
            <div class="ads-container__steps-container__steps-section__set-budget__title-container__btn">
              <button class="ads-container__steps-container__steps-section__set-budget__title-container__btn__draft-btn" @click="setIsDraft" :class="{selected: isDraft}"><i class="fa-regular fa-bookmark"></i> <span>{{ $t('draft') }} </span> </button>
              <button class="ads-container__steps-container__steps-section__set-budget__title-container__btn__preview-btn" @click="openPreviewScreen"><i class="fa-regular fa-eye"></i> <span>{{ $t('preview') }} </span> </button>

            </div>


          </div>

          <div class="ads-container__steps-container__steps-section__set-budget__btn-container">

            <div class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs">
              <div
                class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-views">
                <label for="views-set-ads"><span>{{ $t('setViewCount') }}</span></label>
                <div
                  class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-views__view-container">
                  <span
                    class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-views__view-container__icon"><i
                      class="bx bx-play-circle"></i></span>
                  <input type="number"
                    class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-views__view-container__input"
                    :placeholder="$t('setViewPlaceholder')" id="views-set-ads" v-model="viewsCount">

                </div>
              </div>
              <div class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-time">
                <label for="show-time-set-ads"><span>{{ $t('setShowTime') }}</span></label>
                <div
                  class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-time__show-time-container">
                  <span
                    class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-time__show-time-container__icon"><i
                      class="bx bx-time"></i></span>
                  <input type="number"
                    class="ads-container__steps-container__steps-section__set-budget__btn-container__inputs__get-ads-time__show-time-container__input"
                    :placeholder="$t('setShowTimePlaceholder')" id="show-time-set-ads" v-model="adShowSec">

                </div>
              </div>



            </div>


            <div class="ads-container__steps-container__steps-section__set-budget__btn-container__cost-info">
              <span style="font-weight: 1000;">{{ viewsCount }}</span> {{ ' ' + $t('watching') + ' ' + $t('and') + ' '
              }} <span style="font-weight: 1000;">{{ adShowSec + ' ' }}</span> {{ $t('watchingSec') + ' ' }} <span
                v-if="viewCost" style="font-weight: 1000;">{{ viewCost + ' ' + userCurrency }}</span>
              <button v-if="viewCost === 0" :class="{disabled: !isCostCalculaterActive || startCalculationCost}" class="get-views-cost-btn" @click="getViewsCost">{{ $t('seeCost') }}
              <span v-if="calculateCostErrorMessage" class="error-message-cost-calculation">{{ calculateCostErrorMessage }}</span>
              </button>


            </div>
            <div class="ads-container__steps-container__steps-section__set-budget__btn-container__target">
              <h4 class="ads-container__steps-container__steps-section__set-budget__btn-container__target__title">{{
                $t('target') }}</h4>
              <div
                class="ads-container__steps-container__steps-section__set-budget__btn-container__target__target-settings">
                <div
                  class="ads-container__steps-container__steps-section__set-budget__btn-container__target__target-settings__age-selection">
                  <label for="age-set-ads"><span>{{ $t('ageGap') }}</span> <span class="age-selected-label">{{ ageGap[0] +
                    ' - ' + ageGap[1] }}</span></label>
                  <NSlider placement="bottom-start" v-model:value="ageGap" :min="minAge" :max="maxAge" range :step="1" />

                </div>
                <div
                  class="ads-container__steps-container__steps-section__set-budget__btn-container__target__target-settings__gender-selection">

                  <label for="gender-set-ads"><span>{{ $t('targetedGender') }}</span></label>
                  <div
                    class="ads-container__steps-container__steps-section__set-budget__btn-container__target__target-settings__gender-selection__gender-selecter">
                    <div>
                      <input type="radio" :value="'female'" id="gender-set-ads" v-model="gender">
                      <label for="gender-set-ads">{{ $t('female') }}</label>
                    </div>
                    <div>
                      <input type="radio" :value="'male'" id="gender-set-ads" v-model="gender">
                      <label for="gender-set-ads">{{ $t('male') }}</label>
                    </div>

                    <div>
                      <input type="radio" :value="'all'" id="gender-set-ads" v-model="gender">
                      <label for="gender-set-ads">{{ $t('all') }}</label>
                    </div>

                  </div>
                </div>
                <div
                  class="ads-container__steps-container__steps-section__set-budget__btn-container__target__target-settings__min-per-user-view">
                  <label for="min-per-user-view"><span>{{ $t('minPerUserView') }}</span></label>
                  <input :min="minumumPerUserViewCount" type="number" :placeholder="$t('setMinPerUserView')"
                    id="min-per-user-view" v-model="perUserViewCount">
                </div>
              </div>


            </div>
            <div class="ads-container__steps-container__steps-section__set-budget__btn-container__btn">
              <button class="ads-container__steps-container__steps-section__set-budget__btn-container__btn__prev"
                @click="selectedStep = 'set-location'">{{ $t('previous') }}</button>
              <button class="ads-container__steps-container__steps-section__set-budget__btn-container__btn__next"
              :class="{disabled: !isPublishable}"   @click="publishAd">{{ $t('publish') }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ModalContainer :isExitable="true" v-if="isPreviewActive && selectedAdToPreview"  @closeModal="closeModal($event)">
    <AdShowDetaily :isCommercialPreview="true" :ad="selectedAdToPreview" @closeModal="closeModal($event)" />
  </ModalContainer>
  </div>


</template>

<script>
import StepsLine from '@/utils/StepsLine.vue'
import StepLinesHorizantel from '@/utils/StepLinesHorizantel.vue'

import InteracticeInputWithList from '@/utils/InteractiveInputWithList.vue'
import CategoriesAds from '@/assets/categories/indexCategories.json'
import countryList from '@/assets/countries/countryList.json'
import languagesList from "@/assets/languages/indexLanguages.json"
import countryDetail from '@/assets/countries/countriesDetails.json'
import regionList from '@/assets/countries/regionDetails.json'
import ModalContainer from '@/utils/ModalContainer.vue'
import AdShowDetaily from '../indivudal-user-section/AdShowToIndividualDetaily.vue'
import { mapGetters } from 'vuex'
import { throttle, debounce } from 'lodash'

export default {
  components: {
    StepsLine,
    InteracticeInputWithList,
    StepLinesHorizantel,
    ModalContainer,
    AdShowDetaily
  },
  data() {
    return {
      currentSize: 0,
      selectedStep: 'set-content',
      uploadedImage: null,
      selectedImageFile: null,
      uploadedVideo: null,
      selectedVideoFile: null,
      uploadedVideoBanner: null,
      selectedAdToPreview:null,
      selectedVideoBannerFile: null,
      selectedCategoryType: 'category',
      selectedSubCategories: [],
      keywords:[],
      contentTitle: '',
      contentDescription: '',
      contentPromotionCode: '',
      contentLink: '',
      selectedCountries: [],
      selectedLanguages: [],
      selectedCategories: [],
      selectedCities: [],
      categoriesAdsList: CategoriesAds,
      countryList: countryList,
      regionList,
      cities: [],
      gender: 'all',
      ageGap: [7, 100],
      minAge: 7,
      maxAge: 100,
      minumumPerUserViewCount: 1,
      isDraft: false,
      calculateCostErrorMessage: '',
      publisableErrorMessage: '',
      perUserViewCount: 3,
      languagesList,
      isOver18: false,
      countryDetail,
      userCurrency: '',
      userCurrencyIcon: '',
      viewCost: 0,
      viewsCount: 100,
      adShowSec: 10,
      selectedUploadType: 'image',
      startCalculationCost : false,
      costDetails: {},
      isPreviewActive: false,



      steps: [
        {
          index: 0,
          id: 'set-content',
          title: this.$t('stepContent'),
          icon: 'bx bxs-edit-alt',
        },
        {
          index: 1,
          id: 'set-location',
          title: this.$t('stepLocation'),
          icon: 'bx bx-location-plus',
        },
        {
          index: 2,
          id: 'set-budget',
          title: this.$t('stepBudgetAndTarget'),
          icon: 'bx bx-wallet-alt',
        }
      ]
    }
  },

  beforeMount() {
    this.setDeviceScreenSize()  
  },

  computed: {
    ...mapGetters({ getUserCommercialInfo: 'commercialUserModule/getUserCommercialInfo', getFilterCategories: 'commercialUserModule/getFilterCategories', getUserBalance: 'commercialUserModule/getUserBalance' }),
    ...mapGetters(['getSelectedLanguage','getScreenType']),
    getCityList() {

      const cities = this.cities.map(city => {
        return {
          name: city.name,
          id: city.code,
          countryCode: city.countryCode
        }
      })
      return cities
    },

    getCountryList() {
      return this.countryList.map(country => {
        return {
          name: country.names[this.getSelectedLanguage],
          id: country['alpha-2']
        }
      })
    },

    getRegionList() {
      return this.regionList.map(region => {
        return {
          name: region.names[this.getSelectedLanguage],
          id: region['alpha-2']
        }
      })
    },

    getCategoriesList() {
      return this.getFilterCategories.map(category => {
        return {
          name: category.name,
          id: category.id
        }
      }
      )
    },

    

    isCostCalculaterActive() {
   
        if(this.viewsCount >= 100  && this.adShowSec >= 10 && this.selectedCountries.length && this.selectedCategories.length) {
       this.calculateCostErrorMessage = ''
        return true
      } 
      this.calculateCostErrorMessage = this.getCalculateCostErrorMessage();
      return false
  
    
    },
    isPublishable() {
      
      if(this.viewsCount < 100) {
        this.publisableErrorMessage = this.$t('enterViewsCountError')
      } else if (this.adShowSec < 10) {
        this.publisableErrorMessage = this.$t('enterAdShowSecError')
      }
      else if (!this.selectedCountries.length) {
        this.publisableErrorMessage = this.$t('enterCountriesError')
      }
      else if (!this.selectedCategories.length) {
        this.publisableErrorMessage = this.$t('enterCategoriesError')
      }
      else if (!this.selectedSubCategories.length) {
        this.publisableErrorMessage = this.$t('enterSubCategoriesError')
      }
      else if(!this.contentTitle) {
        this.publisableErrorMessage = this.$t('enterContentTitleError')
      }
      else if(!this.contentDescription) {
        this.publisableErrorMessage = this.$t('enterContentDescriptionError')
      }
      else if(this.perUserViewCount < this.minumumPerUserViewCount) {
        this.publisableErrorMessage = this.$t('enterPerUserViewCountError')
      }
      else if(!this.viewCost){
        this.publisableErrorMessage = this.$t('enterViewCostError')
      }
      else if(!this.getUserBalance) {
        this.publisableErrorMessage = this.$t('enterBalanceError')
      }

      else {
        this.publisableErrorMessage = ''
      }
      return !this.publisableErrorMessage
    },
    getSubCategoriesList() {
      if (this.selectedCategories.length) {

        let subCategories = this.selectedCategories.map(category => {
          const categoryExist = this.getFilterCategories.find(cat => cat.id === category)
          if (categoryExist) {
            return categoryExist.subcategories
          }
          return null

        })

        subCategories = subCategories.filter(Boolean).flat()
        return subCategories.map(subcategory => {
          return {
            name: subcategory.name,
            id: subcategory.id
          }
        })
      }

      return [];
    },
    getLanguagesList() {
      return this.languagesList.map(language => {
        return {
          name: language.translates[this.getSelectedLanguage],
          id: language.lang
        }
      })
    },
  },
  created() {
    this.setUserCurrency()
  },
  watch: {
    getSubCategoriesList: {

      async handler(newSubCategories) {
        let newSubCategoriesIds = newSubCategories.map(subcategory => {
          return subcategory.id
        })
        let selectedSubCategories = this.selectedSubCategories.filter(subcategory => {
          return newSubCategoriesIds.includes(subcategory)
        })
        this.setSubCategory(selectedSubCategories)
      },
      deep: true
    },
    isOver18(){

      if(this.isOver18) {
        this.minAge = 18;
        this.ageGap[0]  = 18
        if(this.ageGap[1] <= 18) {
          this.ageGap[1] = 19
        }

      } else {
        this.minAge = 7;
        this.ageGap[0]  = 7
      }
    },

    viewsCount: 'resetViewCost',

    adShowSec: 'resetViewCost',

    selectedCategories:{

      async handler(newCategories) {
        this.resetViewCost()

      },
      deep: true
    },
    selectedCountries: {

      async handler(newCountries) {
          this.resetViewCost()
        if (!newCountries.length) {
          this.cities = [];
          this.selectedCities = [];
        }
        if (newCountries.length) {
          await this.updateCitiesList()
        }
        if (this.selectedCities.length) {
          this.updateCities()
        }

      },
      deep: true

    },

  },
  methods: {
    setCurrentPage(page) {
            this.$store.commit('setSelectedCommercialSection', page)
        },

    setIsDraft() {

      this.$swal
        .fire({
          title: this.$t("areYouSureDraft"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then(async (result) => {
          try {
            if (result.isConfirmed) {
            this.isDraft = true;
            if (this.isDraft) {
        const userId = this.getUserCommercialInfo?.userId || null
        const draftObj = {
          ad:{
            title: this.contentTitle,
          description: this.contentDescription,
          promotionCode: this.contentPromotionCode,
          link: this.contentLink,
          countries: this.selectedCountries,
          cities: this.selectedCities,
          categories: this.selectedCategories,
          subCategories: this.selectedSubCategories,
          languages: this.selectedLanguages,
          isOver18: this.isOver18,
          viewCost: (this.costDetails?.convertedAmount || {})?.conversion_result || 0,
          viewsCount: this.viewsCount,
          adShowSec: this.adShowSec,
          currency: this.userCurrency,
          keywords: this.keywords,
          gender: this.gender,
          ageGap: this.ageGap,
          costDetails: this.costDetails,
          perUserViewCount: this.perUserViewCount,
          role: this.getUserCommercialInfo.role,
          lang: this.getSelectedLanguage,
          userId: this.getUserCommercialInfo.userId,
          commercialName: this.getUserCommercialInfo.companyName || 'no-name'
          },
          userId
        }
        this.$store.commit('setIsLoading', true)
       const repsonse = await this.$store.dispatch('commercialUserModule/setAdDraft', draftObj)
      if(repsonse) {
        this.setCurrentPage('MyAdsList')
      }
      }
          }
          else {
            this.isDraft = false;
          }
          } catch (error) {
            console.log(error)
            this.isDraft = false
          }
          finally {
            this.$store.commit('setIsLoading', false)
          }
   

        });




    },

    closeModal() {
      this.isPreviewActive = false;
      this.selectedAdToPreview = null
    },

    setSelectedAdToPreview() {
      let categories = this.categoriesAdsList.filter(category => {
        return this.selectedCategories.includes(category.id)
      }).map(category => {
        return category?.names[this.getSelectedLanguage]
      }).join(', ')
      this.selectedAdToPreview =  {
        title: this.contentTitle,
        description: this.contentDescription,
        image: {
          bucketLocation: this.uploadedImage
        },
        video: {
          bucketLocation: this.uploadedVideo
        },
        videoBanner: {
          bucketLocation: this.uploadedVideoBanner
        },
        link: this.contentLink,
        categories,
        countries: this.selectedCountries,
        cities: this.selectedCities,
        languages: this.selectedLanguages,
        isOver18: this.isOver18,
        adShowSec: this.adShowSec,
        commercialName: this.getUserCommercialInfo?.commercialName || '',
        promotionCode: this.contentPromotionCode,


      }
    },

    openPreviewScreen(){
      this.isPreviewActive = true
      this.setSelectedAdToPreview()
    },

    setKeywords(keywords) {
      console.log(keywords)
      this.keywords = keywords
    },

    getCalculateCostErrorMessage() {

      if(this.viewsCount < 100) {
        return this.$t('enterViewsCountError')
      } else if (this.adShowSec < 10) {
        return this.$t('enterAdShowSecError')
      }
      else if (!this.selectedCountries.length) {
        return this.$t('enterCountriesError')
      }
      else if (!this.selectedCategories.length) {
        return this.$t('enterCategoriesError')

      }
    },

    resetViewCost() {
      this.viewCost = 0;
      this.costDetails = {};
    },

    

    setUploadType(type) {

      this.selectedUploadType = type;

      if (type === 'image') {
        this.uploadedImage = null
        this.selectedImageFile = null
      }

      if (type === 'video') {
        this.uploadedVideo = null
        this.selectedVideoFile = null
      }

    },

    getViewsCost: throttle(async function () {

      try {
        this.startCalculationCost = true
        const response = await this.$store.dispatch('commercialUserModule/getAdPublishCost', {
          lang: this.getSelectedLanguage,
          countries: this.selectedCountries,
          currencyFrom: 'USD',
          currencyTo: this.userCurrency,
          categories: this.selectedCategories,
          userId: this.getUserCommercialInfo.userId,
          targetedView: this.viewsCount,
          viewSec: this.adShowSec

        })
        if(response){
          this.viewCost = +(((response?.convertedAmount || {})?.conversion_result).toFixed(2)) || 0;
          this.costDetails = response || {};
        }
      }
      catch (error) {
        console.log(error);
      }
      finally {
        this.startCalculationCost = false
      }
    }, 1000),
    updateCities() {

      this.selectedCities = this.selectedCities.filter(cityId => {
        const isCityExist = this.cities.find(city => city.code === cityId)
        return isCityExist
      })
    },
    async updateCitiesList() {
      const countriesCode = this.selectedCountries;
      const lang = this.getSelectedLanguage;
      try {
        const result = await this.$store.dispatch('getCitiesByCountryCodeAndLanguage', { countriesCode, language: lang });
        this.cities = result
      } catch (error) {
        console.log(error);
        this.cities = [];

      }
    },
    setSelectedStep(indexStep) {
      this.selectedStep = this.steps[indexStep]?.id || 'set-content';
    },

    getUserCurrencyIcon(currency) {
      return '$'
    },
    setUserCurrency() {
      this.userCurrency = this.getUserCommercialInfo.currency?.currency || 'none';
      this.userCurrencyIcon = this.getUserCurrencyIcon(this.userCurrency)
    },

    async uploadMediaImage(event) {
      const file = event.target.files[0]; 
  if (file) {
    // Thumbnail requirements
    const maxImageSizeMB = 2;
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp','image/svg+xml'];
    const requiredWidth = 1280;
    const requiredHeight = 720;

    if (!allowedImageTypes.includes(file.type)) {
      this.$swal.fire({
        title: this.$t('invalidFormat'),
        text: this.$t('imageFormatError'),
        icon: 'error',
      });
      return;
    }

    if (file.size / (1024 * 1024) > maxImageSizeMB) {
      this.$swal.fire({
        title: this.$t('fileSizeToLarge'),
        text: this.$t('fileSizeLimit'),
        icon: 'error',
      });
      return;
    }

    // Validate dimensions
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // if (image.width !== requiredWidth || image.height !== requiredHeight) {
      //   this.$swal.fire({
      //     title: this.$t('invalidDimensions'),
      //     text: this.$t('invalidDimensionsError') + ' ' + requiredWidth + 'x' + requiredHeight + ' ' + this.$t('pixels'),
      //     icon: 'error',
      //   });
      // } else {
      //   this.selectedImageFile  = file; // Save file for upload
      //   this.uploadedImage = image.src; // Preview

      // }
      this.selectedImageFile  = file; // Save file for upload
        this.uploadedImage = image.src; // Preview
    };
  }
},

// async uploadMediaVideoBanner(event) {
//   const file = event.target.files[0]; // Kullanıcının seçtiği dosya

//   if (file) {
//     // Thumbnail requirements
//     const maxImageSizeMB = 2;
//     const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp', 'image/svg+xml'];
//     const requiredWidth = 1280;
//     const requiredHeight = 720;

//     // Format validation
//     if (!allowedImageTypes.includes(file.type)) {
//       this.$swal.fire({
//         title: this.$t('invalidFormat'),
//         text: this.$t('imageFormatError'),
//         icon: 'error',
//       });
//       return;
//     }

//     // Size validation
//     if (file.size / (1024 * 1024) > maxImageSizeMB) {
//       this.$swal.fire({
//         title: this.$t('fileSizeToLarge'),
//         text: this.$t('fileSizeLimit'),
//         icon: 'error',
//       });
//       return;
//     }

//     // Check for canvas support
//     if (!document.createElement('canvas').getContext) {
//       console.warn('Canvas is not supported in this browser. Uploading the original image.');
//       this.selectedVideoBannerFile = file; // Save file for upload
//       this.uploadedVideoBanner = URL.createObjectURL(file); // Preview
//       return;
//     }

//     // Image resizing process
//     const image = new Image();
//     const reader = new FileReader();

//     reader.onload = (e) => {
//       image.src = e.target.result; // Image source set from file
//     };

//     image.onload = async () => {
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');

//       // Set canvas dimensions to required size
//       canvas.width = requiredWidth;
//       canvas.height = requiredHeight;

//       // Draw the image onto the canvas, resizing it to the required dimensions
//       ctx.drawImage(image, 0, 0, requiredWidth, requiredHeight);

//       // Convert the canvas back to a blob or data URL
//       const resizedImageBlob = await new Promise((resolve) =>
//         canvas.toBlob(resolve, file.type, 0.8) // Convert to Blob with quality 0.8
//       );

//       // Save the resized image for upload
//       this.selectedVideoBannerFile = new File([resizedImageBlob], file.name, { type: file.type });
//       this.uploadedVideoBanner = canvas.toDataURL(file.type); // Optional preview

//     };

//     reader.readAsDataURL(file);
//   }
// },



    async uploadMediaVideoBanner(event) {

        const file = event.target.files[0]; 
  if (file) {
    // Thumbnail requirements
    const maxImageSizeMB = 2;
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp','image/svg+xml'];
    const requiredWidth = 1280;
    const requiredHeight = 720;

    if (!allowedImageTypes.includes(file.type)) {
      this.$swal.fire({
        title: this.$t('invalidFormat'),
        text: this.$t('imageFormatError'),
        icon: 'error',
      });
      return;
    }

    if (file.size / (1024 * 1024) > maxImageSizeMB) {
      this.$swal.fire({
        title: this.$t('fileSizeToLarge'),
        text: this.$t('fileSizeLimit'),
        icon: 'error',
      });
      return;
    }

    // Validate dimensions
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // if (image.width !== requiredWidth || image.height !== requiredHeight) {
      //   this.$swal.fire({
      //     title: this.$t('invalidDimensions'),
      //     text: this.$t('invalidDimensionsError') + ' ' + requiredWidth + 'x' + requiredHeight + ' ' + this.$t('pixels'),
      //     icon: 'error',
      //   });
      // } else {
      //   this.selectedVideoBannerFile  = file; // Save file for upload
      //   this.uploadedVideoBanner = image.src; // Preview

      // }

      this.selectedVideoBannerFile  = file; // Save file for upload
        this.uploadedVideoBanner = image.src; // Preview
    };
  }
    },

    async uploadMediaVideo(event) {
  const file = event.target.files[0]; // Kullanıcının seçtiği dosya
  if (file) {
    const maxVideoDuration = 30; // Maksimum video süresi (saniye)
    const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg']; // İzin verilen video formatları
    const maxVideoSize = 10 * 1024 * 1024; // Maksimum video boyutu (10 MB)


    if (!allowedVideoTypes.includes(file.type)) {
      this.$swal.file({
        title: this.$t('invalidFormat'),
        text: this.$t('videoFormatError'),
        icon: 'error',
      });
      return;
    }

    // FileReader ile video süresini kontrol et
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src); // Bellek temizliği

      if (video.duration > maxVideoDuration) {
        this.$swal.fire({
          title: 'Video Too Long!',
          text: this.$t('videoDurationError2') + ' ' + maxVideoDuration + ' ' + this.$t('seconds'),
          icon: 'error',
        });
        return;
      }

      if (file.size > maxVideoSize) {
        this.$swal.fire({
          title: this.$t('fileSizeToLarge'),
          text: this.$t('fileSizeVideoLimit'),
          icon: 'error',
        });
        return;
      }

      // Eğer geçerliyse
      this.selectedVideoFile = file; // Yükleme için dosyayı kaydet
      this.uploadedVideo = video.src; // Görsel önizleme için URL oluştur

    };

    video.onerror = () => {
      this.$swal.fire({
        title: 'Error!',
        text: this.$t('videoProcessingError'),
        icon: 'error',
      });
    };
  }
},


    async publishAd() {
      if(!this.isPublishable){
        return
      }
      try {
        // FormData objesi oluştur
        const formData = new FormData();

        if (this.selectedImageFile) {
          // 'adImage' dosyasını FormData'ya ekle
          formData.append('adImage', this.selectedImageFile);
        }

        if (this.selectedVideoFile) {
          // 'adVideo' dosyasını FormData'ya ekle
          formData.append('adVideo', this.selectedVideoFile);
        }

        if (this.selectedVideoBannerFile) {
          // 'adVideoBanner' dosyasını FormData'ya ekle
          formData.append('adVideoBanner', this.selectedVideoBannerFile);
        }

        // Diğer verileri JSON olarak string'e çevir ve FormData'ya ekle
        formData.append('data', JSON.stringify({
          title: this.contentTitle,
          description: this.contentDescription,
          promotionCode: this.contentPromotionCode,
          link: this.contentLink,
          countries: this.selectedCountries,
          cities: this.selectedCities,
          categories: this.selectedCategories,
          subCategories: this.selectedSubCategories,
          languages: this.selectedLanguages,
          isOver18: this.isOver18,
          viewCost: (this.costDetails?.convertedAmount || {})?.conversion_result || 0,
          viewsCount: this.viewsCount,
          adShowSec: this.adShowSec,
          currency: this.userCurrency,
          keywords: this.keywords,
          gender: this.gender,
          ageGap: this.ageGap,
          costDetails: this.costDetails,
          perUserViewCount: this.perUserViewCount,
          role: this.getUserCommercialInfo.role,
          lang: this.getSelectedLanguage,
          userId: this.getUserCommercialInfo.userId,
          commercialName: this.getUserCommercialInfo.companyName || 'no-name'
        }));
        const result = await this.$store.dispatch('commercialUserModule/publishAd', formData)
        if (result) {
          console.log(result)
          this.$store.dispatch('commercialUserModule/getUpdatedBalance',{
            userId: this.getUserCommercialInfo.userId,
            role: this.getUserCommercialInfo.role
          })
          this.$store.commit('setSelectedCommercialSection', 'MyAdsList')
        }
      } catch (error) {
        console.log(error)
      }
    },

    setCountries(countries) {
      console.log(countries)
      this.selectedCountries = countries
    },
    setCities(cities) {
      this.selectedCities = cities
    },

    setCategory(categories) {
      this.selectedCategories = categories
    },
    setSubCategory(categories) {
      this.selectedSubCategories = categories
    },

    setLanguages(languages) {
      this.selectedLanguages = languages
    },
    setDeviceScreenSize() {

const currentWidth = window.innerWidth || window.screen.width || 0;
this.currentSize = currentWidth;
    
}

  }

}
</script>

<style scoped lang="scss">

.hr {
  min-width: 100%;
  min-height: 1px;
  background-color: #D9D9D9;
  margin-top: 1.5rem;
}
*::placeholder {
  font-weight: 500;
  font-size: .65rem !important;
  font-family: 'Opens Sans', sans-serif !important;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: .4;


}

.activeUploderOption {
  background: #314871 !important;
  color: #fff !important;
}

.ads-container {

  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .5rem;


  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }

  & p,
  h3 {
    margin: 0;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 800;
    color: #2A2A2A;
    margin: 0;
  }

  &__text {
    font-size: .7rem;
    font-weight: 500;
    color: #2a2a2a93;
    margin-bottom: .5rem !important;
  }

  &__steps-container {
    display: flex;
    width: 100%;
    height: 26.5rem;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .1rem;
    @media screen and (max-width: 600px) {
      height: max-content;
      gap: 1rem;
      flex-flow: column nowrap;

  }

    &__steps-line {
      display: flex;
      width: 12%;
      padding-top: .5rem;
      height: 100%;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: 600px) {
    width: 100%;
    height: max-content;

   
  }
    }

    &__steps-section {
      display: flex;
      width: 80%;
      height: 106%;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;

      @media screen and (max-width: 600px) {
        width: 100%;
        height: max-content;
      }


      &__set-content,
      &__set-location,
      &__set-budget {
        display: flex;
        width: 100%;
        height: 100%;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;

        & h4 {
          font-size: 1rem;
          font-weight: 700;
          color: #2A2A2A;
          text-transform: capitalize;
          margin: 0;
          margin-top: .5rem;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          gap: .35rem;

          & i {
            margin-top: .25rem;
            font-size: 1.3rem;
          }
        }


      }

      &__set-content {
        &__content-form {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: row nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: .5rem;

  

          &__first,
          &__second {
            display: flex;
            width: 46%;
            height: 100%;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;
          }


          &__first {
            justify-content: flex-start;
            gap: 0 !important;
            height: 100%;
   


            &__category {
              display: flex;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: .2rem;
              width: 80%;
              margin-bottom: .5rem;

              &__categories-title-container {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: start;
                gap: 1rem;

                & button {
                  display: flex;
                  width: 6rem;
                  height: 1.5rem;
                  flex-flow: column nowrap;
                  align-items: center;
                  justify-content: center;
                  gap: .5rem;
                  border: 1px solid #28303e56;

                  border-radius: 8px;
                  font-size: .58rem;
                  font-weight: 650;
                  line-height: 3rem;
                  background: #fff;
                  color: #28303E;
                  cursor: pointer;

                  &:hover {
                    opacity: .8;
                  }

                  @media screen and (max-width: 768px) {
           width: max-content;
        }
                }

                & .selected-btn {
                  background: #314871 !important;
                  color: #fff !important;
                }

              }

              & label {
                font-size: .65rem;
                font-weight: 600;
                color: #28303E;
                text-transform: capitalize;
              }
            }

            &__title {
              color: #28303E;
              margin: 0;
              margin-top: .5rem;
              display: flex;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: .2rem;
              width: 80%;
              margin-bottom: 1rem;

              & label {
                font-size: .65rem;
                font-weight: 600;
                color: #28303E;
                text-transform: capitalize;
              }

              & input {
                display: flex;
                width: 100%;
                height: 100%;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: .5rem;
                border: 1px solid #dadbe1;
                padding: .5rem 1rem;
                border-radius: 6px;
                font-size: .7rem;
              }
            }

            &__description {
              display: flex;
              width: 80%;
              height: 100%;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: .5rem;

              & label {
                font-size: .65rem;
                font-weight: 600;
                color: #28303E;
                text-transform: capitalize;
              }

              & textarea {
                display: flex;
                width: 100%;
                height: 100%;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: .5rem;
                border: 1px solid #dadbe1;
                padding: .5rem 1rem;
                border-radius: 6px;
                font-size: .7rem;

                @media screen and (max-width: 600px) {
                  height:6.8rem;
                }


              }
            }


          }

          &__second {

            display: flex;
            width: 50% !important;
            gap: 0 !important;



            &__upload-media {
              margin: 0 auto;
              width: 100%;
              height: 7.75rem;
              margin-bottom: .5rem;
              display: flex;
              flex-flow: row nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 1.2rem;

              &__types {
                display: flex;
                width: max-content;
                height: max-content;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: .5rem;

                & button {
                  display: flex;
                  height: 1.3rem;
                  width: 3.6rem;
                  transition: all .1s ease-in-out;

                  flex-flow: column nowrap;
                  align-items: center;
                  justify-content: center;
                  gap: .5rem;
                  border: 1px solid #e8e8e8;
                  padding: .2rem .8rem;
                  border-radius: 8px;
                  font-size: .6rem;
                  font-weight: 550;
                  text-transform: capitalize;
                  background: transparent;
                  color: #727a87;
                  transition: all .3s ease-in-out;
                  cursor: pointer;
                }
              }

              &__image {
                width: 100%;

                & img {
                  height: 7.3rem;
                  width: 100%;
                  object-fit: scale-down;
                  border-radius: 12px !important;
                }

              }

              &__video {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: flex-start;

                & label {
                  width: 100% !important;
                }

                &__banner-video {
                  width: 48%;

                  img {
                    height: 7.75rem;
                    width: 100%;
                    object-fit: scale-down;
                    border-radius: 12px !important;
                  }
                }

                &__upload-video {
                  width: 48%;

                  video {
                    height: 7.75rem;
                    width: 100%;
                    object-fit: scale-down;
                    border-radius: 12px !important;
                  }
                }

              }


              & label {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                gap: .1rem;
                width: 80%;
                height: 7.75rem;
                border-radius: 12px;
                border: 1px dashed #dadbe1;
                font-size: .7rem;
                text-transform: capitalize;
                font-weight: 600;
                background: #F9F9F9;
                transition: all .3s ease-in-out;
                cursor: pointer;

                &:hover {
                  border-color: #28303E;
                }

                & i {
                  font-size: 1.6rem;
                }

              }

            }

            &__next-btn {
              display: flex;
              width: 100%;
              height: max-content;
              flex-flow: row nowrap;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: auto;


              & button {
                display: flex;
                width: max-content;
                height: max-content;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                gap: .5rem;
                border: 1px solid #28303E;
                background: black;
                border-radius: 12px;
                padding: .4rem 1rem;
                font-size: .65rem;
                font-weight: 600;
                text-transform: capitalize;
                color: #fff;
                cursor: pointer;

                &:hover {
                  opacity: .8;
                }
              }
            }

            &__set-age-limitation {
              display: flex;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: .5rem;
              width: 100%;
              margin-top: 1rem;


              & label {
                font-size: .65rem;
                font-weight: 600;
                color: #28303E;
                text-transform: capitalize;
              }

              &__over-18 {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .2rem;

                & label {
                  font-size: .65rem;
                  font-weight: 550;
                  color: #28303E;
                  text-transform: capitalize;
                  margin-top: .25rem;
                }

                & input {
                  display: flex;
                  height: auto;
                }
              }
            }

            &__set-link,
            &__set-promotion-code {

              color: #28303E;
              display: flex;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: .2rem;
              width: 100%;
              margin-top: 1.1rem;

              & label {
                font-size: .65rem;
                font-weight: 600;
                color: #28303E;
                text-transform: capitalize;
              }

              & input {
                display: flex;
                width: 92% !important;
                height: 100%;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: .5rem;
                border: 1px solid #dadbe1;
                padding: .48rem 1rem;
                border-radius: 6px;
                font-size: .7rem;
              }


            }

            &__set-promotion-code {
              & label {
                margin-bottom: .2rem !important;
              }
            }



          }
        }

      }

      &__set-location {

        display: flex;
        width: 90%;
        height: 100%;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;


        &__btn-container {

          display: flex;
          width: 100%;
          height: max-content;
          flex-flow: row nowrap;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 1rem;


          & button {
            display: flex;
            width: max-content;
            height: max-content;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            gap: .5rem;
            border: 1px solid #28303E;
            background: black;
            border-radius: 12px;
            padding: .4rem 1rem;
            font-size: .65rem;
            font-weight: 650;
            text-transform: capitalize;
            color: #fff;
            cursor: pointer;

            &:hover {
              opacity: .8;
            }
          }

          &__prev {
            background: #f2f2f2 !important;
            border: 1px solid #f2f2f2 !important;
            color: #000000 !important;
          }

        }

        & .languages-and-keywords-container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: row wrap;
          align-items: flex-start;
          justify-content: space-between;
        }

        &__set-languages, &__set-keywords {

          display: flex;
          width: 46%;
          height: 100%;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: .1rem;

          & label {
            font-size: .65rem;
            font-weight: 600;
            color: #28303E;
            text-transform: capitalize;
          }


        }

        &__set-location-form {

          display: flex;
          width: 100%;
          height: auto;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;

          &__country,
          &__city {
            display: flex;
            width: 46%;
            height: 100%;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: .65rem;

            & label {
              font-size: .65rem;
              font-weight: 600;
              color: #28303E;
              text-transform: capitalize;
            }


          }

        }

      }


      &__set-budget {

        display: flex;
        width: 90%;
        height: 100%;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;


        @media screen and (max-width: 600px) {
          width: 100%;

        }


        &__title-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          width: 98.5%;

          &__title {
            font-size: 1.6rem;
            font-weight: 800;
            color: #2A2A2A;
            margin: 0;
          }

          &__btn {

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            box-sizing: border-box;

            & button {
              display: flex;
              width: 5.5rem;
              height: 1.6rem;
              flex-flow:row nowrap;
              align-items: center;
              justify-content: center;
              gap: .4rem;
              background: #e8f0ff;
              border-radius: 4px;
              font-size: .6rem;
              font-weight: 750;
              text-transform: capitalize;
              border: 1px solid #e8f0ff !important;

              color: #494fba;
              cursor: pointer;

              &:hover {
                opacity: .8;
              }

              & i {
                font-size: .8rem;

              }
            }

            & .selected {
              border: 1px solid #494fba !important;

        
            }

        }



        }

        &__btn-container {

          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1.5rem;

          &__target {
            display: flex;
            width: 100%;
            height: max-content;
            flex-flow: column nowrap;

            &__title {
              font-size: 1rem;
              font-weight: 700;
              width: max-content;
              color: #2A2A2A;
              text-transform: capitalize;
              margin: 0;
              margin-right: auto;

            }


            &__target-settings {

              display: flex;
              width: 100%;
              height: 100%;
              flex-flow: row wrap;
              align-items: center;
              justify-content: space-between;
              margin-top: 1rem;


              & label {
                font-size: .7rem;
                font-weight: 600;
                color: #28303E;
                text-transform: capitalize;

              }


              &__min-per-user-view {

                display: flex;
                width: 20%;
                height: 100%;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: flex-start;

                & label {
                  margin-top: .6rem;
                }

                & input {
                  border: 1px solid #dadbe1;
                  padding: .2rem .6rem .2rem 1rem;
                  border-radius: 0;
                  font-size: .7rem;
                  font-weight: 600;
                  text-align: center;
                  width: 30%;
                  margin: 0 auto;
                  margin-top: 1.3rem;
                  border-radius: 6px;
                  display: flex;
                  align-self: flex-end;



                }



              }

              &__age-selection {

                display: flex;
                width: 38%;
                height: 100%;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: .5rem;


                & label {
                  display: flex;
                  width: 100%;
                  height: 100%;
                  flex-flow: row nowrap;
                  align-items: center;
                  justify-content: space-between;
                  position: relative;
                  bottom: .3rem;
                }

                & .age-selected-label {
                  font-weight: 700;
                  color: #2A2A2A;
                  background: #e1ffe3;
                  border-radius: 6px;
                  padding: .5rem 1rem;
                }

                &__age-selecter {
                  display: flex;
                  width: 100%;
                  position: relative;
                  bottom: .3rem;
                }

              }

              &__gender-selection {

                display: flex;
                width: 35%;
                height: 100%;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                padding-left: 2rem;

                & label {
                  margin-top: .6rem;
                }

                &__gender-selecter {
                  display: flex;
                  width: 100%;
                  height: 100%;
                  flex-flow: row nowrap;
                  align-items: flex-end;
                  justify-content: flex-start;
                  gap: .6rem;
                  position: relative;
                  bottom: -.75rem;

                  & div {
                    display: flex;
                    width: max-content;
                    height: 100%;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .2rem;


                    & label {
                      font-size: .65rem;
                      font-weight: 600;
                      color: #28303E;
                      text-transform: capitalize;
                      position: relative;
                      bottom: .15rem;

                    }

                    & input {
                      width: .9rem;
                      height: .9rem;
                    }
                  }
                }


              }
            }
          }

          &__cost-info {

            display: flex;
            width: 92%;
            height: max-content;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: .8rem 1.5rem;
            border-radius: 6px;
            font-size: .75rem;
            font-weight: 650;
            line-height: 3rem;
            border: 0;
            background: #F2F8FC;
            color: #085ED4;
            gap: .3rem;
          }

          & .get-views-cost-btn,
          .show-cost-container {
            display: flex;
            width: max-content;
            height: max-content;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: .5rem 1rem;
            border-radius: 6px;
            font-size: .65rem;
            font-weight: 650;
            text-transform: capitalize;
            color: #fff;
            background: #1180ff;
            border: 1px solid #1180ff;
            cursor: pointer;
            margin-left: 1rem;

            &:hover {
              opacity: .8;
            }

          }

          & .get-views-cost-btn {
            position: relative;
            & .error-message-cost-calculation {
              position: absolute;
              bottom: -1.5rem;
              left: 0;
              font-size: .55rem;
              font-weight: 600;
              color: #cc3b3b;
              text-transform: capitalize;
              display: none;
            }

            &:hover {
              
              & .error-message-cost-calculation {
                display: block;
              }
            }

          }

          &__inputs {

            display: flex;
            width: 94%;
            height: max-content;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
            gap: 1rem;

            & label {
              font-size: .65rem;
              font-weight: 600;
              color: #28303E;
              text-transform: capitalize;
            }

            &__get-ads-views {
              display: flex;
              width: 45%;
              height: 4rem;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;
              gap: .5rem;

              &__view-container {
                display: flex;
                width: 100%;
                height: 100%;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .2rem;
                border: 1px solid #dadbe1;
                padding: .5rem 1rem;
                border-radius: 6px;
                font-size: .7rem;

                &__icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 1.1rem;
                  padding: .4rem;
                  background: #efefef;
                  border-radius: 6px;
                  color: #515151;
                }

                &__input {
                  display: flex;
                  width: 80%;
                  height: 50%;
                  border: 0;
                  padding: 0 .5rem;
                  font-size: .8rem;
                  font-weight: 500;
                  outline: 0;

                  &::placeholder {
                    color: #515151;
                    font-weight: 500;
                    font-size: .6rem;
                  }

                }
              }
            }

            &__get-ads-time {
              display: flex;
              width: 45%;
              height: 4rem;
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: flex-start;

              // &:focus {
              //       outline: 2px solid #28303E;
              //     }

              gap: .5rem;

              &__show-time-container {
                display: flex;
                width: 100%;
                height: 100%;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .2rem;
                padding: .5rem 1rem;
                border-radius: 6px;
                font-size: .7rem;
                border: 1px solid #dadbe1;

                &__icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 1.1rem;
                  padding: .4rem;
                  background: #efefef;
                  border-radius: 6px;
                  color: #515151;

                }

                &__input {
                  display: flex;
                  width: 80%;
                  height: 50%;
                  border: 0;
                  outline: 0;
                  padding: 0 .5rem;
                  font-size: .8rem;
                  font-weight: 500;


                  &::placeholder {
                    color: #515151;
                    font-weight: 500;
                    font-size: .6rem;
                  }
                }
              }
            }
          }

          &__btn {
            display: flex;
            width: 98.5%;
            height: max-content;
            margin-top: auto;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-end;
            gap: 1rem;

            & button {
              display: flex;
              width: max-content;
              height: max-content;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              gap: .5rem;
              border: 1px solid #28303E;
              background: black;
              border-radius: 12px;
              padding: .4rem 1rem;
              font-size: .65rem;
              font-weight: 650;
              text-transform: capitalize;
              color: #fff;
              cursor: pointer;

              &:hover {
                opacity: .8;
              }
            }

            &__prev {
              background: #f2f2f2 !important;
              border: 1px solid #f2f2f2 !important;
              color: #000000 !important;
            }
          }



        }
      }


    }


  }


}</style>