import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import registerUserServices from '@/services/registerUserServices';
import logServices from '@/services/logServices';
import GEOAPI_SERVICES from '@/services/geoServices';
import loginUser from '@/services/loginUser';
import commercialUserServices from '@/services/commercialUserServices';
import adminUserServices from '@/services/adminUserServices';
import adServices from '@/services/adServices';
import tools from '@/tools';
import adminUserModule  from './modules/admin.js';
import commercialUserModule  from './modules/commercial.js';
import individualUserModule  from './modules/individual.js'; 
import getLogDetails from '@/utils/getLogDetails';



const currentSession = process.env.VUE_APP_SESSION_REF;
const storageKey = currentSession;

const vuexLocal = new VuexPersistence({
  key: storageKey,
  storage:{
    getItem: () => {
      // Get the store from local storage.
      const store = window.sessionStorage.getItem(storageKey);

      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, storageKey);

          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          // The store will be reset if decryption fails.
          window.sessionStorage.removeItem(storageKey);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, storageKey).toString();

      // Save the encrypted store in local storage.
      return window.sessionStorage.setItem(storageKey, store);
    },
    removeItem: () => window.sessionStorage.removeItem(storageKey),
  },
  reducer(val){
    
    if(val.token == null){ 
      return {}
    }
   
return val
    
  
  }
});

export default createStore({
  plugins: [vuexLocal.plugin],
  modules: {
    adminUserModule,
    commercialUserModule,
    individualUserModule
  },
  state: {
    userLoggedInfo: null,
    selectedLanguage: 'tr',
    userRole: 'individual',
    currentPaymentSessionId: null,
    screenType: null,
    token: null,
    isLogin: false,
    isLoading: false,
    selectedCommercialSection: 'PublishAds',
    selectedAdminSection: 'AdsManagement',
    selectedIndividualSection: 'AdsShowGround',
    browserInfo: null,
    userSubDetail: {
      balance: 0,
      clickEarnRight: 0,
      totalClickAdsCount: 0,
      totalWithdraw: 0,

    }
  },
  getters: {
    getUserInfo: (state) => state.userLoggedInfo,
    getRegisterUserInfo: (state) => state.registerUserInfo,
    getToken: (state) => state.token,
    getIsLogin: (state) => state.isLogin,
    getIsLoading: (state) => state.isLoading,
    getUserSubDetail: (state) => state.userSubDetail,
    getSelectedLanguage:(state) => state.selectedLanguage,
    getUserRole: (state) => state.userRole,
    getSelectedCommercialSection: (state) => state.selectedCommercialSection,
    getSelectedAdminSection: (state) => state.selectedAdminSection,
    getSelectedIndividualSection: (state) => state.selectedIndividualSection,
    getBrowserInfo: (state) => state.browserInfo,
    getPaymentSessionId: (state) => state.currentPaymentSessionId,
    getScreenType: (state) => state.screenType
  },
  mutations: {

    setPaymentSessionId(state, sessionId) {
      state.currentPaymentSessionId = sessionId;
    },
    
    setSelectedCommercialSection(state, section) {
      state.selectedCommercialSection = section;
    },
    setBrowserInfo(state, browserInfo) {
      state.browserInfo = browserInfo;
    },
    setUserRole(state, role) {
      state.userRole = role;
    },
    setUserInfo(state, userInfo) {
      state.userLoggedInfo = userInfo;
    },
    setToken(state, token) {
      state.token = token;
      tools.cookie.set(tools.global_key.tokenName,token);

    },
    setIsLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setUserSubDetail(state, userSubDetail) {
      state.userSubDetail = userSubDetail;
    },
    setSelectedLanguage(state, language) {
      state.selectedLanguage = language;
    },
    setScreenType(state, screenType) {
      state.screenType = screenType;
    },
    setLogout(state) {
      state.userLoggedInfo = null;
      state.token = null;
      state.isLogin = false;
      state.isLoading = false;
      state.userSubDetail = {
        balance: 0,
        clickEarnRight: 0,
        totalClickAdsCount: 0,
        totalWithdraw: 0,
      }
    }
  },
  actions: {

    async softDeleteAd({ state },adInfo) {
      try {
        const result = await adServices.softDeleteAd(adInfo);
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async verifyPayment({ state },sessionId) {
      try {
        console.log('work1',sessionId)
        const result = await commercialUserServices.verifyPayment(sessionId);
        return result;

      } catch (error) {
        console.log(error);
        return error;
      }

    },

    async setUserLogs({ state,getters }, logCode) {
      try {
        const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
        const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
        const adminRoleType = process.env.VUE_APP_ADMIN_ROLE_TYPE;
        const userRole = state.userRole;
        const browserInfo = state.browserInfo;
        const logDetail = getLogDetails(logCode); 
        let userId = '';
        let location = {};
        let ipAddress = '';

        if(userRole == indRoleType){
          const user = getters['individualUserModule/getUserIndividualInfo']
          ipAddress = user.ipAddress
          userId = user.userId;
          location = user?.location || {}
        }
        if(userRole == comRoleType){
          const user = getters['commercialUserModule/getUserCommercialInfo']
          ipAddress = user.ipAddress
          userId = user.userId
          location = user?.location || {}
        }
        if(userRole == adminRoleType){
          const user = getters['adminUserModule/getUserAdminInfo']
          ipAddress = user.ipAddress
          userId = user.userId
          location = user?.location || {}
        }
        
        const userInfo = {
          userId,
          logCode,
          ipAddress,
          browserInfo,
          logDetail,
          location
        }
        const result = await logServices.setUserLogs(userInfo);
        if(result?.status_code == 200){
          return true;
        }
        return false;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async getAdImageLink({ state }, adImageName) {
      try {
        const result = await adServices.getAdImageLink(adImageName);
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async getAdVideoLink({ state }, adVideoName) {
      try {
        const result = await adServices.getAdVideoLink(adVideoName);
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }
    },




    async getCitiesByCountryCodeAndLanguage({ commit }, { countriesCode, language }) {
      try {
        const result = await GEOAPI_SERVICES.getCitiesByCountryCodeAndLanguage(countriesCode, language);
        console.log(result)
        return result?.data || [];
        // commit('setCities', result);
        return result;
      } catch (error) {
        console.log(error);
        return error;
      }

    },



    async login ({ commit, state, dispatch }, userInfos) {
      try {
        const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
        const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
        const adminRoleType = process.env.VUE_APP_ADMIN_ROLE_TYPE;
        const response = await loginUser.loginUser(userInfos);
        const userInfo = response?.data?.userInfo || null;
        const token = response?.token || null;
        const logCode = '10002'
        if(userInfo && token && response?.status_code == 200){
          if(userInfo.role == indRoleType){
            commit('individualUserModule/setIndividualUserInfo', userInfo,{ root: true });
           commit('setUserRole', indRoleType);
            
          }
          if(userInfo.role == comRoleType){
            commit('commercialUserModule/setCommercialUserInfo', userInfo,{ root: true });
            commit('setUserRole', comRoleType);
          }
          if(userInfo.role == adminRoleType){
            commit('adminUserModule/setAdminUserInfo', userInfo,{ root: true });
            commit('setUserRole', adminRoleType);
          }
          commit('setToken', token);
          commit('setIsLogin', true);

          dispatch('setUserLogs',logCode);


          return {
            role: userInfo.role,
          };
        }
        return false;
      } catch (error) {
        console.error(error);
        return error;
        
      }
    },

    async logout ({ commit, state }) {
      commit('setLogout');
              // Logout işlemlerini yapın
        
              if ('BroadcastChannel' in window) {
                if(!state.loggedOut){
          
                    // Yeni bir BroadcastChannel oluştur
                    const logoutChannel = new BroadcastChannel('logout');
            
                    // Bir mesaj gönder
                    logoutChannel.postMessage('User has logged out');
                }
                  }

                tools.cookie.delete(tools.cookie, tools.global_key.tokenName);
                const sessionId = process.env.VUE_APP_SESSION_REF;
          
                function clearSessionStorageExcept(keyToPreserve) {
                  // Tüm session storage key'lerini al
                  const keys = Object.keys(sessionStorage);
                
                  // Her key için
                  keys.forEach(key => {
                    // İstisna key dışındakileri sil
                    if (key !== keyToPreserve) {
                      sessionStorage.removeItem(key);
                    }
                  });
                }
                
                // Kullanımı: 'userData' hariç tüm sessionStorage key'lerini sil
                clearSessionStorageExcept(sessionId);
                
                // clearInterval(userSessionTimer);
          
                window.location.reload();


    }
  },

})
