<template>
    <Carousel :itemsToShow="showAdCount" :wrapAround="true" :transition="500">
        <template #slides>
      <Slide v-for="(ad,index) in getDymcAds" :key="ad._id">
        <div class="carousel__item">

            <AdCard :index="index" :adDetail="ad" @setAdDetailModel="$emit('setAdDetailModel',ad)"/>

        </div>
    
      </Slide>
    </template>
  
    <template #addons="{ slidesCount }">
    <Navigation v-if="slidesCount > 1" />
  </template>

    </Carousel>
  </template>
  
  <script>
  import { Carousel,  Slide, Navigation } from 'vue3-carousel'
  
  import 'vue3-carousel/dist/carousel.css'
  import AdCard from "@/components/indivudal-user-section/AdCardContainer.vue"
  export default {
    props: {
        getDymcAds:{
            type:Array,
            default:[]
        },

        showAdCount:{
            type:Number,
            default:3
        }
    
       
    },

    computed:{

    },

     

    components: {
      Carousel,
      Slide,
      Navigation,
      AdCard
    },


  }
  </script>
  
  <style scoped lang="scss">
  
  .carousel__slide {
    padding: 1rem;
    height: 26rem;
    margin-top: 2rem;

    @media screen and (max-width: 650px) {
        height: 28rem;
    }
    @media screen and (max-width: 500px) {
        height: 30rem;
    }
  }
  
  .carousel__viewport {
    perspective: 2000px;
  }
  
  .carousel__track {
    transform-style: preserve-3d;
  }
  
  .carousel__slide--sliding {
    transition: 0.5s;
  }
  
  .carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
  }
  
  .carousel__slide--active ~ .carousel__slide {
    transform: rotateY(20deg) scale(0.9);
  }
  
  .carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
  }
  
  .carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
  }
  
  .carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
  }

  .carousel__item {
    height: 100%;
    width: 100%;
    display: flex;


   
  }
  </style>
  