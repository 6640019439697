<template>
  <div class="app-footer">
    <div class="app-footer__up">
        <div class="app-footer__up__founder_infos">
            <img class="app-footer__up__founder_infos__logo" src="/img/app_logos/white_app_logo.png" alt="app logo white">
            <div class="app-footer__up__founder_infos__separator">
                <p class="app-footer__up__founder_infos__separator__text founder">{{ $t('founder') }} <i class="bx bxs-hand-right"></i> <strong>Doğukan Karaduman</strong></p>
                <p class="app-footer__up__founder_infos__separator__text">{{ $t('coFounder') }} <i class="bx bxs-hand-right"></i> <strong>Doğa Karaduman</strong></p>
            </div>

        </div>
    </div>

    <div class="app-footer__down">
        <p class="app-footer__down__copyright">© 2024, {{ $t('allRightsReserved') }}</p>
        <div class="app-footer__down__social-contacts">
            <i class="bx bxl-linkedin"></i>
            <i class="bx bxl-facebook"></i>
            <i class="bx bxl-twitter"></i>
            <i class="bx bxl-instagram"></i>
            <i class="bx bxl-discord"></i>
        </div>
        <!-- <div class="app-footer__down__others">

        </div> -->
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
    .app-footer{
        display: flex;
        width: 100%;
        min-height: 15rem;
        height: max-content;
        flex-flow: column wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .5rem;
        background: #000000;
        color: #ffffff;
        margin-top: 1rem;
        overflow: hidden;

        &__up {
            display: flex;
            width: 100%;
            height: auto;
            padding: 1em;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;

            &__founder_infos {
                display: flex;
                width: 100%;
                height: 100%;
                flex-flow: column nowrap;
                align-items:center;
                justify-content: flex-start;
                gap: .1rem;

                &__logo {
                    width: 5rem;
                    height: 5rem;
                    object-fit: scale-down;
                }

                &__separator {
                    display: flex;
                    width: 100%;
                    height: max-content;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0;

                    & .founder {
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: left;
                        margin-top: 1rem;
                        color: #fffffff7;

                    }

                    &__text {
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    width: max-content;
                    margin: 0;
                    margin-top: .5rem;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .5rem;
                    color: #ffffffd7;

                }

              
                }
  
            }
        }

        &__down {
            display: flex;
            width: 100%;
            height: max-content;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-around;
            color: #ffffffe1;
            font-size: .8rem;
            padding: 0 1em;

            &__copyright {
                display: flex;
                width: max-content;
                height: max-content;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .5rem;
            }
            &__social-contacts {
                display: flex;
                width: max-content;
                height: max-content;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .8rem;

                & i {
                    font-size: 1.2rem;
                }
            }
        }
    }
</style>