import baseServices from './index';
const secret = process.env.VUE_APP_SECRET_KEY;
export default {
    registerIndividualUser: async (registerUserInfo) => {
        try {
            registerUserInfo['JWT_SEC'] = secret;
            const result = await baseServices().post('register-user/register-individual-user', registerUserInfo);
            return result;     
        } catch (error) {
            return error
        }

    },
    registerCommercialUser: async (registerUserInfo) => {
        try {
            registerUserInfo['JWT_SEC'] = secret;
            const result = await baseServices().post('register-user/register-commercial-user', registerUserInfo);
            return result;
        } catch (error) {
            return error
        }

    }
}