<template>
    <div class="index-steps-explanation">
        <StepsExplanation />
        <AppAimDetail />
        <AppDownloadSection />
        <FrequentlyAskingQuestionsection />
        <AppFooter />
    </div>
</template>

<script>
import AppAimDetail from './AppAimDetail.vue';
import AppDownloadSection from './AppDownloadSection.vue';
import AppFooter from './AppFooter.vue';
import FrequentlyAskingQuestionsection from './FrequentlyAskingQuestionsection.vue';
import StepsExplanation from './StepsExplanation.vue';
export default {
components: {
    StepsExplanation,
    AppAimDetail,
    AppDownloadSection,
    FrequentlyAskingQuestionsection,
    AppFooter
}
}
</script>

<style scoped lang="scss">
    .index-steps-explanation{
        display: flex;
        width: 100%;
        height: max-content;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
    }
</style>