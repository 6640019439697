<template>
  <div class="commercial-user-section-container">
    <UserCommercialNavbar />
    <div class="commercial-user-section">
       <component :is="getSelectedCommercialSection" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserCommercialNavbar from './NavbarCommercialUser.vue' // Navbar
import PublishAds from '@/components/commercial-user-section/PubishAdsContainer.vue'
import MyAdsList from './MyAdsList.vue';
import LoadBalance from '@/components/payments/CommercialIntentPayment.vue';
export default {
    components: {
        UserCommercialNavbar,
        PublishAds,
        MyAdsList,
        LoadBalance
    },
    computed: {
        ...mapGetters({getUserCommercialInfo:'commercialUserModule/getUserCommercialInfo'}),
        ...mapGetters(['getSelectedCommercialSection','getSelectedLanguage'])

    },
    data() {
        return {
            selectedSection: 'PublishAdd'
        }
    },
    methods:{
        setCategories(){
            this.$store.dispatch('commercialUserModule/getFilterCategories',{
                language:this.getSelectedLanguage

            })
        }
    },
    created(){
        this.setCategories()
        console.log(this.getUserCommercialInfo)
    }
}
</script>

<style scoped lang="scss">
.commercial-user-section-container{

    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;


    & .commercial-user-section {
        width: 82%;
        height: 100%;
        margin: 1.5rem auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        @media screen and (min-width: 1620px) {
            width: 70rem;
        }

        @media screen and (max-width: 768px) {
            width: 90%;
        }
        @media screen and (max-width: 600px) {
            width: 92%;

        }
    }
}
</style>