<template>
  <div class="admin-user-section-container">
    <UserAdminNavbar />
    <div class="admin-user-section">
       <component :is="getSelectedAdminSection" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAdminNavbar from './NavbarAdminUser.vue' // Navbar
import AdsManagement from './AdsManagement.vue';
export default {
    components: {
        UserAdminNavbar,
        AdsManagement

    },
    computed: {
        ...mapGetters({getUserAdminInfo:'adminUserModule/getUserAdminInfo'}),
        ...mapGetters(['getSelectedAdminSection'])

    },
    data() {
        return {
            selectedSection: ''
        }
    },
    created(){
        console.log(this.getUserAdminInfo,'tt',this.getSelectedAdminSection)
    }
}
</script>

<style scoped lang="scss">
.admin-user-section-container{

    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;


    & .admin-user-section {
        width: 84%;
        height: 100%;
        margin: 1.5rem auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
</style>