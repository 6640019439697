<template>
  <div class="user-admin-view">
    <AdminUserSection />
  </div>
</template>

<script>
// @ is an alias to /src
import AdminUserSection from '@/components/admin-user-section/IndexAdminUserSection.vue'

export default {
  name: 'adminView',
  components: {
    AdminUserSection
  }
}
</script>


<style lang="scss">
.user-admin-view{ 
  height:auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
