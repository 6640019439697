import baseServices from './index';
const secret = process.env.VUE_APP_SECRET_KEY;

export default {
    loginUser: async (loginUserInfo) => {
        try {
            loginUserInfo['JWT_SEC'] = secret;
            const result = await baseServices().post('auth/login-user', loginUserInfo);
            return result;
        } catch (error) {
            return error
        }
    }
}