<template>
    <div class="app-download-section">
        <h3>{{ $t('downloadApp') }}</h3>
        <h4>{{ $t('downloadAppDetail') }}</h4>

        <button>{{ $t('downloadAppButton') }}</button>
    </div>
</template>

<script>
export default {

}
</script>

<style  scoped lang="scss">
    .app-download-section{
        display: flex;
        width: 100%;
        margin: 0 auto;
        height: 20rem;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        background:#406EF0;
        color: #ffffff;

        & h3 {
            font-size: 2.5rem;
            font-weight: 1000;
            margin: 0;
            margin-bottom: .5rem;
        }
        & h4 {
            font-size: .9rem;
            width: 80%;
            font-weight: 550;
            margin: 0;
            color: #ffffffcc;
            margin-bottom: 2rem;
        }
        & button {  
            width: max-content;
            height: max-content;
            border: none;
            outline: none;
            border-radius: 12px;
            background-color: #57F087;
            color: #000000;
            font-size: .9rem;
            font-weight: 550;
            padding: 1rem 1rem;
            gap: .5rem;
            border-radius: 1px solid #000000;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }
</style>