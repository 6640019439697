<template>
    <div class="text-keywords-box" :class="{setFlatBorderBottoms: isListOpen}"  :ref="refComp"  :name="refComp" @click.stop  @click.self="setFocusKeywordInput">
       <div class="keyword-tags-container" >
        <span class="keyword-tag" v-for="(keyword, index) in selectedItems" :key="keyword + index">
            {{ getKeywordName(keyword) }}
             <span class="delete-keyword" @click.stop @click="deleteKeyword(index)"><i
                    class="fa-solid fa-xmark"></i></span>
        </span>
       </div>

        <input type="text" @focus="isFocused = true"   ref="keywords" :placeholder=" keywords.length ? '' : placeholder"
            v-model="keywordsInput" class="keyword-text-input" >
        <div class="text-keywords-box__list" :key="getListItems.length"
            v-if="getListItems.length && ((keywordsInput || onFocusListShow ) && getIsFocused)">
            <div class="text-keywords-box__list__item" @click.stop v-for="(item, index) in getListItems" @click="addToKeywords(item.id)"  :key="item.name + index+ item.id">
                <i :class="listIcon"></i> <span>{{ item.name }}</span>
            </div>
        </div>

    </div>
</template>

<script>
import { debounce} from 'lodash'
export default {
    props: {
        listToShow: {
            type: Array,
            default: () => []
        },
        selectedItems: {
            type: Array,
            default: () => []
        },
        onFocusListShow: {
            type: Boolean,
            default: false
        },
        refComp: {
            type: String,
            default: 'text-keywords'
        },
        lang: {
            type: String,
            default: 'en'
        },
        placeholder: {
            type: String,
            default: 'Search'
        },
        listIcon: {
            type: String,
            default: 'fa-solid fa-location-dot'
        },
        keywordInputToListActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            keywords: [],
            keywordsInput: '',
            isFocused: false
        }
    },

    mounted() {
     document.addEventListener('click', this.handleClickOutside);



  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

    computed: {
        getListItems() {
            if (this.keywordsInput) {
                return this.listToShow.filter(item => {
                    return !this.keywords.includes(item.id) && this.itemInList(item.name, this.keywordsInput)
                });

            }
            else {
                return this.listToShow.filter(item => !this.keywords.includes(item.id));
            }
        },
        isListOpen() {
            return this.getListItems.length && (this.keywordsInput || this.onFocusListShow ) && this.getIsFocused
        },
        getIsFocused() {
            return this.isFocused
        }
    },
    watch: {
        keywords: {
            handler(newValue) {
                this.$emit('update:modelValue', newValue);
                this.keywordsInput = '';
                this.$refs.keywords.focus();
            },
            deep: true
        },

        keywordsInput:'addToKeywordsTextToSelection',

        selectedItems: {
            handler(newValue) {
                this.keywords = newValue    
            },
            deep: true
        }

    },
    methods: {
        addToKeywordsTextToSelection: debounce(function() {
            if(this.keywordInputToListActive && this.keywordsInput){
                this.keywords.push(this.keywordsInput);
            }
  
                },2000),

        getKeywordName(keyword) {
         
            return this.listToShow.find(item => item?.id === keyword)?.name || keyword
            
        },
        setUnFocus() {
                this.isFocused = false;


        },
        handleClickOutside(event) {

            if(this.$refs[this.refComp]){
                console.log(this.$refs[this.refComp]),
                console.log(event,'ev')
                if (!this.$refs[this.refComp].contains(event.target)) {
                this.setUnFocus()
            }
            }
      

        },

        itemInList(item, searchText) {
            const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const regex = new RegExp(normalizedSearchText, 'iu'); // Unicode ve büyük/küçük harf duyarlılığını kaldırır
            return regex.test(item.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        },

        addToKeywords(item) {
            this.keywords.push(item);
        },
        setFocusKeywordInput() {
            this.isFocused = true

            this.$refs.keywords.focus();
        },
        deleteKeyword(index) {
            this.keywords.splice(index, 1);
        },

    }
}
</script>

<style scoped lang="scss">


.setFlatBorderBottoms {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.text-keywords-box {
    width: 100%;
    position: relative;
    margin: .5rem 0;
    margin-top: 0.2rem;
    padding: 1rem .2rem;
    border-radius: 8px;
    text-align: justify;
    outline: 0;
    border: 1px solid #c5c5c5;
    min-height: 3.8rem;
    height: max-content;
    font-size: .7rem;
    display: flex;
    flex-flow: row wrap;
    gap: .2rem;
    justify-content: flex-start;
    align-items: flex-start;

    .keyword-tags-container {
       max-height: 5rem;
       height: max-content;
       width: 100%;
       overflow: auto;
       display: flex;
       flex-flow: row wrap;
       gap: .2rem;
    }

    .keyword-text-input {
        position: relative;
        border: 0;
        outline: 0;
        z-index: 0;
        padding: 1%;
        width: 100%;
        border-radius: 8px;

        &::placeholder {
            color: #6c6c6ce4;
            font-size: .65rem;
            font-weight: 550;
            text-align: center;
        }
    }

    .text-keywords-box__list {
        position: absolute;
        bottom: -10rem;
        left: 0;
        width: 100%;
        background: white;
        border-radius: 0;
        border: 1px solid #c5c5c5;
        z-index: 1;
        height: 10rem;
        overflow: auto;
        box-sizing: border-box;

        &__item {
            padding: .3rem .5rem;
            font-size: .7rem;
            font-weight: 500;
            cursor: pointer;

            & i {
                margin-right: .5rem;
                color: #7e7e7e;
            }

            &:hover {
                background: #f4f4f4;
            }
        }
    }

    .keyword-tag {
        font-size: .65rem;
        margin: .2rem;
        padding: .2rem .4rem;
        font-weight: 500;
        width: max-content;
        border-radius: 4px;
        border: 1px solid #EDEEF1;
        background: #f4f4f4;
        color: #28303E;
        display: flex;
        justify-content: center;
        align-items: center;

        & .delete-keyword {

            margin: 0 .4rem;
            color: #7e7e7e;
            font-weight: 600;
            font-size: .8rem;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }

        }
    }

}</style>