const en = {

    userLoginButton: "Login",
    userLogoutButton: "Logout",
    userLoginTitle: "Login to Earn",
    userRegisterTitle: "Register to Earn",
    userRegisterButton: "Register",
    userRegisterRole: "Role Type",
    userRegisterName: "Name",
    userRegisterSurname: "Surname",
    userRegisterEmail: "Email",
    userRegisterPassword: "Password",
    userRegisterIBAN: "IBAN",
    userRegisterCountry: "Country",
    userRegisterBirthdate: "Birthdate",
    userRegisterBirthdateError: "You need to be at least 18!",
    userRegisterRoleError: "You need to choose a role type!",
    userRegisterIBANWarning: "Your name and surname has to be exact match with IBAN owner! Otherwise you will not be able to receive your payment!",
    userRegisterIBANError: "IBAN is not valid!",
    userRegisterError: "Something went wrong!",
    userRegisterEmailError: "Invalid email!",
    userRegisterPasswordError: "Invalid password!",
    userRegisterConfirmPassword: "Confirm Password",
    userRegisterConfirmPasswordError: "Passwords don't match!",
    userLoginEmail: "Email",
    userLoginPassword: "Password",
    userLoginPasswordError: "Invalid password!",
    userLoginEmailError: "Invalid email!",
    userLoginUserId: "User Id",
    userLoginUserIdError: "Invalid user id!",
    userLoginPlaceholder: "User Id or Email",
    userLoginResetPassword: "Forgot Password?",
    userLoginError: "Something went wrong!",
    optional: "Optional",
    tr: "Turkish",
    en: "English",
    stepsExplanation:"Start Making Money By Clicking Ads",
    step:"Step",
    "stepOne": "Register on FELIXLEAP according to your needs.",
    "stepTwo": "Click on ads to increase your 'Lucky Me' chances",
    "stepThree": "Press the 'Lucky Me' button and sit back to earn money",
    "stepOneDetail": "Complete your registration by entering the required information",
    "stepTwoDetail": "Each ad click earns you one 'Lucky Me' chance.",
    "stepThreeDetail": "After pressing the 'Lucky Me' button, if you match three numbers, your earnings are instantly credited to your account.",
    whyUseApp: "How Does FELIXLEAP Make Your Time More Valuable and Enjoyable?",
whyUseAppDetail: `The appeal of this platform lies in its simplicity. We offer a platform that can be easily integrated into your daily routine, enabling you to earn money by clicking on ads without needing special skills or a lot of time. While clicking on ads, you are not just a user; you are also an active member of a community that recognizes the value of your time and rewards you enjoyably.
<br>
<br>
By clicking on ads, you earn 'Lucky Me' chances, and with these chances, you can play the Lucky Me game to earn income. This provides an opportunity not only to earn money but also to enjoy the process. Additionally, we offer you personalized ads, promotional codes, and more. Furthermore, our members can publish their own ads on our platform, expanding their engagement audiences. With these features, we provide a valuable resource for both individual users and businesses.`,

downloadApp: "Try Our App for Enjoyable Earnings",
downloadAppDetail: "You can quickly download FELIXLEAP on all your devices such as Android, iOS, etc. After completing your registration on the FELIXLEAP platform, you can start earning by clicking on ads.",
downloadAppButton: "Download FELIXLEAP",
frequentlyAskedQuestions: "Frequently Asked Questions",

fr_ask_1: "How can you earn with FELIXLEAP?",
fr_ans_1: "After completing your registration on the FELIXLEAP platform, you can earn enjoyable returns by clicking on ads to gain 'Lucky Me' chances. Each ad click earns you a 'Lucky Me' chance. For business accounts, you can reach a wider audience cost-effectively by publishing your customized ads and promotions on our app.",

fr_ask_2: "Does FELIXLEAP charge us any fees?",
fr_ans_2: "No. FELIXLEAP's goal is to share the earnings from ad viewership with its users in a fun way. It does not intend to charge any fees aside from this. For business accounts, fees are calculated based on the type of ad, region, targeting, etc.",

fr_ask_3: "Is the information I share with FELIXLEAP securely protected?",
fr_ans_3: "Yes. FELIXLEAP provides advanced security measures against cyber-attacks and other threats with its unique security algorithms. Your information is stored in an encrypted format.",

fr_ask_4: "What is 'Lucky Me' in FELIXLEAP?",
fr_ans_4: "For each ad click, you earn one 'Lucky Me' chance. When you press the 'Lucky Me' button in the app, a card with six numbers opens. If three of these numbers match, the matching number value is instantly deposited into your account. For example, if you encounter a result like 10, 20, 1000, 50, 1000, 1000, the value of 1000 which appears three times is credited to your account according to the currency of your region.",

fr_ask_5: "How can I withdraw the money I earn?",
fr_ans_5: "You can transfer the desired amount of money to the IBAN number you provided at registration once your balance reaches the minimum withdrawal limit. For example: The minimum withdrawal amount is 5 EURO for the Eurozone.",

fr_ask_6: "How long will it take for my earnings to be transferred to my IBAN account?",
fr_ans_6: "The amount you wish to withdraw will be in your account within 72 hours. Important information: The NAME, SURNAME, and CURRENCY TYPE associated with the IBAN number must match the information provided at the time of registration. Otherwise, the transfer will not be executed.",

fr_ask_7: "How can you contact FELIXLEAP if you experience a problem?",
fr_ans_7: "You can get help with your issue through the Support and Customer Service chat box in our app. If the problem persists, you can send a direct message regarding your issue to our company's official email address or social media accounts.",

founder: "Founder",
coFounder: "Co-Founder",
allRightsReserved: "FELIXLEAP. Terms & Privacy policy.",

userRegisterRole: "Individual",
userRegisterCommercialRole: "Commercial",
userRegisterFormTitle: "Registiration",
userRegisterFormEmail: "E-mail",
userRegisterFormPassword: "Password",
userRegisterFormConfirmPassword: "Confirm Password",
userRegisterFormCountry: "Country",
userRegisterFormCity: "City",
userRegisterFormPhone: "Phone",
userRegisterFormIBAN: "IBAN",
userRegisterFormCommercialName: "Company Name",
userRegisterFormName: "Name",
userRegisterFormSurname: "Surname",
userRegisterFormCurrency: "IBAN Currency Type",
userRegisterFormSubmit: "Register",
userRegisterFormBirthdate: "Birthdate",
userRegisterFormPhoneErrorMessage:"Please enter a valid phone number.",
userRegisterFormIBANErrorMessage:"IBAN is not valid.",
userRegisterFormEmailErrorMessage:"Invalid email.",
userRegisterFormPasswordErrorMessage:"Invalid password.",
userRegisterFormConfirmPasswordErrorMessage:"Passwords don't match.",
userResgisterFormBirthdateErrorMessage:"You need to enter your birthdate.",
userRegisterFormNameErrorMessage:"You need to enter your name.",
userRegisterFormSurnameErrorMessage:"You need to enter your surname.",
userRegisterFormCountryErrorMessage:"You need to enter your country.",
userRegisterFormCurrencyErrorMessage:"You need to pick your IBAN currency type.",
userPublishAds: "Publish Ads",
userMyAds: "My Ads",
userAnalytics: "Analytics",
userLoginInfoWarning: "Please enter your user id or email.",
userLoginPasswordWarning: "Please enter your password.",
userIndividualMemberNo: "Member No",
userBalance: "Balance",
userLoadBalance: "Deposit Money",
userIndividualWithdraw: "Withdraw Money",
userProfile: "Profile",
userSettings: "Settings",
userNotifications: "Notifications",
userProfileEdit: "Edit Profile",
userProfileEditSubmit: "Save Changes",
userProfileEditCancel: "Cancel",
userProfileEditDelete: "Delete Account",
publishAdsDetail:"Publish an ad on FELIXLEAP and reach more people all over the world.",
publishAds:"Publish Ads",
stepContent:"Content",
stepLocation:"Location",
stepBudget:"Budget",
language:"Language",
and:'and',
create:'Create',
set:'Set',
select:'Select',
Upload:'Upload',
uploadMediaImages:'Upload Media Images',
countries:"Countries",
languages:"Languages",
categories:"Categories",
countriesPlaceHolder:"Select Country",
languagesPlaceHolder:"Select Language",
categoriesPlaceHolder:"Select Category",
cityPlaceHolder:"Select City",
contentLink:"Content Link",
promotionCode:"Promotion Code",
titlePlaceHolder:"Type Title",
descriptionPlaceHolder:"Type Description",
linkPlaceHolder:"Type Link",
promotionCodePlaceHolder:"Type Promotion Code",
budgetPlaceHolder:"Type Budget",
budgetError:"Please enter budget.",
titleError:"Please enter title.",
descriptionError:"Please enter description.",
linkError:"Please enter link.",
promotionCodeError:"Please enter promotion code.",
contentError:"Please enter content.",
locationError:"Please enter location.",
uploadImageError:"Please upload image.",
countriesError:"Please select country.",
languagesError:"Please select language.",
categoriesError:"Please select category.",
budget:"Budget",
budgetError:"Please enter budget.",
setBudget:"Total Budget",
setBudgetError:"Please enter total budget.",
currency:"Currency",
currencyError:"Please select currency.",
currencyPlaceholder:"Please Select Currency",
adsBudgetAmountPlaceholder:"Please enter ads budget amount.",
totalCost:"Total Cost",
setViewCount:"Targeted View Count",
setViewPlaceholder:"Enter targeted view count",
minTargetedViews:"Minimum Targeted Views",
minTargetedViewsError:"Please enter minimum 100 targeted views.",
setShowTime:"Show Time Per Ad (second)",
setShowTimePlaceholder:"Enter show time second",
setShowTimeError:"Please enter show time per ad minimum 5 second.",
watching:"views",
watchingSec:"second during to watch cost",
ageLimitation:"Is Ad has 18 Years Limitation?",
no:'No',
yes:'Yes',
uploadImage:"Upload Image",
myAds:"My Published Ads",
title:"Title",
description:"Description",
image:"Image",
banner:"Banner",
categories:"Categories",
countries:"Countries",
cities:"Cities",
languages:"Languages",
link:"Link",
targetedViewsCount:"Targeted Views",
reachedViewsCount:"Reached Views",
status:"Status",
titleMyAdList:"My Published Ads",
approval:"Approval",
approved:"Approved",
rejected:"Rejected",
pending:"Pending",
active:"Active",
inactive:"Inactive",
deactivate:"Deactivate",
activate:"Activate",
more:"More",
edit:"Edit",
delete:"Delete",
view:"View",
deleteAd:"Delete Ad",
deleteAdDetail:"Are you sure you want to delete this ad?",
cancel:"Cancel",
yes:"Yes",
no:"No",
userAdminMemberNo:"Member No",
copied:"Copied",
adsManagement:"Ads Management",
generalManagement:"General Management",
change:"Change",
changeStatus:"Change Status",
changedSuccess: 'Changed Successfully',
commercialName:"Commercial Name",
adTitle:"Ad Title",
userId:"User Id",
adminUserToSeeDetails:"Ad Status",
adsManagement:"Ads Management",
over18:"Over 18",
publishDate:"Publish Date",
createdDate:"Created Date",
statusDetail:"Status Detail",
category:"Category",
noCategory:"No Category",
noName:"No Name",
noDescription:"No Description",
noLink:"No Link",
noPromotionCode:"No Promotion Code",
noContent:"No Content",
noLocation:"No Location",
noBudget:"No Budget",
noCurrency:"No Currency",
noAds:"No Ads",
keywords:"Keywords",
keywordsPlaceHolder:"Enter Keywords",
noImage:"No Image",
noDate:"No Date",
update:"Update",
messagesToAdStatusPlaceholder:"Enter messages to ad status",
adStatusMessageRequired:"Ad status message is required.",
adStatusUpdated:"Ad status updated.",
linkPreview:"Link Preview",
visitTo:"Visit To",
fav:"Fav",
share:"Share",
report:"Report",
complete:"Complete",
closeAd:"Close Ad",
reportDetail:"Are you sure you want to report this ad?",
reportSuccess:"Reported Successfully",
reportError:"Report Error",
reportSuccessDetail:"Reported Successfully",
IlikeIt:"I like it",
notSure:"Not sure",
reportAd:"Report Ad",
logout:"Logout",
userCommercialMemberNo:"Member No",
accordingTopublishDate:"According to publish date",
moreFilter:"More Filters",
luckyMeGame:"Lucky Me Game",
luckyMeGameIntro:"Get 3 matches and earn money.",
luckyMe:"Lucky Me",
publisher:"Publisher",
viewer:"Viewer",
userRegisterFormPublisherName:"Publisher Name",
userRegisterFormPublisherNamePlaceholder:"Enter Publisher Name",
userRegisterFormPublisherEmail:"Publisher Email",
userRegisterFormPublisherEmailPlaceholder:"Enter Publisher Email",
paymentSectionTitle:'Payment Section',
paymentIntentPlaceHolder:'Amount of Payment',
cardInformationTitle:'Card Informations',
cardNumberExamplePlaceholder:'4242 4242 4242 4242',
expireDateMonthExamplePlaceholder:'MM',
expireDateYearExamplePlaceholder:'YY',
cvcExamplePlaceholder:'CVC',
cardNumberError:'Please enter card number.',
expireDateError:'Please enter expire date.',
cvcError:'Please enter cvc.',
cardNumberPlaceholder:'Card Number',
cardHolderNamePlaceholder:'Card Holder Name',
cardHolderName:'Card Holder Name',
cardHolderNameError:'Please enter card holder name.',
userRegisterFormCountry:'Country',
billingAddress:'Billing Address',
billingAddressPlaceholder:'Please enter billing address',
billingAddressError:'Please enter billing address.',
countryError:'Please select country.',
userRegisterFormCountryPlaceholder:'Please select country',
isBusinessPurchase:'I am purchasing as a business',
vatNumberTitle:'VAT Information',
vatNumberPlaceholder:'VAT Number',
vatNumber:'VAT Number',
vatNumberError:'Please enter VAT number.',
businessNamePlaceholder:'Business Name',
businessName:'Business Name',
businessNameError:'Please enter business name.',
addBalance:'Add Balance',
makePayment:'Make Payment',
balance:'Balance',
balanceError:'Please enter balance.',
addBalanceError:'Please enter balance.',
addBalanceSuccess:'Added Balance Successfully',
supportedBy:'Supported By',
supportedByStribe:'Sported By <strong>Stribe</strong>.',
terms:"Terms",
privacy:"Privacy",
conditions:"Conditions",
balanceLoadDescription:"Deposit Money to your account.",
successPaymentTitle:"Payment Successful!",
successPaymentDescription:"Your payment has been successfully done.",
totalPayment:"Total Payment",
goToCreateAdSection:"Go To Ad Publishing",
subCategories:"Sub Categories",
categoriesSubPlaceHolder:"Select Sub Category",
stepBudgetAndTarget:"Budget & Target",
target:"Target",
male:   "Male",
female:"Female",
all:"All",
ageGap:"Age Gap",
minPerUserView:"Per User View",
setMinPerUserView:"Min View Count",
targetedGender:"Targeted Gender",
seeCost:"See Cost",
enterViewsCountError:"Views count should be at least 100.",
enterAdShowSecError:"Ad show duration should be at least 10 second.",
enterCountriesError:"Please select at least one country.",
enterCategoriesError:"Please select at least one category.",
luckyNumbers:"Lucky Numbers",
remainingLuckmeAttempts:"Remaining Lucky Me Attempts",
totalEarning:"Total Win",
userRemainingAdsDailyCount:"Remaining Interactions",
preview:"Preview",
draft:"Draft",
back:"Back",
userRegisterGender:"Gender",
male:"Male",
female:"Female",
other:'Other',
areYouSureDraft:"Are you sure you want to save as draft?",
invalidFormat:"Invalid Format!",
imageFormatError:"The thumbnail must be a JPG, PNG, JPEG, WEBP, SVG or GIF file.",
fileSizeToLarge : "File Too Large!",
fileSizeLimit : "The thumbnail file size must be less than 2MB.",
invalidDimensions:"Invalid Dimensions!",
invalidDimensionsError:"The thumbnail dimensions must be at least",
videoFormatError:'The video must be an MP4, WEBM, or OGG file.',
videoDurationError:'Video Too Long!',
videoDurationError2:'The video must not exceed',
seconds:'seconds.',
videoProcessingError:'An error occurred while processing the video file.',
fileSizeVideoLimit:"The video file size must be less than 10MB.",



















}


export default en;