<template>
  <div class="ad-show-to-individual-detaily">
    <div class="ad-show-to-individual-detaily__ad-header-controller">
        <div class="ad-show-to-individual-detaily__ad-header-controller__timer">
            <img src="/img/icons/timer.gif" v-if="getCurrenRestedTime > 0" alt="timer gif">
            <img src="/img/icons/timer-static.png" v-else alt="stoped timer icon">
            <span> {{ getCurrenRestedTime }} </span> 
        </div>
        <div class="ad-show-to-individual-detaily__ad-header-controller__buttons">
            <button :class="{'disabled': !getAdWatchedStatus,'selected-btn-like': selectedFeedBack === 'like'}" @click="setAdWatchedStatusToLike" class="ad-show-to-individual-detaily__ad-header-controller__buttons__btn-like">
                <i class="fa-regular fa-heart"></i>
                <span>{{ $t('IlikeIt') }}</span>
            </button>
            <button :class="{'disabled': !getAdWatchedStatus,'selected-btn-not-sure': selectedFeedBack === 'notSure'}" @click="setAdWatchedStatusToNotSure"  class="ad-show-to-individual-detaily__ad-header-controller__buttons__btn-not-sure">
                <i class="fa-regular fa-face-frown"></i>
                <span>{{ $t('notSure') }}</span>
            </button>


        </div>
        <button class="ad-show-to-individual-detaily__ad-header-controller__btn-report">
                <span>{{ $t('reportAd') }}</span>
            </button>


    </div>
    <div class="ad-show-to-individual-detaily__ad-info-card">
        <AdShowCard :adDetails="ad" :userRole="'individual'"  />
    </div>
   
    <div class="ad-show-to-individual-detaily__iframe-container" v-if="ad?.link || adDetail?.video?.bucketLocation">
            <div class="ad-show-to-individual-detaily__iframe-container__link-router" v-if="ad?.link">
                <h5 class="ad-show-to-individual-detaily__iframe-container__link-router__title">{{ $t('linkPreview') }}</h5>
                <span class="ad-show-to-individual-detaily__iframe-container__link-router__title-go">{{ $t('visitTo') }}</span> 
                <i class="fa-solid fa-long-arrow-right"></i>
                <a @click="setTotalLinkClickCount" :href="ad?.link" target="_blank">{{ ad?.link }}</a>


            </div>
            <div class="ad-show-to-individual-detaily__iframe-container__show">
                <div class="ad-show-to-individual-detaily__iframe-container__show__video" v-if="adDetail?.video?.bucketLocation">
                <video :src="adDetail?.video?.bucketLocation" controls 
                autoplay
                :poster="adDetail?.image?.bucketLocation"
                :style="{width: '20rem', height: '20rem', objectFit: 'cover'}"

            ></video>

            <hr>
            </div>
            <iframe class="ad-show-to-individual-detaily__iframe-container__show__iframe" :src="ad?.link" frameborder="0"></iframe>

            </div>
    </div>



    <button @click="setExitAd" :class="{'disabled': !isCloseButtonActive}" class="ad-show-to-individual-detaily__close-btn"><i class="bx bx-x"></i></button>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdShowCard  from "@/components/comman-components/preview-ad-container/AdDetailyShowContainer.vue"

export default {
 props: {
    ad: {
        type: Object,
        required: true,
        default: {}
    },

    isCommercialPreview:{
        type: Boolean,
        default:false,
    },

 },   
components: {
    AdShowCard
},
mounted() {
    this.setAdDurationSessionTimer()
    this.startTimer()

},
beforeUnmount() {
    clearInterval(this.timer)
    clearInterval(this.sessionDurationTimer)

},
methods: {

    setAdDurationSessionTimer() {

        this.sessionDurationTimer = setInterval(() => {
            this.totalAdShowSessionDurationSec ++;
       
        }, 1000)
    },
    startTimer() {


        this.timer = setInterval(() => {
            this.remainingAdDurationSec --;
            if(this.remainingAdDurationSec  === 0) {
                clearInterval(this.timer)
            }
        }, 1000)
    },
        setExitAd() {
            if(this.isCommercialPreview){
                this.$emit('closeModal')
                return
            }
            if(!this.isCloseButtonActive) return;
         const adWatchedMetaData = {
            totalAdDurationSec:this.totalAdDuration,
            totalAdShowSessionDurationSec:this.totalAdShowSessionDurationSec,
            selectedFeedBack: this.selectedFeedBack,
            totalLinkClickCount:this.totalLinkClickCount,
            isReported:this.isReported


         }
            this.$store.dispatch("individualUserModule/setAdWatced",{
                    adId:this.ad?._id || '',
                    userId:this.getUserIndividualInfo?.userId || {},
                    adWatchedMetaData

            })

            this.$store.commit('individualUserModule/updateDailyWatchedAds',this.ad);
            this.$store.commit('individualUserModule/transformAdToPendingAdFromInitialAd',this.ad);
            this.$store.commit('individualUserModule/updateInitialAdFromPendingAds')




            this.$emit('closeModal')
        },
        setAdWatchedStatusToLike() {
            if(this.selectedFeedBack === 'like'){
                this.selectedFeedBack = null
            }
            else {
                this.selectedFeedBack = 'like'
            }
        },
        setTotalLinkClickCount(){
            this.totalLinkClickCount++;
        },
        setAdWatchedStatusToNotSure() {
            if(this.selectedFeedBack === 'notSure'){
                this.selectedFeedBack = null
            }
            else {
                this.selectedFeedBack = 'notSure'
            }
        },
},

created(){
    console.log('ad',this.ad)
    this.totalAdDuration = this.ad?.adShowSec || 10;
    this.remainingAdDurationSec = this.ad?.adShowSec || 10;
},
data() {
    return {
    timer: null,
    sessionDurationTimer: null,
    totalAdDuration: 10,
    remainingAdDurationSec: 10,
    totalAdShowSessionDurationSec:0,
    selectedFeedBack: null,
    totalLinkClickCount: 0, 
    isReported:false     
    }
},
computed: {
    ...mapGetters({getUserIndividualInfo:'individualUserModule/getUserIndividualInfo'}),
    getCurrenRestedTime() {
        return this.remainingAdDurationSec 
    },
    getAdWatchedStatus() {
        return this.getCurrenRestedTime === 0
    },
    isCloseButtonActive() {
        if(this.isCommercialPreview) return true;
        return this.remainingAdDurationSec === 0 && this.selectedFeedBack
    }
}

}
</script>

<style scoped lang="scss">

.selected-btn-like {
    border: 1px solid #8e5596!important;
    scale: 1.05;
    font-weight: 600 !important;
    color:#5d3262 !important;
    & i {
        color: #5d3262 !important;
    }
    
}
.selected-btn-not-sure {
    border: 1px solid #e05656ef !important;
    scale: 1.05;
    font-weight: 600 !important;
    color:#cb4d4def !important;
    & i {
        color: #cb4d4def!important;
    }

}
.disabled {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: .4;
}
    .ad-show-to-individual-detaily {
        position: fixed;
        width: 40rem;
        min-height: 24rem;
        height: max-content;
        margin: 2.5rem auto auto auto;
        background: #ffffff;
        inset: 0;
        z-index:1;
        overflow: hidden;
        border-radius: 8px;
        padding: 1.5rem 3rem  !important;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;

        @media screen and (min-width: 1600px) {
            margin-top: 6rem ;
            
        }

        @media 
        screen and (min-width: 1400px) and (max-width: 1600px) {
            margin-top: 2.5rem ;
        }

        @media screen and (min-width: 1024px) and (max-width: 1400px) {
            margin-top: 3rem ;

        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
            margin-top: 5rem ;

        }

        @media screen and (min-width: 500px) and (max-width: 768px) {
            width: 50rem;
            margin-top: 6rem ;
            border-radius: 4px;
            padding: 3rem 2rem !important;

        }

        @media screen and (max-width: 500px) {
            width: 100%;
            margin: auto !important;
            height: 100%;
            box-sizing: border-box;
            // margin-top: 8rem ;
            // padding: 3rem 2rem !important;
            padding: 2rem !important;
            border-radius: 4px;
            padding-top: 3.5rem !important;



        }
       

        &__ad-header-controller {
            width: 100%;
            height: 2rem;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: .5rem;
            @media screen and (min-width: 500px) and (max-width: 768px) {
                height: 2.5rem;

        }

        @media screen and (max-width: 500px) {
                height: 4.5rem;

                &__btn-report {
                    font-size: .9rem !important;
                    margin-bottom: .6rem;


                }

                &__buttons {

                    &__btn-like, &__btn-not-sure{
                        font-size: 1.2rem  !important;
                        padding: .8rem 1.2rem !important;
                        gap: .8rem !important;

                            i {
                                font-size: 1.35rem  !important;
                                
                            }
                    }
               
                }

       

                &__timer {

                    font-size: 1.2rem !important;
                    padding: .6rem 1rem !important;


                    gap: .8rem !important;

                    & span {
                    margin-top: .2rem !important;
                }


                    & img {
                        width: 1.8rem !important;
                        height: 1.8rem !important;
                    }
                }



        }

            &__btn-report {
                    color: #3a3a3aef;
                    background-color: transparent;
                    text-decoration: underline;
                    margin-top: 1rem;
                    margin-left: auto;
                    font-size: .6rem;
                    border:0;
                    background: none;
                    font-weight: 550;
                    padding-bottom: .5rem;
                    cursor: pointer;

                    &:hover {
                        opacity: .8;
                    }

                    

  
                }

            &__buttons {
                width: max-content;
                height: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .8rem;
                margin-left: 1rem;


                &__btn-like, &__btn-not-sure{
                    width: max-content;
                    height: max-content;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .4rem;
                    font-size: .6rem ;
                    font-weight: 550;
                    color: #515f77d7;
                    padding: .4rem .8rem;
                    border-radius: 12px;
                    background: #fff;
                    border:0;
                    cursor: pointer;

                    &:hover {
                        opacity: .8;
                    }


                    i {
                        font-size: .85rem ;

                    }

                }

                &__btn-like {

                    color: #8e5596;
                    background-color: #fdf2ff;

                    
                    i {
                        color: #77427e;
                    }
                }

                &__btn-not-sure {

                    color: #e05656ef;
                    background-color: #fff1f1ef;
                    i {
                        color: #cf4545ef;
                    }
                }

     
            }

            &__timer {
                width: max-content;
                min-width: 3rem;
                height: max-content;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;
                gap: .4rem;
                font-size: .8rem;
                font-weight: 550;
                color: #779fd3;
                border-radius: 6px;
                border:2px dashed #e0edff;
                margin: 1rem 0;
                padding: .3rem .6rem;

                & span {
                    margin-top: .1rem;
                }
                & img {
                    width: 1.1rem;
                    height: 1.1rem;
                }
            }
        }

        &__iframe-container {
            width: 100%;
            height: 18rem;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap:.1rem;
            margin-top: .5rem;

            @media screen and (min-width: 500px) and (max-width: 768px) {
                height: 22rem;

        }

        @media screen and (max-width: 500px) {
                height: 24rem;


        }




            &__link-router {
                width: 100%;
                height: max-content;
                font-size: .7rem;
                font-weight: 500;
                margin-bottom: .5rem;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .3rem;

                @media screen and (max-width: 500px) {
                    
                    font-size: .8rem !important;

                    & a {
                        font-size: .8rem !important;
                    }

                    &__title {
                        font-size: .9rem !important;
                    }
                }

                &__title-go {
                    color: #2c2c2c;
                    font-weight: 500;


                }
                & i {
                    color: #555555;

                }

                & a {
                    margin-left: .5rem;
                    font-size: .7rem;
                    font-weight: 500;
                }
                &__title{
                    color: #000000;
                    font-weight: 600;
                    font-size: .75rem;
                    padding: 0 !important;
                    margin: 0 ;
                    margin-right: auto;

                }

            }

            &__show {
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;

                &__video {
                    width: max-content;
                    height: 100%;
                }

                &__iframe {
                width: 100%;
                height: 100%;
                // border: 2px solid #dfdfdf !important;
                border:0 !important;
                border-radius: 8px;

                @media screen and  (max-width: 768px) {
                    border-radius: 4px;
                }

            }

            }

     
        }

        &__ad-info-card {
            width: 100%;
            height: 14rem;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;

            

            @media screen and (min-width: 500px) and (max-width: 768px) {
                height: 16rem;

        }

        @media screen and (max-width: 500px) {
                min-height: 34rem;
                height: max-content;
                max-height: 60rem;



        }
           
        }

        &__close-btn {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 1.3rem;
            height: 1.3rem;
            border: 0;
            font-size: .9rem;
            border-radius: 50%;
            background: #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all .3s ease-in-out;
            &:hover {
                background: #e6e6e6;
            }


            @media screen and (max-width: 500px) {
                width: 2.8rem !important;
                height: 2.8rem !important;
                font-size: 2rem !important;
                top: .8rem;
                right: 1.8rem !important;

                
            }
        }

    }
</style>