import baseServices from './index';

export default {

    getAdImageLink: async (adImageName) => {
        try {
            const encodedImageName = encodeURIComponent(adImageName);
            const result = await baseServices().get(`bucket-file/get-signed-url/${encodedImageName}`);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getAdVideoLink: async (adVideoName) => {
        try {
            const encodedVideoName = encodeURIComponent(adVideoName);
            const result = await baseServices().get(`bucket-file/get-signed-url-for-video/${encodedVideoName}`);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    softDeleteAd: async (adInfo) => {
        try {
            const result = await baseServices().post("ad-action/soft-delete-ad", adInfo);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
};