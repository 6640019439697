<template>
  <div class="status-change-modal-container">
    
    <PreviewAd :adDetail="ad"  @closeModal="$emit('closeModal')" />

    <button @click="$emit('closeModal')" class="status-change-modal-container__close-btn"><i class="bx bx-x"></i></button>
  </div>
</template>

<script>
import PreviewAd from "../comman-components/preview-ad-container/IndexPreviewAdContainer.vue"
export default {
    components:{
        PreviewAd
    },
    emits:['closeModal'],
    props:{
        ad: {
            type: Object,
            required: true,
            default: {}
        }
    },

    mounted(){

    },
}
</script>

<style scoped lang="scss">
    .status-change-modal-container{
        position: fixed;
        width: 50rem;
        height: 40rem;
        margin: 3vh auto auto auto;
        background: #ffffff;
        inset: 0;
        z-index:1;
        overflow: hidden;
        border-radius: 8px;
        padding: 0 3rem !important;
        overflow-y:auto ;


        &__close-btn {
            position: absolute;
            top: 2.35rem;
            right: 2.85rem;
            width: 1.5rem;
            height: 1.5rem;
            border: 0;
            font-size: 1rem;
            border-radius: 50%;
            background: #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all .3s ease-in-out;
            &:hover {
                background: #e6e6e6;
            }
        }
    }
</style>