<template>
    <div class="failed-payment-container">
        <font-awesome-icon icon="times-circle" class="failed-icon"/>
        <h2 class="failed-payment-title">
            {{ $t('failedPaymentTitle') }}
        </h2>

        <p class="failed-payment-description">
            {{ $t('failedPaymentDescription') }}
        </p>
        <router-link type="a" :to="{ name: 'user-commercial'}">{{ $t('goToCreateAdSection') }}</router-link>

        <!-- <router-link type="a" :to="{ name: 'retryPayment'}" class="retry-link">
            {{ $t('retryPayment') }}
        </router-link> -->
    </div>
</template>

<script>
export default {
    name: 'FailedPaymentPage'
}
</script>

<style scoped lang="scss">
.failed-payment-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .failed-icon {
        color: #FF6347; // Tomatoya yakın kırmızı renk
        font-size: 3rem; // Büyük kırmızı çarpı işareti
        margin-bottom: 1rem;
    }

    .failed-payment-title {
        font-size: 1.5rem;
        font-weight: 700;
        color: #393939;
        margin: 0;
        padding: 0;
    }

    .failed-payment-description {
        font-size: 0.9rem;
        font-weight: 500;
        color: #393939;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
    }

    .retry-link {
        font-size: 0.9rem;
        font-weight: 500;
        color: #0056b3; // Mavi link rengi
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
