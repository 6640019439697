/* eslint-disable no-console */

import { register } from 'register-service-worker'

const CACHE_NAME = 'app-cache-v2'; // Versiyonu artırın
const urlsToCache = [
  '/',
  '/index.html',
  '/manifest/manifest.json',
  '/favicon.ico',

];

// Install event
self.addEventListener('install', (event) => {
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      return cache.addAll(urlsToCache);
    })
  );
});

// Activate event
self.addEventListener('activate', (event) => {
  event.waitUntil(
    caches.keys().then((cacheNames) => {
      return Promise.all(
        cacheNames.map((cache) => {
          if (cache !== CACHE_NAME) {
            return caches.delete(cache);
          }
        })
      );
    })
  );
});


if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js?v=${Date.now()}`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ action: 'skipWaiting' });
        window.location.reload(); // Yeni sürümü yüklemek için sayfayı yenile
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
