const messages = {
    'rejected':[
        {
            'type':'illegal',
            'message':'Illegal Ad',
            'code':8001,
            'description':'Illegal Ad',
            'translateRef':'illegalAd'
        },

        {
            'type':'deleted',
            'message':'Ad deleted by admin cause of stricted content.',
            'code':8002,
            'description':'Deleted Ad',
            'translateRef':'deletedAd'
        },

        {
            'type':'age-limitation',
            'message':'Ad not showable to under 18 year olds',
            'code':8003,
            'description':'Age Limitation Ad',
            'translateRef':'ageLimitationAd'
        },

        {
            'type':'copy-right',
            'message':'Ad not showable due to copyright violation',
            'code':8004,
            'description':'Copy Right Ad',
            'translateRef':'copyRightAd'
        },

        {
            'type':'duplicate',
            'message':'Duplicate Ad',
            'code':8005,
            'description':'Duplicate Ad',
            'translateRef':'duplicateAd'
        },

        {
            'type':'spam',
            'message':'Spam Ad',
            'code':8006,
            'description':'Spam Ad',
            'translateRef':'spamAd'
        },

        {
            'type':'inappropriate',
            'message':'Inappropriate Ad',
            'code':8007,
            'description':'Inappropriate Ad',
            'translateRef':'inappropriateAd'
        },

        {
            'type':'customized',
            'message':'Customized Ad',
            'code':8008,
            'description':'Customized Ad',
            'translateRef':'customizedAd'
        }

    ],
    'approved':[
        {
            'type':'approved',
            'message':'Your Ad has been approved',
            'code':7001,
            'description':'Approved Ad',
            'translateRef':'approvedAd'
        },
        {
            'type':'customized',
            'message':'Customized Ad',
            'code':7008,
            'description':'Customized Ad',
            'translateRef':'customizedAd'
        }
    ],

    'pending':[
        {
            'type':'pending',
            'message':'Pending Ad',
            'code':6001,
            'description':'Pending Ad',
            'translateRef':'pendingAd'
        },

        {
            'type':'pending-long',
            'message':'Ad carefully examinening and awaiting for approval. Please be patient to get approved.',
            'code':6002,
            'description':'Pending Ad for long approvel process.',
            'translateRef':'pendingAdForLongProcess'
        },
        {
            'type':'customized',
            'message':'Customized Ad',
            'code':6008,
            'description':'Customized Ad',
            'translateRef':'customizedAd'
        }

    ]
}

export default messages

