<template>
    <div class="frequently-asking-question">
        <h3>{{ $t('frequentlyAskedQuestions') }}</h3>
        <div class="question-and-answers">
            <div class="question" @click="setSelectedIndex(question?.index || 0)" v-for="question in questions" :key="question.questionTitle">
                <h4><span>{{ question.questionTitle }}</span> <i :class="selectedQuestionIndex === question.index ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"></i>  </h4>
                <p v-if="selectedQuestionIndex === question.index">{{ question.answer }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            selectedQuestionIndex: -1,
            questions: [
                {
                questionTitle:this.$t('fr_ask_1'),
                answer: this.$t('fr_ans_1'),
                index:0
                },

                {
                questionTitle:this.$t('fr_ask_2'),
                answer: this.$t('fr_ans_2'),
                index:1
                },

                {
                questionTitle:this.$t('fr_ask_3'),
                answer: this.$t('fr_ans_3'),
                index:2
                },

                {
                questionTitle:this.$t('fr_ask_4'),
                answer: this.$t('fr_ans_4'),
                index:3
                },

                {
                questionTitle:this.$t('fr_ask_5'),
                answer: this.$t('fr_ans_5'),
                index:4
                },

                {
                questionTitle:this.$t('fr_ask_6'),
                answer: this.$t('fr_ans_6'),
                index:5
                },

                {
                questionTitle:this.$t('fr_ask_7'),
                answer: this.$t('fr_ans_7'),
                index:6
                },

            ]
    }
    },

    methods: {
        setSelectedIndex(index){
            this.selectedQuestionIndex = index === this.selectedQuestionIndex ? -1 : index
        }
    }

}
</script>

<style scoped lang="scss">
    .frequently-asking-question{
        display: flex;
        width: 80%;
        margin: 0 auto;
        height: max-content;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: .5rem;

        & h3 {
            font-size: 2.5rem;
            font-weight: 1000;
            color: #000000;
        }

        & .question-and-answers {
            display: flex;
            width: 100%;
            height: max-content;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
           

            & .question {
                display: flex;
                width: 60%;
                margin: 0 auto;
                height: max-content;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: center;
                padding: .2rem 0;
                gap: .1rem;
                border-top: 1px solid #E0E0E0 ;
                transition: .8s all ease-in-out;
                cursor: pointer;


                & h4 {
                    font-size: .95rem;
                    font-weight: 800;
                    color: #000000;
                    margin: 1rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }

                & p {
                    font-size: .8rem;
                    font-weight: 500;
                    color: #000000bb;
                    margin-top: 0;

                }
            }
        }
    }
</style>