import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store';
import HomeView from '../views/HomeView.vue'
import UserIndividualView from '../views/UserIndividualView.vue'
import UserCommercialView from '../views/UserCommercialView.vue'
import UserAdminView from '../views/UserAdminView.vue'
import PaymentSuccessView from '../views/payments/SuccessPaymentView.vue'
import PaymentFailedView from '../views/payments/FailedPaymentView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,

    meta: {
      requiresAuth: false
    }

  },
  {
    path: '/user-individual',
    name: 'user-individual',
    component: UserIndividualView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-commercial',
    name: 'user-commercial',
    component: UserCommercialView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-admin',
    name: 'user-admin',
    component: UserAdminView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payment-success',
    name: 'payment-success',
    component: PaymentSuccessView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/payment-failed',
    name: 'payment-failed',
    component: PaymentFailedView,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history:createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const role = store.getters.getUserRole;
  const token = store.getters.getToken;
  const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
  const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
  const adminRoleType = process.env.VUE_APP_ADMIN_ROLE_TYPE;

  if (requiresAuth) {
    if (!store.getters.getIsLogin && !token) {
      // If the user is not logged in, redirect to the home page
      if (to.name !== 'home') {
        return next({ name: 'home' });
      }
    } else if (adminRoleType === role) {
      // If the user is an admin, redirect to the admin page
      if (to.name !== 'user-admin') {
        return next({ name: 'user-admin' });
      }
    } else if (indRoleType === role) {
      // If the user is an individual, redirect to the individual user page
      if (to.name !== 'user-individual') {
        return next({ name: 'user-individual' });
      }
    } else if (comRoleType === role) {
      // If the user is a commercial user, redirect to the commercial user page
      if (to.name !== 'user-commercial') {
        return next({ name: 'user-commercial' });
      }
    }
  } 

  // If none of the above conditions apply, allow the navigation
  next();
});



export default router
