<template>
  <div class="filter-ads-individual-container">
    <div class="search-input-filter-ads">
      <!-- <label for="filter-ads-categories" >{{ $t('categories') }}</label> -->
      <n-tree-select multiple checkable filterable v-model:value="selectedCategories" :clear-filter-after-select="false"
        :options="getModifiedCategories" size="small" :placeholder="$t('categoriesPlaceHolder')" :max-tag-count="1"
        clearable style="font-size: .5rem !important;" />
    </div>
    <div class="date-filter-ads-by-publish">
      <label v-if="!dateActive" @click="dateActive = true" class="filter-ads-by-publish-date-input" id="dateLabel" for="filter-ad-date">{{ $t('publishDate') }}</label>
      <input
      v-else      
      type="date"
      id="filter-ad-date"
      class="filter-ads-by-publish-date-input" 
      @focus="dateActive = true" @blur="resetDate"
     

      style="width: auto; height: auto;"
      v-model="getDatePublishFilter" >
    </div>
    <div class="search-filter-ads">
      <input type="text" class="search-ads-input" v-model.lazy.trim="searchAdText" :placeholder="$t('search')">
      <i class="fa-solid fa-bolt-lightning"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectedCategories: [],
      getDatePublishFilter: null,
      searchAdText: '',
      dateActive: false
    }
  },
  computed: {
    ...mapGetters({
      getUserIndividualInfo: 'individualUserModule/getUserIndividualInfo',
      getFilterCategories: 'individualUserModule/getFilterCategories'
    }),
    ...mapGetters(['getSelectedLanguage']),
    getModifiedCategories() {
      if (this.getFilterCategories?.length) {
        return this.getFilterCategories.map((category) => {
          return {
            label: category.name,
            key: category.id,
            children: category.subcategories.map((subcategory) => {
              return {
                label: subcategory.name,
                key: subcategory.id
              }
            })

          }
        })
      }
      return []

    }

  },

  methods: {
    getCategories() {
      this.$store.dispatch('individualUserModule/getFilterCategories', {
        language: this.getSelectedLanguage
      })
    },
    resetDate() {
      if(!this.getDatePublishFilter && this.dateActive){
        this.dateActive = false
      }
},


  },
  created() {
    this.getCategories()
  }

}
</script>

<style  lang="scss">
.n-base-selection,
.n-input {
  --n-border-radius: 6px !important;
  /* Set the CSS variable globally for all such elements */
  --n-placeholder-color: #526cc4 !important;
  font-weight: 550 !important;
  --n-border: 1px solid #afc0ff!important;
  --n-color: #ffffff !important;
  --n-arrow-size: .9rem !important;

  --n-font-size: .6rem !important;
  --n-arrow-color: #526cc4  !important;
  --n-height: 3.05em !important;
  --n-font-weight: 600 !important;
  // --n-border: 0 !important;
  --n-outline: 0 !important;

  @media screen and (max-width: 768px) {
    --n-border-radius: 4px !important;
    --n-height: 3.2em !important;


  }




}


.filter-ads-individual-container {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: transparent;
  box-sizing: border-box;
  gap: 1rem;
  box-sizing: border-box;
  // padding-bottom: 1rem;


  & .search-input-filter-ads {
    min-width: 10rem;
    width: max-content;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: .2rem;

    & label {
      font-size: .6rem;
      font-weight: 600;
      color: #28303E;
      text-transform: capitalize;
    }
  }



  & .date-filter-ads-by-publish {
    position: relative;
    width: max-content;
    height: max-content;

    #dateLabel {
  position: absolute;
  // left: 10px;
  top: 0;
  color: #526cc4;
  font-weight: 550 !important;
  cursor: text;
  transition: all 0.3s;
}

#filter-ad-date:valid + #dateLabel,
#filter-ad-date:focus + #dateLabel {
  display: none;

}

    & .filter-ads-by-publish-date-input {
      display: flex;
      flex-flow: row nowrap;
      min-width: 6rem;
      width: 6rem;
      align-items: center;
      justify-content: flex-start;
      margin-left: auto;
      border: 1px solid #afc0ff;
      padding: .4rem .6rem;
      border-radius: 6px;
      background: #ffffff;
      gap: .2rem;
      cursor: pointer;
      transition: .4s ease-in-out all;
      font-size: .6rem;
      font-weight: 550;
      color: #526cc4;

      &::placeholder {
        color: #8ea7fa;
      }

      @media screen and (max-width: 768px) {

        border-radius: 4px;
      }

    }

    // & i {
    //   font-size: .8rem;
    //   color: #ffbf00;
    //   position: absolute;
    //   top: 50%;
    //   right: 1rem;
    //   transform: translateY(-50%);
    // }


  }

  & .search-filter-ads {
    width: auto;
    height: 100%;
    position: relative;
    margin-left: auto;
    box-sizing: border-box;




    & .search-ads-input {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-left: auto;
      border: 1px solid #afc0ff;
      padding: .46rem .6rem;
      border-radius: 6px;
      background: #ffffff;
      gap: .2rem;
      cursor: pointer;
      transition: .4s ease-in-out all;
      font-size: .6rem;
      font-weight: 550;
      color: #526cc4;
      text-transform: capitalize;

      &::placeholder {
        color: #526cc4;
      }

      @media screen and (max-width: 768px) {

border-radius: 4px;
}

    }

    & i {
      font-size: .8rem;
      color: #ffbf00;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }



  }


}
</style>