import baseServices from './index';

export default {    
    setUserLogs: async (userLogs) => {
        try {
            const result = await baseServices().post('auth/set-user-logs', userLogs);
            return result;
        } catch (error) {
            return error
        }
    }
};
