<template>
    <div class="commercial-intent-payment-container">

            <PaymentReceiverSection :currency="currency" :userInfo="getUserCommercialInfo" :selectedLanguage="getSelectedLanguage"/>

        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentReceiverSection from './PaymentReceiverSection.vue'

export default {
    components: { PaymentReceiverSection },
    data() {
        return {
            currency: '',


        };
    },

    computed: {
        ...mapGetters({ getUserCommercialInfo: 'commercialUserModule/getUserCommercialInfo' }),
        ...mapGetters(['getSelectedLanguage']),


    },

    created() {
        const currency = this.getUserCommercialInfo?.currency?.currency;
        if (currency) {
            this.currency = currency.toLowerCase();
        }
    },



    methods: {
    
    },
};
</script>

<style scoped lang="scss">
.commercial-intent-payment-container {
    width: 30rem;
    height: max-content; // Yüksekliği otomatik hale getirdik
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    border-radius: 0;
    border: 0;
    padding: 0; // İçerik için biraz padding ekledik
    background-color: transparent ;// Arka plan rengi
    overflow: auto;

    @media screen and (max-width: 368px) {
        width: auto; // Mobil uyumluluk için genişliği %90 yaptık
    }

    .set-amount-of-payment-container {
        width: 40%;
        height: auto; // Yüksekliği otomatik hale getirdik
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem; // Aralıkları biraz artırdık
    }

    .payment-request-section {
        width: 55%;
        height: auto; // Yüksekliği otomatik hale getirdik
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem; // Aralıkları biraz artırdık
    }

    // Kart Bilgileri Alanı
    .stripe-element-container {
        background-color: #ffffff; // Arka plan rengi
        border: 1px solid #ccc; // Kenar rengi
        border-radius: 8px; // Kenar yuvarlama
        padding: 1rem; // İçerik için padding
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Gölgelendirme efekti
        transition: border 0.2s; // Kenar geçiş efekti

        &:focus-within {
            border-color: #007bff; // Alanın odaklandığında kenar rengi
        }
    }

    input {
        width: 100%; // Genişlik ayarı
        padding: 0.75rem; // İçerik için padding
        border: 1px solid #ccc; // Kenar rengi
        border-radius: 4px; // Kenar yuvarlama
        font-size: 16px; // Yazı boyutu
        outline: none; // Kenar belirleyici
        transition: border-color 0.2s; // Kenar geçiş efekti

        &:focus {
            border-color: #007bff; // Odaklandığında kenar rengi
        }
    }

    button {
        cursor: pointer;
        background-color: #007bff; // Buton arka plan rengi
        color: #fff; // Buton yazı rengi
        border: none; // Kenar yok
        border-radius: 4px; // Kenar yuvarlama
        padding: 0.75rem; // İçerik için padding
        font-size: 16px; // Yazı boyutu
        transition: background-color 0.3s; // Geçiş efekti

        &:disabled {
            background-color: #cccccc; // Devre dışı kaldığında arka plan rengi
            cursor: not-allowed; // İzin verilmez simgesi
        }

        &:hover:not(:disabled) {
            background-color: #0056b3; // Hover durumu
        }
    }

    p {
        margin-top: 1rem; // Yukarıdan boşluk
        font-size: 14px; // Yazı boyutu
        color: #d9534f; // Hata mesajı rengi
    }
}
</style>
