<template>
  <div class="individual-user-section-container">
    <UserIndividualNavbar />
    <div class="individual-user-section-container__ads">
      <div class="individual-user-section-container__ads__show-container">
        <AdsFilter />

        <AdsContainer :key="selectedAdToShow ? 'show' : 'hide'" @setAdDetailModel="setAdDetailModel($event)" />

      </div>
      <div class="individual-user-section-container__ads__luckme-game-and-filter">
        <LuckmeGame />
      </div>
    </div>
  </div>
  <ModalContainer :isExitable="false" v-if="selectedAdToShow" >
    <AdShowDetaily :ad="selectedAdToShow" @closeModal="closeModal($event)" />
  </ModalContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import UserIndividualNavbar from './NavbarIndividualUser.vue' // Navbar
import AdsContainer from './AdsContainer.vue' // Ads
import LuckmeGame from './LuckmeGame.vue';
import AdsFilter from './FilterAdsContainer.vue'
import ModalContainer from '@/utils/ModalContainer.vue'
import AdShowDetaily from './AdShowToIndividualDetaily.vue'

export default {
    components: {
        UserIndividualNavbar,
        AdsContainer,
        LuckmeGame,
        AdsFilter,
        ModalContainer,
        AdShowDetaily
    },
    computed: {
        ...mapGetters({getUserIndividualInfo:'individualUserModule/getUserIndividualInfo' }),

    },

    methods: {
      setAdDetailModel(ad){
        this.selectedAdToShow = ad
      },
      closeModal(){

        this.selectedAdToShow = null
      }
    },
    data() {

      return {
        selectedAdToShow:null,
      }
    },
    created(){
        console.log(this.getUserIndividualInfo)
    }
}
</script>

<style scoped lang="scss">
.individual-user-section-container{

    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;

    &__ads{
        width: 84%;
        // min-height: 40rem;
        height: 34rem;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin: .5rem auto 0 auto;
        // background: #f7f7f7;
        border-radius: 12px;
        border: 1px solid #fefefe;
        padding: 0;
        box-sizing: border-box;

        @media screen and (min-width: 3000px) {
          width: 60%;
        }

          @media screen and ((min-width: 2560px) and (max-width: 3000px)) {
            width: 64%;
          }

          @media screen and ((min-width: 2360px) and (max-width: 2560px)) {
            width: 68%;
          }

          @media screen and ((min-width: 2140px) and (max-width: 2360px)) {
            width: 72%;
          }

          @media screen and ((min-width: 1920px) and (max-width: 2140px)) {
            width: 76%;
          }

          @media screen and ((min-width: 1820px) and (max-width: 1920px)) {
            width: 78%;
          }

          @media screen and ((min-width: 1820px) and (max-width: 1920px)) {
            width: 80%;
          }

          @media screen and ((min-width: 1620px) and (max-width: 1820px)) {
            width: 82%;
          }

          @media screen and ((min-width: 1440px) and (max-width: 1620px)) {
            width:84%;
            
          }
    

        @media screen and (max-width: 768px) {
          width: 80%;
          margin: 0 auto;
          margin-bottom: 1rem;
          margin-top: .5rem;
          height: max-content;


        }



        &__show-container{
            width: 64%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: space-between;
            box-sizing: border-box;
            // gap: 1rem;

            @media screen and (max-width: 768px) {
              width: 100%;
              margin: 0 auto;
              margin-bottom: 2rem;
            }

        }

        &__luckme-game-and-filter{
            width: 34%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;

            @media screen and (max-width: 768px) {
              width: 100%;
              margin: 0 auto;
             
            }

        }
    }
}
</style>