
import commercialUserServices from '@/services/commercialUserServices';
import registerUserServices from '@/services/registerUserServices';


const adminUserStoreModule = {
    namespaced: true,
    state: {
        filterCategories: [],
        userCommercialInfo: {
            userId:'',
            companyName: '',
            ipAddress: '',
            email: '',
            phone: '',
            country: {},
            city: {},
            role: 'commercial',
            countryCode: '',
            balance:0,
            lastLoginDate:Date.now(),


       
          },
    },
    mutations: {
        setCommercialUserInfo(state, userInfo) {
          console.log(userInfo,'userInfoAD')
            state.userCommercialInfo = userInfo;
          },

        setFilterCategories(state, categories) {
          state.filterCategories = categories;
        },
        upadteCommercialUserBalance(state, balance) {
          state.userCommercialInfo.balance = balance;
        }
    },
    actions: {

        async setAdDraft({ commit },adDetail) {
            try {
              const result = await commercialUserServices.setAdDraft(adDetail);
              if(result?.status_code == 200){
                return result?.data || null;
              }
              return null;
            } catch (error) {
              console.log(error);
              return error;
            }
        },
      async getAdPublishCost({ commit },adDetail) {
        try {
          const result = await commercialUserServices.getAdPublishCost(adDetail);
          if(result?.status_code == 200){
            return result?.data || null;
          }
          return null;
        } catch (error) {
          console.log(error);
          return error;
        }
      },

      async getUpdatedBalance({ commit },userInfo) {
        try {
          const result = await commercialUserServices.getUpdateBalance(userInfo);
          if(result?.status_code == 200){
            commit('upadteCommercialUserBalance', result?.data?.balance || 0);
            return true;
          }
          return false;
        } catch (error) {
          console.log(error);
          return error;
        }
      },

      async getFilterCategories({ commit },filter) {
        try {
          const result = await commercialUserServices.getFilterCategories(filter);
          if(result?.status_code == 200){
            commit('setFilterCategories', result?.data || []);
          }
          else {
            throw new Error(result?.message);
          }
        } catch (error) {
          console.log(error);
        }
      },

        async publishAd({ commit }, adDetail) {
            try {
              const result = await commercialUserServices.publishAd(adDetail);
              if(result?.status_code == 201){
                return true;
              }
              return false;
            } catch (error) {
              console.log(error);
              return error;
            }
          },

          async getPaymentIntent({ commit }, paymentInfo) {
            try {
              const result = await commercialUserServices.createPaymentIntent(paymentInfo);
              if(result?.status_code == 200){
                return result|| null;
              }
              return false;
            } catch (error) {
              console.log(error);
              return error;
            }
          },
        async setCommercialUserRegister ({ commit,dispatch }, registerUserInfo ) {
            try {
              const response = await registerUserServices.registerCommercialUser(registerUserInfo);
      
              const userInfo = response?.data?.userInfo || null;
              const token = response?.token || null;
              const logCode = '10001'
              if(userInfo && token && response?.status_code == 201){
                commit('setCommercialUserInfo', userInfo);
                commit('setUserRole', userInfo.role,{ root: true });
                commit('setToken', token,{ root: true });
                commit('setIsLogin', true,{ root: true });

                dispatch('setUserLogs',logCode,{ root: true });

                return userInfo;
              }
      
              return false;
            } catch (error) {
              console.error(error);
              return error;
              
            }
          },
    async getMyCommercialAds({ state},filter) {
        try {
          const userId = state.userCommercialInfo?.userId || null;
          const result = await commercialUserServices.getMyCommercialAds(userId,filter);
          if(result?.status_code == 200){
            return result?.data || [];
          }
          return [];
        } catch (error) {
          console.log(error);
          return error;
        }
      },
    },
    getters: {
        getUserCommercialInfo: (state) => state.userCommercialInfo,
        getFilterCategories: (state) => state.filterCategories,
        getUserBalance: (state) => state.userCommercialInfo.balance


    }
}
export default adminUserStoreModule