<template>
  <div class="preview-ad-container"> 
    <AdminUserAdDetail :adDetails="adDetail" @setAdStatus="setAdStatus($event)" />
    <div class="preview-ad-container__ad-details">
        <div class="preview-ad-container__ad-details__ad-info">
            <AdDetailyShowContainer :adDetails="adDetail" />
        </div>

        <div class="preview-ad-container__ad-details__iframe-container" v-if="adDetail?.link || adDetail?.video">
            <div class="preview-ad-container__ad-details__iframe-container__video" v-if="adDetail?.video?.bucketLocation">
                <video :src="adDetail?.video?.bucketLocation" controls 
                autoplay
                :poster="adDetail?.image?.bucketLocation"
                :style="{width: '20rem', height: '20rem', objectFit: 'cover'}"

            ></video>

            <hr>
            </div>

            <iframe v-if="adDetail?.link" class="preview-ad-container__ad-details__iframe-container__iframe" :src="adDetail?.link" frameborder="0"></iframe>
        </div>
    </div>

    <div class="preview-ad-container__update-container">
        <div class="preview-ad-container__update-container__status-update-message">

            <div class="preview-ad-container__update-container__status-update-message__messages-standards">
                <div v-for="message in getSelectedAdStatusStandardMessages.filter(message => message.type !== 'customized')" :key="message.type" :class="{'selected-status-message': selectedStatusMessages.find(selectedStatusMessage => selectedStatusMessage.code === message.code)}" @click="setAdStatusMessage(message)" class="preview-ad-container__update-container__status-update-message__messages-standards__message">
                    {{ message.message }}
                </div>
            </div>

            <textarea name="messages-to-ad-status" v-model="adStatusCustomizeMessageResponse" :placeholder="$t('messagesToAdStatusPlaceholder')"></textarea>

  
        </div>

        <div class="preview-ad-container__update-container__update-btn">
            <p class="preview-ad-container__update-container__update-btn__message"
            :style="{color: updatedResultStatus === true  ? 'green' : 'red'}"
            v-if="updatedResultStatus !== null">
                {{ updatedResultMessage }}
            </p>
            <button class="preview-ad-container__update-container__update-btn__update-btn" @click="updateAdStatus">{{ $t('update') }}</button>
        </div>
    </div>

  </div>
</template>

<script>
import AdminUserAdDetail from  "./AdminUserToSeeDetails.vue"
import AdDetailyShowContainer from "./AdDetailyShowContainer.vue";
import adStatusMessages from "@/assets/admin/ads/getAdStatusStandardMessages.js";
import { mapGetters } from 'vuex';
import {throttle} from 'lodash'
import moment from 'moment';
export default {
emits:['closeModal'],    
props:{
    adDetail: {
        type: Object,
        required: true,
        default: {}
    }
},
components:{
    AdminUserAdDetail,
    AdDetailyShowContainer,
},
data() {
    return {
        adStatusMessages,
        selectedStatusMessages: [],
        selectedStatus: '',
        adStatusCustomizeMessageResponse:'',
        updatedResultStatus:null,
        updatedResultMessage:''

        
    }
},
computed:{
    getSelectedAdStatusStandardMessages(){
        const statusTypes = ['rejected', 'approved', 'pending'];
        if(!statusTypes.includes(this.selectedStatus)) return [];
        return this.adStatusMessages[this.selectedStatus]
    }
},

watch: {
    adStatusCustomizeMessageResponse(customizeMessage = ''){
    const currentStatusTypeMessageCode = this.getSelectedAdStatusStandardMessages.find(message => message.type === 'customized')?.code; 
    const customizedMessageIndex = this.selectedStatusMessages.findIndex(message => message.code === currentStatusTypeMessageCode);
    if(currentStatusTypeMessageCode && customizedMessageIndex > -1){
        this.selectedStatusMessages[customizedMessageIndex].message = customizeMessage;
    }
    else {
        const customizedMessageObj = this.getSelectedAdStatusStandardMessages.find(message => message.type === 'customized');
        if(customizedMessageObj){
            customizedMessageObj.message = customizeMessage;
            this.selectedStatusMessages.push(customizedMessageObj)
        }
    }
    }
},
created(){
    this.selectedStatus = this.adDetail?.statusApproval || 'pending';
    this.selectedStatusMessages = this.adDetail?.currentStatusMessages|| [];
},
methods: {
    setAdStatus(status){
        this.selectedStatusMessages = [];
        this.selectedStatus = status;
    },
    setAdStatusMessage(message){
     const statusMessageExist = this.selectedStatusMessages.find(statusMessage => statusMessage.type === message.type);
        if(statusMessageExist){
            this.selectedStatusMessages = this.selectedStatusMessages.filter(statusMessage => statusMessage.type !== message.type)
        }
        else {
            this.selectedStatusMessages.push(message)
        }
        
    },

  updateAdStatus: throttle( async function(){
    const statusResponseMessagesCheck = this.selectedStatusMessages.filter(message => message.message !== '');
    if(statusResponseMessagesCheck.length === 0){
        this.updatedResultStatus = false;
        this.updatedResultMessage = this.$t('adStatusMessageRequired');
        setTimeout(() => {
            this.updatedResultStatus = null;
            this.updatedResultMessage = '';
        },6000)

        return;
    }
    const response = await this.$store.dispatch('adminUserModule/updateAdStatus', {
        adId: this.adDetail._id,
        previousStatus: this.adDetail.statusApproval,
        status: this.selectedStatus,
        statusMessages: this.selectedStatusMessages.map(message => {
            return {
                ...message,
                createdDate: moment()
            }
        })
    })

    if(response?.status_code == 201){
        this.updatedResultStatus = true;
        this.updatedResultMessage = this.$t('adStatusUpdated');

        setTimeout(() => {
            
            this.$emit('closeModal')
        }, 1500);
    }
    else {
        this.updatedResultStatus = false;
        this.updatedResultMessage = this.$t('error') + '/ ' + response?.status_code + '/ ' + response?.message;
    }
    

  },500)

},
}
</script>

<style scoped lang="scss">

    .selected-status-message{
        background: #d5d5d5 !important;
        color: #1c222d !important;
    }
    .preview-ad-container{

        width: 100%;
        height: inherit;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 auto;
        gap: 1rem;


        &__ad-details{
            width: 100%;
            height: 36%;

            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: space-between;
            max-height: 14rem;


            &__ad-info{
                width: 52%;
                height: 100%;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 1rem;
            }

            &__iframe-container{
            width: 40%;
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;
            overflow: auto;

            &__video{


                border-radius: 8px;
                
            }

            &__iframe{
                width: 100%;
                height: 100%;
                border:0 !important;
                border-radius: 8px;


            }
        }
        }

        &__update-container{
            width: 100%;
            height: 18%;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;


            &__status-update-message{
                width: 54%;
                height:100%;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                border-radius: 8px;
                border: 1px solid #dedede;
                padding: 1%;
                

                &__messages-standards{
                    width: 100%;
                    height: 74%;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    overflow: auto;
                    gap: .3rem;
                    
                    &__message{

                            width: max-content;
                            height: max-content;
                            font-size: .55rem;
                            padding: .2rem .4rem;
                            font-weight: 500;
                            border-radius: 4px;
                            border: 1px solid #EDEEF1;
                            background: #f4f4f4;
                            color: #28303E;
                            cursor: pointer;

                            &:hover{
                                background: #EDEEF1;
                                color: #28303E;
                            }

                        
                    }
           
                }

                & textarea {
                        position: relative;
                        border: 0;
                        outline: 0;
                        z-index: 0;
                        padding: 1.5%;
                        font-size: .5rem;
                        width: 100%;
                        height: 25%;
                        border-radius: 4px;
                        border-top: 1px solid #EDEEF1 ;
                        margin-top: .4rem;
                        

                        &::placeholder {
                            color: #7e7e7ea9;
                            font-size: .55rem;
                            font-weight: 500;
                            text-align: center;
                            padding-top: 2%;
                        }
                    }
            }

            &__update-btn{
                width: 40%;
                height: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items:flex-end;
                justify-content: flex-end;
                gap: 1rem;
                &__update-btn{
                    width: max-content;
                    height: max-content;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    border-radius: 6px;
                    border: 1px solid #b8e5ff;
                    background: #fff;
                    color: #52a7d8;
                    font-size: .65rem;
                    font-weight:600;
                    padding: .4rem 1.2rem;
                    cursor: pointer;

                    &:hover {
                        opacity: .8;
                        background: #f4fbff;


                    }
                }
            }
        }

    }
</style>