<template>
  <div class="user-commercial-view">
    <CommercialUserSection />
  </div>
</template>

<script>
// @ is an alias to /src
import CommercialUserSection from '@/components/commercial-user-section/IndexCommercialUserSection.vue'

export default {
  name: 'CommercialView',
  components: {
    CommercialUserSection
  }
}
</script>


<style lang="scss">
.user-commercial-view{ 
  height:auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
