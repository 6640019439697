<template>
  <div class="app-aim-detail">
        <div class="image-section">
            <img :src="require('@/assets/app_aim_pic.png')" alt="app.aim">
        </div>

        <div class="text-section">
            <h3 class="text-section__title">{{ $t('whyUseApp') }}</h3>
            <p class="text-section__text" v-html="$t('whyUseAppDetail')"></p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.app-aim-detail{
    display: flex;
    width: 84%;
    margin: 1rem auto;
    height: max-content;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    background: #F9FAFB;
    padding: 3rem 2rem;


    & .image-section {
        display: flex;
        width: 40%;
        height: max-content;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        & img {
            width: 30rem;
            height: 30rem;
            object-fit: scale-down;
            border-radius: 12px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
    }

    & .text-section {
        display: flex;
        width: 50%;
        height: max-content;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;

        &__title {
            font-size: 1.8rem;
            font-weight: 1000;
            color: #000000;
        }

        &__text {
            font-size: .9rem;
            font-weight: 500;
            color: #646464;
            text-align: justify;
            margin-top: -1rem;
        }
    }
}
</style>