<template>
  <div class="app-steps-explanation-container">
    <h3 class="app-steps-explanation">{{ $t('stepsExplanation') }} <i class='bx bxs-dollar-circle' ></i></h3>
    <div class="steps-container">
        <div class="step-card" :style="`background-color: ${step.bgColor}`" v-for="step in steps" :key="step.title">
            <div class="step-card__icon"><i :class="step.icon"></i></div>
            <div class="step-card__title">{{ step.title }}</div>
            <div class="step-card__description">{{ step.description }}</div>
            <div class="step-card__detail">{{ step.detail }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
data(){
    return {
        steps:[
            {
            icon:'bx bx-walk',    
            title:this.$t('step') + ' 1',
            description: this.$t('stepOne'),
            detail: this.$t('stepOneDetail'),
            bgColor:'#6BA5FF'
            },
            {
            icon:'bx bx-target-lock',    
            title:this.$t('step') + ' 2',
            description: this.$t('stepTwo'),
            detail: this.$t('stepTwoDetail'),
            bgColor:'#0044BC'
            },
            {
            icon:'bx bx-money',
            title:this.$t('step') + ' 3',
            description: this.$t('stepThree'),
            detail: this.$t('stepThreeDetail'),
            bgColor:'#406EF0'
            }
        ]
    }
},
}
</script>

<style scoped lang="scss">
.app-steps-explanation-container{
    display: flex;
    width: 80%;
    height: max-content;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    margin: .5rem auto;

    & .app-steps-explanation {
        font-size: 2rem;
        font-weight: 1000;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & .bxs-dollar-circle {
            font-size: 4rem;
            margin-left: .5rem;
            color: #85bb65;
        }
    }

    & .steps-container {
        display: flex;
        width: 100%;
        height: max-content;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: space-between;
        

        & .step-card {
            display: flex;
            width: 26%;
            height: 18rem;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            color: #FFFFFF;
            background: #0044BC;
            border-radius: 10px;
            padding: 3rem 1.8rem;
            border: 1px solid #e7ecf5;

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                border-radius: 50%;
                background: #001D45;
                color: #ffffff;
                font-weight: 600;
                margin-bottom: 1.5rem;

                & i {
                        font-size: 2rem;
                }
            }

            &__title {
                font-size: .9rem;
                font-weight: 600;
                color: #ffffffc5;
                margin-bottom: 1rem;
            }

            &__description {
                font-size: 1.2rem;
                font-weight: 700;
            }

            &__detail {
                font-size: .8rem;
                font-weight: 500;
                margin-top: .5rem;
                color: #ffffffc7;
            }
        }
    }



}
</style>