import baseServices from './index';

export default {

    setAdDraft: async (adDetail) => {
        try {
            const result = await baseServices().post('commercial-user/set-ad-draft', adDetail);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    publishAd: async (adDetail) => {

        try {   
            const result = await baseServices().post('commercial-user/publish-ad', adDetail,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } );
            return result;

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getUpdateBalance: async (updateBalanceInfo) => {
        try {
            const result = await baseServices().post('commercial-user/get-updated-balance', updateBalanceInfo);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getMyCommercialAds: async (userId,filter) => {
        try {
            const result = await baseServices().post(`commercial-user/get-my-commercial-ads`,{
                userId,
                filter
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    createPaymentIntent: async (paymentInfo) => {
        try {
            const result = await baseServices().post(`payments/create-payment-intent`,paymentInfo);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    verifyPayment: async (sessionIdValue) => {
        try {
            const result = await baseServices().post(`payments/verify-payment-session`,{sessionId:sessionIdValue});
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    getFilterCategories: async (filter) => {
        try {
            const result = await baseServices().post('commercial-user/get-filter-categories', 
                filter
            );
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getAdPublishCost: async (adDetail) => {
        try {
            const result = await baseServices().post('commercial-user/get-ad-cost', adDetail);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
};