<template>
  <div class="modal-container" @click.self="isExitable ? $emit('closeModal') : null">
    <slot></slot>
  </div>
</template>

<script>
export default {
emits: ['closeModal'],
props: {
  isExitable: {
    type: Boolean,
    default: true
  }
}
}
</script>

<style scoped lang="scss">
    .modal-container{

        position: fixed;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        inset: 0;
        background: #000000b8;
        z-index:1;
        overflow: hidden;
        backdrop-filter: blur(10px) !important;
        
       
    }
</style>