<template>
  <div class="ad-card-container" @click="$emit('setAdDetailModel', adDetail)">

    <div class="ad-card-container__media">
        <img  :src="adDetail?.image?.bucketLocation " alt="ad banner">
    </div>

    <div class="ad-card-container__info">
        <h3 class="ad-card-container__info__title">
            <span v-if="adDetail?.title.length < 80">{{ adDetail?.title || 'no-title' }}</span>
            <show-shorter-text-view  :customLength="80" v-else :text="adDetail?.title || 'no-title'"></show-shorter-text-view>
        </h3>
        <p class="ad-card-container__info__description">
            <span v-if="adDetail?.description.length < 75">{{ adDetail?.description || 'no-description' }}</span>
            <show-shorter-text-view :customLength="75"  v-else :text="adDetail?.description || 'no-description'"></show-shorter-text-view>
        </p>
    </div>
  </div>
</template>

<script>
import ShowShorterTextView from '@/utils/ShowShorterTextView.vue'
export default {
  components: { ShowShorterTextView },
 emits:['setAdDetailModel'],   
props:{
    adDetail: {
        type: Object,
        required: true,
        default: {}
    },
    index: {
        type: Number,
        required: true,
        default: 0
    }
},
data() {
    return {
        boxShadows:[
        '0px 0px 10px 2px #e5d469',
        ' 0px 0px 10px 2px #bc69e5',
    ' 0px 0px 10px 2px #69a9e5',
    ' 0px 0px 10px 2px #69e57e',
    ' 0px 0px 10px 2px #e56969',
    ' 0px 0px 10px 2px #69e5e5',


        ],
        boxHoverShadows:[
        ' 0px 0px 12px 0px #d6c23e',
        ' 0px 0px 12px 2px #a555cd',
        ' 0px 0px 12px 2px #4f8cc6',
        ' 0px 0px 12px 2px #53cb67',
        ' 0px 0px 12px 2px #ce5757',

        ' 0px 0px 12px 2px #54cbcb',


        ] 
    }
},
methods:{
    async setInitialImageLink(){
        try {
    if(typeof this.adDetail?.image !== 'object' ){
        this.adDetail['image'] = {
                bucketLocation: ''
            
        }
    }
    const banner  = (this.adDetail?.image || {})?.bucketLocation || (this.adDetail?.videoBanner || {})?.bucketLocation || '';
    if(banner.startsWith('/img/app_logos/darkest_app_logo.png')) return '/img/app_logos/darkest_app_logo.png';
    if(banner.startsWith('https://')) return;

    if(banner){

        this.adDetail.image.bucketLocation = await this.setAdImageLink(banner)
    }

    else {
        this.adDetail.image.bucketLocation = '/img/app_logos/darkest_app_logo.png'
    }
    
} catch (error) {
            console.log(error)
    }
    },
    async  setAdImageLink(image){
        if(image){
            try {
               const result = await this.$store.dispatch('getAdImageLink', image)
               console.log(result)
               if(result?.status_code == 200){
                   return result?.data?.url || null
               }
    
            } catch (error) {
                console.log(error)
                return '/img/app_logos/darkest_app_logo.png'
            }
 
        }
        return '/img/app_logos/darkest_app_logo.png'

    },
},
async beforeMount(){

    await this.setInitialImageLink()
}
}
</script>

<style scoped lang="scss">

.ad-card-container{
    width: 32%;
    height: 48%;
    border-radius: 6px;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;

    display: flex;
    background-color: transparent !important;

    border: 1px solid #ecf2ff;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;

  

    cursor: pointer;
    position: relative;

    @media screen and (max-width: 768px) {
          width: 100%;
          height: 100%;


        }

     


    // box-shadow: v-bind('boxShadows[index]');
// box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);

    transition: .2s all ease-in-out;




    p,h3 {
        margin: 0;
        padding: 0;
    }
    &:hover{
        // border: 1px solid #52a7d8;
        // box-shadow: 0px 0px 10px 0px #4392c0;
        // box-shadow: v-bind('boxHoverShadows[index]');
        scale: 1.01;
        z-index: 1;
    }

    @media screen and (max-width: 768px) {
        height: 100%;
    }

    // @media screen and (max-width: 650px) {
    //     width: 44%;

    // }

    // @media screen and (max-width: 625px) {
    //     width: 46%;
        
    // }

    // @media screen and (max-width: 550px) {
    //     width: 48%;
        
    // }

    // @media screen and (max-width: 400px) {
    //     width: 100%;
        
    // }


    &__media{
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;

            @media screen and  (max-width: 768px) {
                    border-radius: 4px !important;
                }
    //         border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;

        }
    }
    &__info{
        width: 100%;
        height: 38%;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding:  .4rem ;
        box-sizing: border-box;
        gap: .2rem;
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, #ffffffef 100%,#fefdf8ec 100% );



        // border-bottom-left-radius: 6px;
        // border-bottom-right-radius: 6px;

        @media screen and (max-width: 768px) {
            &__title{
                font-size: .8rem !important;
            }

            &__description{
                font-size: .65rem !important;
            }
        }


        @media screen and (max-width: 500px) {
            &__title{
                margin-top: 1rem !important;
                font-size: .95rem !important;
            }

            &__description{
                font-size: .85rem !important;
            }
        }

        &__title{
            width: 100%;
            height: max-content;
            font-size: .75rem;
            font-weight: 800;
            color: #000000 ;
        }
        &__description{
            width: 100%;
            height: max-content;
            font-size: .58rem;
            font-weight: 500;
            color: #000000db ;
        }
    }
}
</style>