<template>
    <nav class="navbar-container-commercial">
        <div class="navbar-sub-container-commercial">

      <div class="navbar-container-commercial__app-logo-container">
          <img src="/img/app_logos/darkest_app_logo.png" alt="app logo" class="navbar-container-commercial__app-logo-container__app-logo-img">
      </div>
      <div class="navbar-container-commercial__user-login-section">
        <div class="navbar-container-commercial__user-login-section__first">
            <div class="navbar-container-commercial__user-login-section__first__user-icon">
                <i class='bx bxs-user'></i>
            </div>
            <div class="navbar-container-commercial__user-login-section__first__user-info">
                <p class="navbar-container-commercial__user-login-section__first__user-info__name">{{ getUserCommercialInfo?.companyName }}</p>
            </div>
        </div>
        <div class="navbar-container-commercial__user-login-section__second">
            <p class="navbar-container-commercial__user-login-section__second__member-title">{{ $t('userCommercialMemberNo') }} :</p>
            <p class="navbar-container-commercial__user-login-section__second__member-number">{{ getUserCommercialInfo?.userId }} <i @click="setCopy($event,getUserCommercialInfo?.userId)" class="bx bx-copy"></i></p>
        </div>
        <div class="navbar-container-commercial__user-login-section__third">
            <div class="navbar-container-commercial__user-login-section__third__balance">
                <p class="navbar-container-commercial__user-login-section__third__balance__title"> <span>{{ $t('userBalance') }} </span> <i class="bx bx-money"></i> </p>
                <p class="navbar-container-commercial__user-login-section__third__balance__amount">{{ (getUserCommercialInfo?.balance || '0').toFixed(2) }} {{ getUserCommercialInfo?.currency?.currency || '' }}</p>
            </div>
            <div class="navbar-container-commercial__user-login-section__third__withdraw">
                <button class="navbar-container-commercial__user-login-section__third__withdraw__btn" @click="setCurrentPage('LoadBalance')"> <span>{{ $t('userLoadBalance') }}</span> <i class="bx bx-right-arrow"></i></button>
            </div>
        </div>
      </div>
      <div class="navbar-container-commercial__user-third-section">
        <div class="navbar-container-commercial__user-third-section__my_ads">
            <button @click="setCurrentPage('MyAdsList')" :class="{'active-page-btn': getSelectedCommercialSection === 'MyAdsList'}" class="navbar-container-commercial__user-third-section__my_ads__btn"> <span>{{ $t('userMyAds') }}</span> <i class="bx bx-list-ul"></i></button>
        </div>
        <div class="navbar-container-commercial__user-third-section__publish_add">
            <button @click="setCurrentPage('PublishAds')" :class="{'active-page-btn': getSelectedCommercialSection === 'PublishAds'}" class="navbar-container-commercial__user-third-section__publish_add__btn"> <span>{{ $t('userPublishAds') }}</span> <i class="bx bx-plus"></i></button>
        </div>

        <div class="navbar-container-commercial__user-third-section__analytics_ads">
            <button @click="setCurrentPage('AnalyticsAds')" :class="{'active-page-btn': getSelectedCommercialSection === 'AnalyticsAds'}" class="navbar-container-commercial__user-third-section__analytics_ads__btn"> <span>{{ $t('userAnalytics') }}</span> <i class="bx bx-line-chart"></i></button>
        </div>

          <div class="navbar-container-commercial__user-third-section__others">
              <button @click="setCurrentPage('Settings')" class="navbar-container-commercial__user-third-section__others__btn"><i class="bx bx-cog"></i></button>
              <button class="navbar-container-commercial__user-third-section__others__btn"> <i class="bx bx-bell"></i></button>
              <button class="navbar-container-commercial__user-third-section__others__btn logout"  @click="logout" ><i class="bx bx-log-out"></i></button>

          </div>

          <div class="navbar-container-commercial__user-third-section__select-language">
              <n-popselect v-model:value="selectedLanguage" :options="languages">
                  <div class="navbar-container-commercial__user-third-section__select-language__btn">
                      <img :src="require(`@/assets/flags/${selectedLanguage}.png`)" alt="language">
                      <!-- <span>{{ $t(selectedLanguage) }}</span> -->
                  </div>
    </n-popselect>
          </div>
      </div>
    </div>

    </nav>

  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import ModalContainer from '@/utils/ModalContainer.vue';
  export default {
  
      components: {
          ModalContainer,
      },
      data() {
          return {
              selectedLanguage: 'tr',
              languages: [
                  {
                      label: this.$t('tr'),
                      value: 'tr'
                  },
                  {
                      label: this.$t('en'),
                      value: 'en'
                  },
  
  
              ],
              userEntryInfo: '',
              passwordEntryInfo: '',
              isRegesterModalOpen: false
          }
      },
  
      computed: {
      ...mapGetters({getUserCommercialInfo:'commercialUserModule/getUserCommercialInfo'}),
      ...mapGetters(['getSelectedLanguage','getSelectedCommercialSection','getScreenType']),

    },
  
    created(){
      this.selectedLanguage = this.getSelectedLanguage
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        setCurrentPage(page) {
            this.$store.commit('setSelectedCommercialSection', page)
        },
        setCopy(event,text) {
            this.$copyText(text).then(() => {

                const div = document.createElement('div');
                div.style.position = 'absolute';
                div.style.top = '-.9rem';
                div.style.right = '-.5rem';
                div.style.fontSize = '.65rem';
                div.innerText = this.$t('copied');
                event.target.appendChild(div);
                setTimeout(() => {
                    event.target.removeChild(div);
                   
                }, 2000);
            })
        }
    },
  
      watch: {
          selectedLanguage() {
              this.$store.commit('setSelectedLanguage', this.selectedLanguage)
          }
      },
  
  }
  </script>
  
  <style scoped lang="scss">
  .active-page-btn {
    background: #fff !important;
    border: 1px solid #6eb4ff !important;
    font-weight: 800 !important;
    // color: #28425d  !important;

    // & i {
    //     color: #28425d!important;
    // }
  }
  .navbar-container-commercial {
    display: flex;
      width: 100%;
      height: 5rem;
    //   padding: 0 6%;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: center;
      background: #FFCC01;
      box-sizing: border-box;
      overflow: hidden;
      @media screen and (max-width: 600px) {
            height: max-content;
            padding: 1rem 0;
            justify-content: flex-start;


          }


      & .navbar-sub-container-commercial {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          width: 82%;
          height: 100%;

          @media screen and (min-width: 1620px) {
            width: 70rem;
        }

        @media screen and (max-width: 768px ) {

            width:90%;
           
          }

          @media screen and (max-width: 600px ) {
            justify-content: space-between;
            margin: auto;
            width:80%;
           
          }

          @media screen and (max-width: 550px) {

            width:90%;
           
          }

          @media screen and (max-width: 500px) {

width:94%;

}

          @media screen and (max-width: 420px) {
            justify-content: flex-start;
            gap: 2rem;
        }

    
      }
  
      &__app-logo-container {
        display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 6%;
          &__app-logo-img {
              width: 4rem;
              height: 4rem;
              object-fit: scale-down;
              transform: scale(1.2);
          }
          @media screen and (max-width: 600px) {
            width: max-content;
            &__app-logo-img {
                width: 6rem;
              height: 6rem;
            }              
            
          }

          @media screen and (max-width: 450px) {
            width: max-content;
            &__app-logo-img {
                width: 5rem;
              height: 5rem;
            }              
            
          }

          @media screen and (max-width: 420px) {
                width: max-content;
            &__app-logo-img {
                width: 4rem;
              height: 4rem;
            }

                
            }
      }
  
      &__user-login-section {
        display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          gap: 1.5rem;
          
          width: 44%;
          margin:0;
          padding:.5rem 0;
          padding-left: 1rem;
          padding-right: 1rem;
          background: #FEE98D;
          border-radius: 8px;
          box-sizing: border-box;

          @media screen and (max-width: 600px) {
              width: max-content;
              gap: 2.5rem;
              padding-left: 2rem;
          padding-right: 2rem;
          border-radius: 4px;
          padding: 1rem 2rem;
            
          }
          @media screen and (max-width: 500px) {
              width: max-content;
              gap: 1.5rem;
              padding-left: 1.5rem;
          padding-right: 1.5rem;
          border-radius: 4px;
          padding: 1rem;
            
          }

          @media screen and (max-width: 420px) {
            width: max-content;
              gap: 1.5rem;
              padding-left: 1rem;
          padding-right: 1rem;
          border-radius: 4px;
          padding: .5rem 1rem;

                
            }



          & p {
              margin: 0;
              padding: 0;
          }

          &__first {
              display: flex;
              flex-flow: row;
              align-items: center;
              justify-content: center;
              gap: .5rem;

              @media screen and (max-width: 600px) {
                    gap: 1rem;
              
          }
          @media screen and (max-width: 420px) {
                    gap: .5rem;

                
            }


              &__user-icon{
                display: flex;
                align-items: center;
                justify-content: center;
                background: #D8E6E8;
                border-radius: 6px;
                padding: .3rem;


                @media screen and (max-width: 600px) {
                    padding: .6rem;
                    border-radius: 4px;
              
          }

          @media screen and (max-width: 420px) {
                    padding: .3rem;

                
            }


            & i{
                font-size: 1.5rem;
                color: #8BB3BE;


                @media screen and (max-width: 600px) {
                    font-size: 2rem;
              
          }
          @media screen and (max-width: 420px) {
                    font-size: 1.5rem;

                
            }
            }
              }

              &__user-info{
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                justify-content: center;
                color: #313131af;
                font-weight: 550;
                font-size: .65rem;
                text-transform: capitalize;

                @media screen and (max-width: 600px) {
                    font-size: 1rem;
              
          }

          @media screen and (max-width: 420px) {
                    font-size: .65rem;

                
            }


            &__name{
                
            }

            &__surname{

              }
      
            }

          }

          &__second {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content:center;
            gap: .2rem;
            color: #313131;
            font-weight: 600;
            font-size: .65rem;


            @media screen and (max-width: 600px) {
                    font-size: 1rem !important;
                    gap:1rem !important;
              
          }

          @media screen and (max-width: 420px) {
                    font-size: .65rem !important;
                    gap:.5rem !important;
                
            }

            &__member-title {

            }

            &__member-number {
                display: flex;
                align-items: center;
                justify-content:flex-start;
                gap: .5rem;
                color: #313131e2;

                & i {
                    position: relative;
                    font-size: 1rem;
                    color: #313131;
                    margin-bottom: .2rem;

                @media screen and (max-width: 600px) {
                    font-size: 1.5rem !important;
              
          }

          @media screen and (max-width: 420px) {
                    font-size: 1rem !important;
                
            }
                }
            }
          }

          &__third {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content:flex-start;
            gap: 1.5rem;
            margin: 0 auto;
            width: max-content;

            &__withdraw {
                display: flex;
                align-items: center;
                justify-content:flex-start;
                gap: .5rem;


                @media screen and (max-width: 600px) {
                    gap: 1rem;
              
          }

          @media screen and (max-width: 420px) {
                    gap: .5rem;
                
            }

                & button {
                    display: flex;
                align-items: center;
                justify-content: center;
                width: max-content;
                height: max-content;
                border: none;
                outline: none;
                border-radius: 4px;
                background-color: #000;
                color: #FFCC01;
                font-size: .6rem;
                font-weight: 650;
                padding: .4rem .6rem;
                gap: .2rem;


                @media screen and (max-width: 600px) {
                    font-size: 1rem;
                    padding: .6rem 1rem;
                    border-radius: 4px;
                    gap: .4rem;
              
          }

          @media screen and (max-width: 420px) {
                    font-size: .6rem;
                    padding: .4rem .6rem;
                    border-radius: 4px;
                    gap: .2rem;
                
            }

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }


                & .bxs-right-arrow {
                    margin-top: .1rem;
                }
                }

          }

        &__balance {

            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            gap: .3rem;


            @media screen and (max-width: 600px) {
                    gap: .6rem;

                    &__title {
                        font-size: 1rem !important;
                        padding: .4rem 1.6rem !important;
                        gap:.4rem !important;

                        & i {
                            font-size: 1.2rem !important;
                            color: #384c51;
                        }
                    }

                    &__amount {
                        font-size: 1.2rem !important;
                    

                    }
              
          }

          @media screen and (max-width: 420px) {
                    gap: .3rem;

                    &__title {
                        font-size: .65rem !important;
                        padding: .2rem 1rem !important;
                        gap:.2rem !important;

                        & i {
                            font-size: .7rem !important;
                            color: #384c51;
                        }
                    }

                    &__amount {
                        font-size: .7rem !important;
                    }
                
            }
            &__title {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .2rem;
                font-size: .65rem;
                font-weight: 550;
                color: #2a2a2ad7;
                border-bottom: 2px dashed #452149bf;
                padding: .2rem 1rem;


                & i {
                    font-size: .7rem;
                    font-weight: 750;
                }
            }

            &__amount {
                font-size: .7rem;
                font-weight: 750;
                color: #2a2a2ad7;
            }
        }

          }


      }
      &__user-third-section {
        display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          gap:1rem;

          width: 45%;
          margin: 0;

          border-radius: 8px;
          padding:.8rem 0;
        
          box-sizing: border-box;

          @media screen and (max-width: 600px) {
              width: 100%;
              margin-top: .5rem;
              justify-content: space-between;


          }

          @media screen and (max-width: 420px) {
              width: 100%;
              margin-top: .5rem;
              justify-content: flex-start;
              border-radius: 4px;
              padding:.5rem 0;
              gap: 1rem;
            
          }



          &__settings, &__notifications, &__publish_add, &__my_ads, &__analytics_ads {
              &__btn {
                width: max-content;
                  height: max-content;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: .4rem .8rem;
                  border: none;
                  outline: none;
                  background: #ffffff;
                  color: #384c51;
                  font-size: .6rem;
                  font-weight: 600;
                  border-radius: 6px;
                  gap: .3rem;
                  @media screen and (max-width: 600px) {
             font-size: 1rem;
             padding: .9rem 1.5rem;
             gap: .5rem;

             & i {
                    font-size: 1.2rem !important;
                    color: #384c51;
                }


          }

          @media screen and (max-width: 420px) {
                    font-size: .6rem;
                    padding: .4rem .8rem;
                    gap: .3rem;
                
            }
                  &:hover {
                      cursor: pointer;
                      opacity: .8;
                  }
                  & i {
                    font-size: .9rem;
                    color: #384c51;
                }
              }
          }

            &__others {
                border-radius: 6px;
                 padding:.35rem .6rem;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-start;
                gap: .1rem;

                background: #ffffff;

                @media screen and (max-width: 600px) {
             font-size: 1rem;
             padding: .9rem 1.5rem;
             gap: .5rem;

             &__btn {
                font-size: 1rem;
          
             } 

    
             
             & i {
                    font-size: 1.2rem !important;
                }




          }

          @media screen and (max-width: 420px) {
                    font-size: .6rem;
                    padding: .4rem .8rem;
                    gap: .3rem;

                    &__btn {
                        font-size: .6rem;
                    }

                    & i {
                        font-size: .9rem;
                    }
                
            }

                &__btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: max-content;
                    height: max-content;
                    border:0;
                    outline: none;
                    color: #384c51;
                    background: #ffffff;
                    font-size: .6rem;
                    font-weight: 600;
                    gap: .3rem;

                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }
                    & i {
                        font-size: .8rem;
                        color: #384c51;
                    }
                }

                & .logout {
                    & i {
                        font-size: .9rem !important;
                        color: #526c73 !important;

                        @media screen and (max-width: 600px) {
                            font-size: 1.2rem !important;
                        }

                        @media screen and (max-width: 420px) {
                            font-size: .9rem !important;
                        }
                    }
        
                }

                
            }

          &__select-language {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: .5rem;
              padding-top: .2rem;

              @media screen and (max-width: 600px) {
                    
                &__btn {
                    font-size: 1rem;

                }

                & img {
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                }

              }

              @media screen and (max-width: 420px) {
                &__btn {
                    font-size: .6rem;

                }

                & img {
                    width: 1rem !important;
                    height: 1rem !important;
                }
                
            }
              
              &__btn {
                  display: flex;
                  flex-flow: row nowrap;
                  align-items: center;
                  justify-content: flex-start;
                  width: max-content;
                  height: max-content;
                  border: none;
                  outline: none;
                  border-radius: 0;
                  color: #000000;
                  border-radius: 1px solid #D9D9D9;
                  font-size: .7rem;
                  font-weight: 550;
                  padding: 0;
                  gap: .3rem;
                  padding:0 .6rem .2rem .6rem;
                  // border-bottom: 1px solid #000000;
  
  
                  & img {
                      width: 1rem;
                      height: 1rem;
                  }
              }
          }
      }
  
  }
  </style>