import axios from 'axios';
import tools from '@/tools';
import store from '@/store/index';
// import sendErrorToSlack from "../constants/app/slackErrorhandler"

const prod = 'https://felixleap.lm.r.appspot.com/api/v0.0.1/';
const test = 'https://felixleap.lm.r.appspot.com/api/v0.0.1/';
const local = 'http://localhost:4001/api/v0.0.1/';
// Retry mekanizması için gerekli ayarlar
const maxRetries = 3;  // Maksimum retry sayısı
let retryCount = 0;    // Başlangıçta retry sayısı

const instance = axios.create({
  baseURL: prod,
  timeout: 60000,  // 60 saniye timeout
});

// İstek interceptor'ı
instance.interceptors.request.use((config) => {
  const token = tools.cookie.get('authToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Yanıt interceptor'ı
instance.interceptors.response.use(res => res.data, async (err) => {
  const statusCode = err.response ? err.response.status : null;
  if (retryCount < maxRetries && statusCode !== 401) {
    retryCount += 1;  // Retry sayısını artır
    return instance(err.config);  // İsteği tekrar gönder
  }

  // Slack'e hata mesajı gönder
//   const errorMessage = `HTTP Error: ${err.config.url} - Status ${statusCode}`;
//   sendErrorToSlack(errorMessage);

  // 401 hatası durumunda kullanıcıyı logout yap
  if (statusCode === 401) {
    await store.dispatch("logout");
  }

  // Son hatayı fırlat
  throw err;
});

export default () => instance;
