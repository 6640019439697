<template>
  <div class="my-ads-container">
    <div class="my-ads-container__header">
      <h3 class="my-ads-container__header__title">{{ $t('myAds') }}</h3>
    </div>
    <div class="my-ads-container__list-ads" v-if="ads.length">
        <!-- <div class="my-ads-container__list-ads__item-title">
            <h4 class="my-ads-container__list-ads__item-title__title" v-for="title in titlesAds" :key="title">
                {{ $t(title) }}
            </h4>
        </div>
        <div class="my-ads-container__list-ads__item">
            <p class="my-ads-container__list-ads__item__text" v-for="ad in ads" :key="ad?.id">
                {{ ad }}
            </p>
        </div> -->

        <table class="my-ads-container__list-ads__table">

            <thead>
                <tr class="my-ads-container__list-ads__table__tr">
                    <th v-for="title in titlesAds" :key="title" class="my-ads-container__list-ads__table__tr__th">
                        <p>{{ $t(title?.lang_ref_key) }}</p>

                    </th>
                    <th class="my-ads-container__list-ads__table__tr__th">
                        <p>{{ $t('more') }}</p>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr class="my-ads-container__list-ads__table__tr" v-for="(ad,index) in ads" :key="ad?._id" :class="{'even-tr': index % 2 === 0}">
                    <td v-for="(title) in titlesAds" :key="title"  class="my-ads-container__list-ads__table__tr__td">
                        <p v-if="title?.lang_ref_key === 'approval'">{{ $t(ad[title?.id]) }}</p>
                        <div v-else-if="title?.lang_ref_key === 'banner'" class="my-ads-container__list-ads__table__tr__td__image">
                            <img :src="ad[title?.id].bucketLocation" alt="ad.image">
                        </div>
                        <ShowShorterTextView v-else-if="typeof ad[title?.id] === 'string' && ad[title?.id].length > 30 "  :text="ad[title?.id]" />
                        <p v-else>{{ ad[title?.id] }}</p>
                    </td>
                    <td class="my-ads-container__list-ads__table__tr__td">
                        <i class="bx bx-dots-vertical"></i>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
  </div>
</template>

<script>
import getCommercialAdsListTitles from '@/assets/commercial/ads/getCommercialAdsListTitles'
export default {
    data() {
        return {
            titlesAds:getCommercialAdsListTitles,
            ads:[],
            filter:{
                statusType:[],
                pagination:1,
                lang:'en'
            },
            firstSectionVisibleFields:[
                'title',
                'description',
                'image',
                'categories',
                'statusApproval',
                'viewsCount',
                'reachedViewsCount'
            ]
        }
    },

    methods: {
        async  setAdImageLink(image){
        if(image){
            try {
               const result = await this.$store.dispatch('getAdImageLink', image)
               console.log(result)
               if(result?.status_code == 200){
                   return result?.data?.url || null
               }
    
            } catch (error) {
                console.log(error)
                return '/img/app_logos/dark_app_logo.png'
            }
 
        }
        return '/img/app_logos/dark_app_logo.png'

    },

    async  setAdVideoLink(video){
        if(video){
            try {
               const result = await this.$store.dispatch('getAdVideoLink', video)
               console.log(result)
               if(result?.status_code == 200){
                   return result?.data?.url || null
               }
    
            } catch (error) {
                console.log(error)
                return '/img/app_logos/dark_app_logo.png'
            }
 
        }
        return '/img/app_logos/dark_app_logo.png'

    },

    getModifiedDate(date){
            return moment(date).format('DD/MM/YYYY HH:mm');
        },
        

    async getAds() {
        try {
            this.filter['lang'] = this.$store.getters.getSelectedLanguage;
            const ads = await this.$store.dispatch('commercialUserModule/getMyCommercialAds',this.filter);
            for(const ad of ads){
                const banner  = ad?.image?.bucketLocation || ad?.videoBanner?.bucketLocation || null;
                const video = ad?.video?.bucketLocation || null;
                if(banner ){
                    ad.image.bucketLocation = await this.setAdImageLink(banner)
                }
                else {
                    ad.image.bucketLocation = '/img/app_logos/dark_app_logo.png'
                }

                if(video){
                    ad.video.bucketLocation = await this.setAdVideoLink(video)
                }
                
              }
              const adsFields = Object.keys(ads.length ? ads[0] : {});
              this.titlesAds = this.titlesAds.filter(title => adsFields.includes(title?.id) && this.firstSectionVisibleFields.includes(title?.id));
              this.ads = ads;
        } catch (error) {   
            console.log(error);
            this.ads = [];
            this.titlesAds = [];
        }
    }
    },

    async created() {
        await this.getAds()
    }
}
</script>

<style scoped lang="scss">

    .even-tr {
        background: #F9FAFB !important;
    }
    .my-ads-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        gap: 1rem;

        & p,
        h3 {
            margin: 0;
        }

        &__header {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            border-bottom: 2px solid #dddddd6f;
            padding: .5rem 0;
        }

        &__list-ads {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1rem;

            &__table {

            width: 100%;
            table-layout:auto;
            word-wrap: break-word;
            border-collapse: collapse;

            &__tr__td__image {
                  width: max-content;
                  height: max-content;
                  margin: auto;
                  

                  img {
                      width: 2rem;
                      height: 2rem;
                      object-fit: contain;
                      border-radius: 4px;
                     
                  }
              }


        tr th {
            font-size: .7rem;
            color: #4f5c73 !important;
            text-transform: capitalize;
            border-radius:0;
            padding-bottom: .8rem !important;


        }


        tr td {
            text-align: center !important;
            font-size: .65rem;
            height: auto;
            padding: .6rem .3rem ;
            text-align: center;
            color: #424d63 !important;
        }

        & i {
            font-size: 1rem;
            font-weight: 550;
            color: #4f5c73 !important;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }

       tbody tr {
            width: 100%;

        }


                
            }
        }
    }
</style>