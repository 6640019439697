
import registerUserServices from '@/services/registerUserServices';
import individualUserServices from '@/services/individualUserServices';



const individualUserStoreModule = {
    namespaced: true,
    state: {
      adsIndividual: {
        activeAds: [],
        pendingAds: [],
        
      },
      latestLuckmeResult: {
        isWin:false,
        earnedAmount:0,
        currency:"",
        isLoss:0,
        updatedDate:Date.now(),

      },

      filterCategories: [],
        userIndividualInfo: {
            userId:'',
            name: '',
            surname: '',
            email: '',
            phone: '',
            country: {},
            city: {},
            IBAN: '',
            ipAddress: '',
            role: 'individual',
            birthdate: '',
            currency: '',
            countryCode: '',
            gender: 'all',
            age:18,
            dailyWatchedAds: [],
            currentBalance: {},
            remainingDailyAdWatchCount:100,
            balance:0,
            luckmeGameDetails:{},
            lastLoginDate:Date.now(),
      
      
          },
    },
    mutations: {
        setIndividualUserInfo(state, userInfo) {
            state.userIndividualInfo = userInfo;
          },
          setLuckmeResult(state, result) {
            state.userIndividualInfo.luckmeGameDetails = result?.luckmeGameDetails || {};
            state.userIndividualInfo.balance = result?.currentBalance?.balance || 0;
            state.userIndividualInfo.currentBalance = result?.currentBalance || {};

          },

        setInitialAds(state, ads) {
            state.adsIndividual.activeAds = ads;
          },

          setLuckmeLatestResult(state, result) {
            state.latestLuckmeResult = result;
          },



        setPendingAds(state, ads) {
            state.adsIndividual.pendingAds = ads;
          },
          updateInitialAdFromPendingAds(state) {
            const firstAdOfPendingAds = state.adsIndividual.pendingAds.shift() || null;
            if(firstAdOfPendingAds){
              state.adsIndividual.pendingAds = state.adsIndividual.pendingAds.filter(ad => ad?._id !== firstAdOfPendingAds?._id);

              state.adsIndividual.activeAds.push({...firstAdOfPendingAds});
            }
          },

          transformAdToPendingAdFromInitialAd(state,ad) {

              
              const findDailyWatchedAd = state.userIndividualInfo.dailyWatchedAds.find((item) => item._id == ad._id);

              if(!findDailyWatchedAd){
                state.adsIndividual.pendingAds.push({...ad});
              }
             
              else if(findDailyWatchedAd.reachedCount < findDailyWatchedAd.perUserViewCount){
                  state.adsIndividual.pendingAds.push({...ad });
                
              }

              state.adsIndividual.activeAds = state.adsIndividual.activeAds.filter(adData=>{
                return adData?._id !== ad?._id
              })
      
          },

        setFilterCategories(state, categories) {
            state.filterCategories = categories;
          },

          updateDailyWatchedAds(state,ad) {

            if(state.userIndividualInfo.remainingDailyAdWatchCount > 0){
              state.userIndividualInfo.remainingDailyAdWatchCount -= 1;
            }
            if(!state?.userIndividualInfo?.dailyWatchedAds){
              state.userIndividualInfo['dailyWatchedAds'] = [];
            }
              const findAd = state.userIndividualInfo.dailyWatchedAds.find((item) => item._id == ad._id);
              if(!findAd){
                state.userIndividualInfo.dailyWatchedAds.push({
                  _id:ad._id,
                  perUserViewCount:ad.perUserViewCount,
                  reachedCount:1
                });
              }
              else {
                if(findAd.reachedCount < findAd.perUserViewCount){
                  findAd.reachedCount += 1;
                }

              }
            
          },

        updateIndividualUserBalance(state, balance) {
            state.userIndividualInfo.balance = balance;
          },
          incrementLuckmeCount(state) {
            state.userIndividualInfo.luckmeGameDetails.remainingAttempts = state.userIndividualInfo.luckmeGameDetails.remainingAttempts + 1;
          },

          descrimentLuckmeCount(state) {
            if(state.userIndividualInfo.luckmeGameDetails.remainingAttempts > 0){
              state.userIndividualInfo.luckmeGameDetails.remainingAttempts = state.userIndividualInfo.luckmeGameDetails.remainingAttempts - 1;
            }
          }


    },
    actions: {

      async getRemaningSecondWatchAdsRightReset(ctx,userId) {
        try {
          const result = await individualUserServices.fetchRemaningSecondWatchAdsRightReset(userId);
          if(result?.status_code == 200){
            return result?.data?.remainingSeconds || 0;
          }
          return 0;
        } catch (error) {
          console.log(error);
          return error;
        }
      },
      async setLuckmeGameAttempResult({ commit },payload) {
        try {
          const result = await individualUserServices.setLuckmeGameAttempResult(payload);
          if(result?.status_code == 200){
              commit('setLuckmeResult', result?.data?.user || {});

          
          }
          return result;
        } catch (error) {
          console.log(error);
          return error;
        }
      },
      async setAdWatced({ commit },adWatchedMetaData) {
        try {
          const result = await individualUserServices.setAdWatched(adWatchedMetaData);
          if(result?.status_code == 200){
            commit('incrementLuckmeCount');
          }
          return result;
        } catch (error) {
          console.log(error);
          return error;
        }
      },
      async getUpdatedBalance({ commit },userInfo) {
        try {
          const result = await individualUserServices.getUpdateBalance(userInfo);
          if(result?.status_code == 200){
            commit('updateIndividualUserBalance', result?.data?.balance || 0);
            return true;
          }
          return false;
        } catch (error) {
          console.log(error);
          return error;
        }
      },

      async getFilterCategories({ commit },filter) {
        try {
          const result = await individualUserServices.getFilterCategories(filter);
          if(result?.status_code == 200){
            commit('setFilterCategories', result?.data || []);
          }
          else {
            throw new Error(result?.message);
          }
        } catch (error) {
          console.log(error);
        }
      },
        async setIndividualUserRegister ({ commit,dispatch }, registerUserInfo ) {
            try {
              const response = await registerUserServices.registerIndividualUser(registerUserInfo);
      
              const userInfo = response?.data?.userInfo || null;
              const logCode = '10001'
              const token = response?.token || null;
              if(userInfo && token && response?.status_code == 201){
                commit('setIndividualUserInfo', userInfo);
                commit('setUserRole', userInfo.role,{ root: true });
                commit('setToken', token,{ root: true });
                commit('setIsLogin', true,{ root: true });

                dispatch('setUserLogs',logCode,{ root: true });

                return userInfo;
              }
              return false;
            } catch (error) {
              console.error(error);
              return error;
              
            }
          },

          async getUserIndividualInitialAds({ state,commit },{userInfo,userWatchedAds=[],filter,lang = 'en'}) {
            try {
              const result = await individualUserServices.getInitialAds(userInfo,filter,lang,userWatchedAds);
              if(result?.status_code == 200){
                commit('setInitialAds', result?.data || []);
              }
              return result?.data || [];
            } catch (error) {
              console.log(error);
              return error;
            }
          },
          async getUserIndividualPendingAds({ commit },{filter,userWatchedAds =[],activeAdIds,userInfo,lang = 'en'}) {
            try {

              const result = await individualUserServices.getPendingAds(userInfo,filter,activeAdIds,lang,userWatchedAds);

              if(result?.status_code == 200){
                console.log('result',result)
                commit('setPendingAds', result?.data || []);
              }
             
            } catch (error) {
              console.log(error);
              
            }
          },
    },
    getters: {
        getUserIndividualInfo: (state) => state.userIndividualInfo,
        getInitialAds: (state) => state.adsIndividual.activeAds,
        getPendingAds: (state) => state.adsIndividual.pendingAds,
        getUserBalance: (state) => state.userIndividualInfo.balance,
        getFilterCategories: (state) => state.filterCategories,
        getDailyWatchedAds: (state) => state.userIndividualInfo?.dailyWatchedAds || [],
        getLatestLuckmeResult: (state) => state.latestLuckmeResult || {},

    }
}
export default individualUserStoreModule