<template>
  <div class="register-user-container">

    <div class="exit"><i class="fa-solid fa-xmark" @click="$emit('closeModal')"></i></div>
    <div class="register-user-container__user-types">
        <div class="register-user-container__user-types__type" :class="{active: selectedRole === 'individual'}" @click="setRole('individual')">{{ $t('viewer') }}</div>
        <img class="register-user-container___user-types__img" src="/img/app_logos/dark_app_logo.png" alt="dark app logo">
        <div class="register-user-container__user-types__type-commercial" :class="{active: selectedRole === 'commercial'}" @click="setRole('commercial')">{{ $t('publisher') }}</div>
    </div>

    <div v-if="selectedRole === 'individual'" class="register-user-container__name-surname">
        <input type="text" v-model="name" :placeholder="$t('userRegisterFormName') + '*'" class="register-user-container__name-surname__input">
        <input type="text"  v-model="surname" :placeholder="$t('userRegisterFormSurname') + '*'" class="register-user-container__name-surname__input">
    </div>
    <div v-else-if="selectedRole === 'commercial'" class="register-user-container__company-name">
        <input type="text" v-model="companyName" :placeholder="$t('userRegisterFormPublisherName') + '*'" class="register-user-container__company-name__input">
    </div>
    <div class="register-user-container__email">
        <input type="email" v-model="email" :placeholder="$t('userRegisterFormEmail') + '*'" class="register-user-container__email__input">
        <i  class="fa-solid fa-envelope"></i>

        <div v-if="isValidEmail === false" class="error-message">
      {{ $t('userRegisterFormEmailErrorMessage') }} 
    </div>
    </div>
    <div class="register-user-container__password">
        <div class="register-user-container__password__form-container">
            <input type="password" v-model="password" :placeholder="$t('userRegisterFormPassword') + '*'" class="register-user-container__password__form-container__input">
            <div v-if="isValidPassword === false" class="error-message">
      {{ $t('userRegisterFormPasswordErrorMessage') }} 
            </div>

        </div>
        <div class="register-user-container__password__form-container" >
            <input type="password" v-model="confirmPassword" :placeholder="$t('userRegisterFormConfirmPassword') + '*'" class="register-user-container__password__form-container__confirm-password-input">
            <div v-if="isValidConfirmPassword === false" class="error-message">
      {{ $t('userRegisterFormConfirmPasswordErrorMessage') }} 
            </div>
        </div>

    </div>

    <div class="register-user-container__country">
        <select name="country-select"  v-model="selectedCountry">
            <option :value="{}" disabled  selected>{{ $t('userRegisterFormCountry') + ' *' }} </option>
            <option v-for="country in countryList" :key="country.country" :value="country">{{ country.names[getSelectedLanguage] }}</option>
        </select>

        <select name="city-select" :disabled="isCitiesDisabled"  v-model="selectedCity">
            <option disabled :value="{}" selected>{{ $t('userRegisterFormCity') }}</option>
            <option v-for="city in getCities" :key="city?.name" :value="city">{{ city?.name }}</option>

        </select>
    </div>

    <div class="register-user-container__phone-currency">

        <div class="register-user-container__phone">
            <input type="tel" v-model="phone" :placeholder="$t('userRegisterFormPhone') + ' *'" class="register-user-container__phone__input">
            <img :src="`/flag_icons/3x2/${getSelectedCountryFlag}.svg`" alt="country flag" v-if="getSelectedCountryFlag">
            <i v-else class="fa-solid fa-phone"></i>
            <div v-if="isValidPhone === false" class="error-message">
      {{ $t('userRegisterFormPhoneErrorMessage') }} 
    </div>
        </div>
        <select name="gender-select"  v-model="gender">
            <option disabled :value="''" selected>{{ $t('userRegisterGender') + ' *'}}</option>
            <option v-for="gender in genders" :key="gender.value" :value="gender.value">{{ gender.label }}</option>

        </select>
        <!-- <select name="currency-select" v-model="currency">
            <option disabled selected :value="{}">{{ $t('userRegisterFormCurrency') + ' *' }} </option>
            <option v-for="currency in currencyList" :key="currency" :value="currency">{{ currency.currency }}</option>
            <div v-if="isValidCurrency === false" class="error-message">
      {{ $t('userRegisterFormCurrencyErrorMessage') }} 
    </div>
        </select> -->
    </div>
    <!-- <div class="register-user-container__IBAN" v-if="selectedRole === 'individual'">
        <input type="text" v-model="IBAN" :placeholder="$t('userRegisterFormIBAN') + ' ' + $t('optional')" class="register-user-container__IBAN__input">
    </div> -->

    <div class="register-user-container__birthdate-register-btn">
        <!-- <fieldset class="date-fieldset">
        <legend class="date-legend">{{ $t('userRegisterFormBirthdate') + ' *' }}</legend>
    </fieldset> -->
    <div class="form-container" v-if="selectedRole === 'individual'">
        <label class="label-date" for="date">{{ $t('userRegisterFormBirthdate') + ' *' }}</label>
        <input  type="date" v-model="birthdate" :placeholder="$t('userRegisterFormBirthdate') + ' *'" class="date-input">


    </div>

        <button :class="{disabled:!isRegisterButtonAvailable}" @click="registerUser" class="register-user-container__register-btn__btn">{{ $t('userRegisterFormSubmit') }} <i class='bx bxs-right-arrow'></i></button>
    </div>


  </div>

  <LoadingSpinner v-if="isFetchingRegister" />
</template>

<script>
import currencyList from '@/assets/currency/currencyList.json'
import countryList from '@/assets/countries/countryList.json'
import countryDetail from '@/assets/countries/countriesDetails.json'
import { mapGetters } from 'vuex'
import {debounce,throttle} from 'lodash'

export default {

    data(){
        return {
            isFetchingRegister:false,
            userRoles: [
                {
                    label: this.$t('userRegisterRegularRole'),
                    value: 'user'
                },
                {
                    label: this.$t('userRegisterCommercialRole'),
                    value: 'commercial'
                }
            ],

            currencyList: currencyList,
            countryList: countryList,
            countryDetail: countryDetail,
            genders:[
               {
                   label: this.$t('male'),
                   value: 'male'
               },
               {
                   label: this.$t('female'),
                   value: 'female'
               },
               {
                   label: this.$t('other'),
                   value: 'all'
               }

            ],
            selectedRole: 'individual',
            gender:'',
            name: '',
            surname: '',
            companyName: '',
            email: '',
            password: '',
            confirmPassword: '',
            phone: '',
            selectedCountry: {},
            selectedCity: {},
            IBAN: '',
            currency: {},
            birthdate: '',
            countryCode: '',
            isValidPhone:null,
            isValidEmail:null,
            isValidPassword:null,
            isValidCurrency:null,
            isValidConfirmPassword:null,

            individualdUserInfo:{
                role: 'individual',
                name: '',
                surname: '',
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                country: {},
                city: {},
                countryCode: '',
                currency: {},
                IBAN: '',
                birthdate: '',
            },
            commercialUserInfo:{
                role: 'commercial',
                companyName: '',
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                country: {},
                city: {},
                countryCode: '',
                currency: {},

            },
            isRegesterModalOpen: false
        }
    },



    computed: {
        ...mapGetters(['getSelectedLanguage']),

        getCities(){
            if(!Object.keys(this.selectedCountry).length) return ['No City'];
            const country = this.countryDetail[(this.selectedCountry['alpha-2']|| '').toUpperCase()]
            const cities = country?.provinces ? country.provinces : [{name:country?.capital || 'No City'}];

            return cities
        },
        getSelectedCountryFlag(){
            if(!Object.keys(this.selectedCountry).length) return '';
            const country = this.selectedCountry['alpha-2']|| 'TR';

            return country.toUpperCase()
        },

        isCitiesDisabled(){
            let country = this.selectedRole === 'individual' ? this.individualdUserInfo.country : this.commercialUserInfo.country;
            let isCountrySelected = Object.keys(country).length ? true : false
            return !isCountrySelected
        },

        isRegisterButtonAvailable(){

            if(this.selectedRole === 'individual'){
                return this.validatePhone('check') && this.validateEmail('check') && this.validatePassword('check') && this.validateConfirmPassword() && Object.keys(this.individualdUserInfo.country).length && this.name && this.surname && this.birthdate && this.gender
            }
            else if(this.selectedRole === 'commercial'){
                return this.validatePhone('check') && this.validateEmail('check') && this.validatePassword('check') && this.validateConfirmPassword() && Object.keys(this.commercialUserInfo.country).length && this.companyName  && this.gender
            }
            return false
        }
    },

    methods: {


        getUserInAccordingToRoleType(roleType) {
        const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
        const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
        if (roleType === indRoleType) {
            this.$router.push({ name: 'user-individual' })
        }

        if (roleType === comRoleType) {
            this.$router.push({ name: 'user-commercial' })
        }

    },

         registerUser:throttle(async function(){
            const indRoleType = process.env.VUE_APP_INDIVIDUAL_ROLE_TYPE;
             const comRoleType = process.env.VUE_APP_COMMERCIAL_ROLE_TYPE;
            if(!this.isRegisterButtonAvailable) return;
            try{
                this.isFetchingRegister = true
                let result = null;
                if(this.selectedRole === indRoleType){
                   result = await this.$store.dispatch('individualUserModule/setIndividualUserRegister', this.individualdUserInfo)
                }
                else if(this.selectedRole === comRoleType){
                   result = await this.$store.dispatch('commercialUserModule/setCommercialUserRegister', this.commercialUserInfo)
                }

                if(result){
                    this.$emit('closeModal');
                    this.getUserInAccordingToRoleType(result.role)

                }
                else {
                    alert('Something went wrong')
                } 

                
            }
            catch(err){
                console.log(err)
            }
            finally{
                this.isFetchingRegister = false;
                
                console.log(this.$store.state)
            }

        }, 1000),
        setRole(role){
            this.setReset()
            this.selectedRole = role
        },
        setReset(){
            this.name= '';
            this.surname= '';
            this.companyName= '';
            this.email= '';
            this.password= '';
            this.confirmPassword= '';
            this.phone= '';
            this.selectedCountry= {};
            this.selectedCity= {};
            this.IBAN= '';
            this.currency= {};
            this.birthdate= '';
            this.gender = '';

            this.isValidPhone=null;
            this.isValidEmail=null;
            this.isValidPassword=null;
            this.isValidConfirmPassword=null

            if(this.selectedRole === 'individual'){
                this.individualdUserInfo = {
                    role: 'individual',
                    name: '',
                    surname: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    phone: '',
                    country: {},
                    city: {},
                    countryCode: '',
                    currency: {},
                    IBAN: '',
                    birthdate: '',
                    gender: ''
                }

            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo = {
                    role: 'commercial',
                    companyName: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    phone: '',
                    country: {},
                    city: {},
                    countryCode: '',
                    currency: {},
                    gender: ''
                }
            }
        },
        validateCurrency(currency){
            if(!currency?.currency){
                this.isValidCurrency = false;
                return false
            }
            this.isValidCurrency = null;
            return currency?.currency
        },
        validatePhone(isFinalCheck = false) {
      // Adjust the regex to match the phone number format you expect
      const phonePattern = /^\+?(\d{1,4}[\s-]?)?\(?\d{1,4}\)?[\s-]?\d{1,4}[\s-]?\d{1,9}$/;
      this.isValidPhone = phonePattern.test(this.phone);
      if(!this.phone){
          this.isValidPhone = null
      }
      if(isFinalCheck){
          return this.isValidPhone && this.phone.length > 9
      }
      
  
    },

    validateEmail(isFinalCheck = false) {
      // Adjust the regex to match the email format you expect
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.isValidEmail = emailPattern.test(this.email);
      if(!this.email){
          this.isValidEmail = null
      }
      if(isFinalCheck){
          return this.isValidEmail
      }


    },

    validatePassword(isFinalCheck = false) {
      // Adjust the regex to match the password format you expect
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
      this.isValidPassword = passwordPattern.test(this.password);
      if(!this.password){
          this.isValidPassword = null
      }
      if(isFinalCheck){
          return this.isValidPassword && this.password.length > 7
      }
  
    },

    validateConfirmPassword() {

        if(this.confirmPassword !== this.password){
            return false
        }
        return this.confirmPassword === this.password

    }

    },

    watch: {
        "individualdUserInfo": {
            deep: true,
            handler(){
                console.log(this.individualdUserInfo)
            }
        },
        name: function(){
            this.individualdUserInfo.name = this.name
        },
        surname: function(){
            this.individualdUserInfo.surname = this.surname
        },
        companyName: function(){
            this.commercialUserInfo.companyName = this.companyName
        },

        email: debounce(function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.email = this.email

            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.email = this.email
            }

            this.validateEmail()
        },1500),
        password: debounce(function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.password = this.password
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.password = this.password
            }

            this.validatePassword()


        }, 1500),
        confirmPassword: debounce(function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.confirmPassword = this.confirmPassword
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.confirmPassword = this.confirmPassword
            }

            if(this.password !== this.confirmPassword){
                this.isValidConfirmPassword = false
            }
            else {
                this.isValidConfirmPassword = this.password === this.confirmPassword
            }
        },1500),

        phone: debounce(function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.phone = this.phone
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.phone = this.phone
            }
            this.validatePhone();

        }, 1500),

        // currency: function(){
        //         if(this.selectedRole === 'individual'){
        //             this.individualdUserInfo.currency = this.currency
        //         }
        //         if(this.selectedRole === 'commercial'){
        //             this.commercialUserInfo.currency = this.currency
        //         }

        //         this.validateCurrency(this.currency);
   
        // },

        birthdate: function(){
                this.individualdUserInfo.birthdate = this.birthdate
            
        },

        selectedCountry: function(){
            this.selectedCity = {};
            this.phone = '';
            this.countryCode = '';
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.country = this.selectedCountry;
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.country = this.selectedCountry
            }
            if(Object.keys(this.selectedCountry).length){
                this.phone = this.selectedCountry['phone_code']
                this.countryCode = this.selectedCountry['phone_code']
            }
        },

        selectedCity: function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.city = this.selectedCity
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.city = this.selectedCity
            }
        },

        gender: function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.gender = this.gender
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.gender = this.gender
            }
        },

        IBAN: function(){
                this.individualdUserInfo.IBAN = this.IBAN
        },

        countryCode: function(){
            if(this.selectedRole === 'individual'){
                this.individualdUserInfo.countryCode = this.countryCode
            }

            if(this.selectedRole === 'commercial'){
                this.commercialUserInfo.countryCode = this.countryCode
            }
        }


    }


}
</script>

<style scoped lang="scss">

    .active{
        background: #777191 !important;
        color: #ffffff !important;
    }
    .error-message {
  color: #ff5a5a;
  font-size: .75rem;
  font-weight: 500;
  position: absolute;
  bottom: -1rem;
  left: 0;
  
}

.disabled {
  pointer-events: none;
  opacity: .4;
}


    .register-user-container{
    z-index: 9;
    width: 36rem;
    height: 34rem; 
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: .9rem;
    background: #cdc9e0;
    border-radius: 10px;
    padding: 1.3rem 1rem;
    position: relative;
    margin: 8vh auto ;

    & .exit {
        position: absolute;
        top: .3rem;
        right: .9rem;
        font-size: 1.2rem;
        cursor: pointer;
        color: #38405A;
    } 
    

    & input {
            width: 100%;
            height: 2rem;
            border: 1px solid #D9D9D9;
            border-radius: 16px;
            padding: .2rem .8rem;
            font-size: .85rem;
            outline: none;
            transition: .2s all ease-in-out;

            &:focus{
                border: 1px solid #bfd0ff;
            }

            &::placeholder{
                font-size: .85rem;
                color: #9c9c9c;
                font-weight: 500;

            }
        }
        

    &__user-types{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: auto;
        margin: 0 auto;
        border-bottom: 2px solid #00000014;
        margin-bottom: .8rem;

        & img {
            width: 6em;
            height: 6rem;
            object-fit: scale-down;
            transform: scale(1.5);
            margin-bottom: .5rem;
        }

        &__type, &__type-commercial{
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            background: #ffffff61;
            border-radius: 4px;
            padding:   .4rem .5rem;
            font-weight: 550;
            transition: .1s all ease-in-out;
            font-size: .8rem;
            color: #38405A;
            min-width: max-content;
            width: 10rem;
            height: max-content;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }


        }


    }

    &__name-surname, &__company-name, &__country, &__birthdate, &__phone-currency, &__birthdate-register-btn, &__email, &__password, &__IBAN{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: auto;
    }
    & .register-user-container__email {
        position: relative;
        & input {
            padding-left:2.5rem ;
        }
        & i {
            position: absolute;
            left: .8rem;
            font-size: .85rem;
            cursor: pointer;
            color: #38405A;
        }

    }



    & .register-user-container__phone {
        display: flex;
        width: 48%;
        height: max-content;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        & input {
            width: 100% !important;
            padding-left:2.5rem !important;
        }

        & i {
            position: absolute;
            left: .8rem;
            font-size: .8rem;
            cursor: pointer;
            color: #38405A;
        }

        & img {
            width: 1.5rem;
            height: 1.5rem;
            object-fit: scale-down;
            position: absolute;
            left: .8rem;
            border-radius: 8px;
        }


    }

    &__name-surname input {
        width: 42% !important;
    }


    &__password {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: max-content;
        margin: 0 auto;
    }
&__password__form-container {
        display: flex;
        width: 48%;
        height: max-content;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        & input {
            width: 100% !important;
        }


}


    &__birthdate-register-btn  {
        display: flex;
        width: 90%;
        height: auto;
        flex-flow: row wrap;
        align-items: flex-end;
        justify-content: space-between;

        & .form-container {
            display: flex;
            width: 42%;
            height: max-content;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap: .2rem;

            & input {
            width: 100% !important;

            &::placeholder {
                font-size: .85rem;
                color: #9c9c9c;
                font-weight: 550;
            }
        }
            & .label-date {
                color: #38405A;
                font-size: .75rem;
                font-weight: 500;
                padding-left: .3rem;
            }
        }
    
    }
    &__birthdate-register-btn button {
        width: 47.5% !important;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        height: 2.4rem;
        border: 1px solid transparent;
        border-radius: 16px;
        padding: .2rem .8rem;
        font-size: 1rem;
        font-weight: 600;
        outline: 0;
        background: #48435c !important;
        color: #ffffff;
        cursor: pointer;
        gap: .4rem;

        &:hover {
            opacity: .8;
        }

        & .bxs-right-arrow {
            margin-top: .1rem;
            font-size: .85rem;
        }
    }
    &__phone-currency select {
        width: 47.5% !important;
        border: 1px solid #D9D9D9;
        border-radius: 16px;
        padding: .2rem .8rem;
        font-size: .85rem;
        outline: 0;
        height: 2.4rem;
    }
    &__phone-currency input {
        width: 42% !important;
    }

    &__country select {
        width: 47.5% !important;
        border: 1px solid #D9D9D9;
        border-radius: 16px;
        padding: .2rem .8rem;
        font-size: .85rem;
        outline: 0;
        height: 2.4rem;

    }






}


    
</style>