import baseServices from './index';

export default {


    getAdminAdsList : async (userId,filter) => {
        try {
            const result = await baseServices().post(`admin-user/get-admin-ads`,{
                userId,
                filter
            });
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    updateAdStatus : async (adUpdatedInfo) => {
        try {
            const result = await baseServices().post(`admin-user/update-ad-status`,adUpdatedInfo);
            return result;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
};